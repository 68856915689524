<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="container content_wrap p_under webpurchase">
    <section class="contentBox">
        <h1 class="headingUnder01">WEB買取 買取手順</h1>
        <div class="stepBox">
            <section class="content step">
                <h2 class="headingUnder02">STEP1: 買取カードのページにアクセス</h2>
                <p>ご希望の買取カードのページにアクセスして下さい。</p>
                <p><a href="" target="_blank">&gt;「Bigweb カード買取ページ」</a></p>
                <p class="attention small">※こちらの買取価格はWebの郵送買取のみの価格となります。店舗での買取価格とは異なりますので、あらかじめご了承下さい。</p>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">STEP2: 売りたいカードを選びカゴに入れる</h2>
                <p>当店が現在、買取を募集しているカードの一覧が表示されます。<br>
                    売りたいカード一覧から選び、枚数を選択して「買取」ボタンでカゴに入れて下さい。</p>
                <p class="attention small">※カード名が同じでも、シリーズ・レアリティ・言語が違うものは適用外となりますのでご注意下さい。<br>
                    ※リスト以外のカードがある場合は、買取ページのリスト最上段にある「一括買取」をカゴの中に入れて下さい。<br>
                    ※WEB買取のカードと一括買取のカードを一緒に送付される場合、仕切りや袋等で分けてお送り下さい。</p>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">STEP3: 買取依頼ボタンをクリック</h2>
                <p>買取希望カードを全てカゴに入れたら、カゴの中の確認ページにて、買取規約をご確認の上「この内容で買取依頼する」ボタンをクリックしてください。</p>
                <p class="attention small">※未発送の買取に追加する場合は、追加の旨をご要望欄にご記載下さい。</p>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">STEP4: 当店より買取受付完了・発送案内メールの送付</h2>
                <p>当店より買取受付完了・発送案内のメールを送らせていただきます。カードの送り先、買取注意事項などをご確認下さい。</p>
                <p class="attention small">※メールが届かない場合、迷惑メールのフォルダに送られている場合がございますので、ご確認下さい。<br>
                    確認できない場合はご連絡下さい。（電話：06-6245-7767[月-土 10:00-17:00]）</p>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">STEP5: ：買取カードを発送して下さい</h2>
                <p>メールをご確認後、記載された送り先まで、カードをお送り下さい。</p>
                <p class="attention small">※ご案内メール到着後5日以内に、当店に到着するようにカードを発送して下さい。<br>
                    ※ご案内メール到着後5日以上経過しますと、買取依頼時の価格が適用されない場合がございます。<br>
                    ※買取カードの送料はお客様のご負担となります。</p>
                <h3 class="headingUnder03">買取ご依頼金額の合計が1万円以上の場合､現住所記載の身分証明書のコピーを同封してください。※既に本人確認がお済の場合は省略できます。</h3>
                <ul class="textList">
                    <li>古物営業法により非対面取引における古物の買取際、買取額が1万円以上の場合は氏名、住所の本人確認が必須となります。</li>
                    <li>本人確認のため身分証明書のご住所宛に『簡易書留(転送不可扱い)』を送らせて頂きます。</li>
                    <li>身分証明書とは､運転免許証(表裏)､保険証(表裏)、日本政府発行のパスポート､住民票などになります。</li>
                    <li>買取金顧が1万円未満の場合､または既に本人確認がお済の場合は身分証明書のコピーは不要となります。</li>
                    <li>｢身分証明書のコピー｣をご同封し忘れた場合は､お手数ですが､別途、当店に送付願います。</li>
                </ul>
                <br>
                <h3 class="headingUnder03">18才未満の方は、「親権者同意書」が必要となります。</h3>
                <ul class="textList">
                    <li>18才未満の方は、「親権者同意書」が必要となりますので､必ず保護者の方に署名捺印して頂き、買取カードを送付の際､ご同封下さい。</li>
                    <li>PDFファイルをご利用頂けない場合や､プリントアウトが不可能な場合は、親権者の方の直筆にて、お取引にご同意して頂く旨を記載して頂き､署名捺印して頂いた紙面をご同封下さい。</li>
                    <li>買取カードを送付の際、「親権者同意書」をご同封し忘れた場合は、お手数ですが､別途、当店に送付願います。</li>
                </ul>
                <br>
                <p>&gt; <a href="assets/images/purchase/purchase-file.pdf" target="_blank">親権者同意書ダウンロード（PDF）</a></p>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">STEP6: 買取カード到着</h2>
                <p>当店に買取ご依頼カードが到着しましたら、順次査定させて頂きます。</p>
                <h3 class="headingUnder03">買取ご依頼金額が1万円以上で、本人確認がまだお済でないお客様に『簡易書留(転送不可扱い)』を発送します。</h3>
                <ul class="textList">
                    <p class="attention small">※買取金額が1万円未満、または既に本人確認がお済の方はこのSTEPは省略されます。</p>
                </ul>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">STEP7: 査定価格をお知らせ</h2>
                <p>お客様のカード到着より1日～3日以内にメールにて査定価格の総額をお知らせいたします。</p>
                <p class="attention small">※キズや汚れが有る場合は査定金額から減額となる場合がございます。<br>
                    （カード状態によっては買取価格から5割以上減額、80円以下のカードは買い取れない場合がございます）<br>
                    ※買取依頼が混雑した場合、査定価格の連絡が遅れる事もございます。</p>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">STEP8: 買取金額にご了承後、買取完了です。</h2>
                <p>買取金額にご了承いただけましたら、買取完了です。代金をお客様の本人名義の口座に送金いたします。</p>
                <p class="attention small">※本人確認が必要でまだ完了していない場合は、本人確認完了次第の送金となります。<br>
                    ※入金先の口座はご本人様の口座のみとなります。</p>
            </section>
            <section class="content step">
                <h2 class="headingUnder02">返品について</h2>
                <p>買取金額にご納得いただけなかった場合、カードを返品いたします。カードは着払いで返送いたしますので、送料はお客様のご負担となります。</p>
                <h3 class="headingUnder03">-買取する商品について-</h3>
                <p>当店では買取できないカードゲームもございます。詳しくは電話またはメールにてお問い合わせ下さい。<br>
                </p>
                <p>
                    電話お問い合わせ窓口：[<font color="#993333">06-6245-7767 </font>（月-土 10:00-17:00）]<br>
                    メールお問い合わせ窓口：[ <a href="mailto:kaitori@big-web.tv">kaitori@big-web.tv</a> ]
                </p>
                <p class="attention small">※当店の買取希望枚数に達した場合、 買取を打ち切らせていただくことがあります。 <br>
                    ※傷や汚れのあるカードは買い取る事が出来ない場合がございます。 <br>
                    ※買取枚数に達したカードは買取終了となります。買取終了のカードは一括買取となりますので、買取価格が低くなります。<br>
                    ※当店の買取表は随時更新致します。</p>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-1.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-1.jpg" alt="MTG 欠け・よれ・めくれサンプル">
                            </a>
                        </td>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-2.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-2.jpg" alt="遊戯王 欠け・よれ・めくれサンプル">
                            </a>
                        </td>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-3.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-3.jpg" alt="アーケード 欠け・よれ・めくれサンプル">
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-4.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-4.jpg" alt="MTG すりきずサンプル">
                            </a>
                        </td>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-5.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-5.jpg" alt="遊戯王 すりきずサンプル">
                            </a>
                        </td>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-6.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-6.jpg" alt="アーケード すりきずサンプル">
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-7.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-7.jpg" alt="ヴァイスシュヴァルツ 欠け・よれ・めくれサンプル">
                            </a>
                        </td>
                        <td>
                            <a href="assets/images/purchase/purchase_damage-8.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                                <img src="assets/images/purchase/purchase_damage-8.jpg" alt="ヴァイスシュヴァルツ すりきずサンプル">
                            </a>
                        </td>
                    </tr>
                </table>
                <br>
                <p class="attention small">
                    <strong>※上のカード画像は一例です。上の画像以外の傷・汚れ等も査定の対象となる場合がございます。</strong><br>
                    ※カードの折れ、さらに大きな傷・汚れ・欠けのある場合、買取出来ない場合がございます。<br>
                    ※英語・日本語以外の言語のカードの買取は、英語・日本語版カードの買取価格より安くなります。<br>
                </p>
            </section>
        </div>

    </section><!--  /.contentBox-->

</div><!--  /.container-->
  
