import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SearchService {
    options: any;

    constructor(public http: HttpClient) {
        let me = this;

        me.options = {
            headers: new HttpHeaders({
                //'Authorization': 'Bearer ' + this.token
                'Content-Type': 'application/json'
            })
        };
    }

    /**
     * @todo GET配列が渡せないために変換したJSON"文字列"を配列型に戻す
     * @param value
     * @returns
     */
    ConvertJsonStringToArray(value){
        if (!Array.isArray(value)) {
            return JSON.parse(value as string);
        }
        return value;
    }

    getFilterConfigs(game_id) {
        let me = this;
        let http_params: any = {};
        let url = environment.server + 'SearchFilters/?game_id=' + game_id;
        let result: any = this.http.get(url, http_params);

        return result;
    }

    loadFilterConfigs(game_id) {
        let game = environment.filterConfigs.find(item => {
            return item.id == game_id;
        });
        return game.filter_configs;
    }

}
