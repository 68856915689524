<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under pointback">
    <h1 class="headingUnder01">ポイントバックについて</h1>
    <section class="guide-content">
      <h2 class="headingUnder02">ポイントをためてお得なショッピング</h2>
        <p>Bigwebで、商品ごとに表示しております「Bigwebポイント」を、お買い上げいただきました皆様にプレゼントします。<br>
          <span class="attention">（クレジットカードでのご購入はポイントは付加されません。）</span></p>
        <p>獲得ポイントは、次回Bigwebでのショッピングの際にポイントを利用することで、1ポイント当たり1円の値引き価格でお買い物ができます。<br>
          <span class="small">※クレジットカードの場合、BIG-WEBポイントは付きません。</span>
        </p>
        <p>
          クレジットカードで2件以上ご注文頂き、同梱でお送りする場合、送料1回分で発送いたしますが、その際の多く頂いた送料分は、次回以降のお買い物でご利用いただけますBIG-WEBポイントにてお返しいたします。尚、ポイントでお返しする際、メール等でのご連絡はいたしませんのでご了承ください。
        </p>
    </section>

    <section class="guide-content">
      <h2 class="headingUnder02">自分のポイントを知りたい時</h2>
      <p><a href="<?= $login_user
          ?$this->Url->build(['controller' => 'Users', 'action' => 'profile', $login_user['users_new_id']])
          : $this->Url->build(['controller' => 'Users', 'action' => 'login']) ; ?>">会員情報ページ</a>よりID・パスワードを入力し、個人情報ページに現在の残りポイントが表示されます</p>
    </section>

    <section class="guide-content qanda">
      <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
      <ul class="qaList">
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">ポイントを使用する時に、使用するポイントを決めれるのでしょうか</div>
            </dt>
            <dd>
              <div class="qTitle">A</div><div class="qText">はい、決められます。購入の際、カート内の「キャッシュバックPOINT」の欄の「POINTを使用する」を選択し、ご利用POINTをご記入ください。</div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">
                獲得ポイントの有効期限はありますか。
              </div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                	今のところ、ポイントの有効期限は設定しておりません。
              </div>
            </dd>
          </dl>
        </li>

      </ul>
    </section>

  </section><!--  /.contentBox-->