import {Component, OnInit, Input} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
@Component({
    selector: 'app-menubar',
    templateUrl: './menubar.component.html',
    styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
    page: string
    @Input() game: any;

    constructor(
        public router: Router,
    ) {

    }

    ngOnInit(): void {
        let path = this.router.url.split('/');
        this.page = path[1]

    }

    onClickMenu(act) {
        let me = this;
        me.router.navigate([act]);
    }

    onClickExternalMenu(url) {
        let me = this;
        window.open(url, 'mtg');
    }
}
