import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GamesService, SearchService, SettingsService } from '../../_services';
import { TypeConstant, TypeGenreConstant } from '../../../appConstant';
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-supply-genre',
    templateUrl: './supply-genre.component.html',
    styleUrls: ['./supply-genre.component.scss']
})
export class SupplyGenreComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;
    TypeGenreConstant: typeof TypeGenreConstant = TypeGenreConstant;

    @Input() game: any;
    @Input() is_box: boolean = false;
    @Input() is_purchase: boolean;
    @Input() supplytypes: any;
    @Input() supplytype_id: any;
    @Input() subtype_id: any;
    @Input() selectedCardsets: any[] = [];
    @Input() groupType: string;

    @Output() onSelectCardset: EventEmitter<any> = new EventEmitter();

    image_url: string = environment.image_url;
    rarities: any = [];
    selectedRarities: any = [];

    filterConfigs: any;
    localeId: string;

    is_list: boolean = false;
    lastScrollTop: number = 0;

    constructor(
        public gamesService: GamesService,
        private router: Router,
        public searchService: SearchService,
        public settingsService: SettingsService
    ) {
        let path = this.router.url.split('/');
        this.is_list = path[1] == 'list' ? true : false;
        this.localeId = this.settingsService.getLocale();
    }

    ngOnInit(): void {
        let me = this;
        //me.loadRarities(me.supplytype_id);
    }

    loadRarities(cardset) {
        let me = this;
        //me.gamesService.loadRarities(cardset).subscribe((result: any) => {
        //    me.rarities = result.rarities;
        //});
    }

    goToListPage($event, params) {
        let me = this;
        let url = (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code + '/list'
        me.showLoading($event);
        if (me.is_purchase) {
            params.is_purchase = 1;
        }

        setTimeout(function () {
            me.router.navigate([url], { queryParams: params });
        }, 10);
    }

    showLoading($event) {
        let me = this;
        let elm = document.getElementById('cardset-list');

        if (elm.getElementsByClassName('mat-drawer-inner-container')[0]) {
            me.lastScrollTop = elm.getElementsByClassName('mat-drawer-inner-container')[0].scrollTop;
            localStorage.setItem('cardsetlist-scrolltop', String(me.lastScrollTop));
        }
        me.onSelectCardset.emit($event);
        let selectedRarities = sessionStorage.getItem('selectedRarities');
        me.selectedRarities = selectedRarities ? JSON.parse(selectedRarities) : [];

    }
}
