<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under orderconfirmation">
    <h1 class="headingUnder01">ご注文・発送状況の確認</h1>

    <section class="guide-content">
        <h2 class="headingUnder02">【お詫び】配送遅延、集荷禁止のお知らせ</h2>
        <p>
            現在、一部地域においてお届けの遅延、荷受けの停止が発生しております。<br />
            また、お届け中の商品についても、道路状況などにより大幅な遅延が発生する可能性がございます。<br />
            お客様には大変ご迷惑をお掛けしますが、予めご了承ください。<br />
            <br>
            尚、配送遅延などの発生地域の詳細については、各宅配業者様のWebサイトでご確認ください。
        </p>
        <div class="borderBox delivery">
            <ul>
                <li><h3 class="headingUnder04">佐川急便（宅配便）</h3>
                    <a href="http://www.sagawa-exp.co.jp/" target="_blank">http://www.sagawa-exp.co.jp/</a></li>
                <li><h3 class="headingUnder04">ヤマト運輸（ネコポス・宅急便）</h3>
                    <a href="http://www.kuronekoyamato.co.jp/" target="_blank">http://www.kuronekoyamato.co.jp/</a></li>
                <li><h3 class="headingUnder04">郵便局（ゆうパケット）</h3>
                    <a href="https://www.post.japanpost.jp/index.html" target="_blank">https://www.post.japanpost.jp/index.html</a></li>
            </ul>
        </div>

    </section>

    <section class="guide-content">
        <h2 class="headingUnder02">	ご注文いただいた商品の配送状況が確認できます。</h2>
        <p>ご注文内容は、ご注文完了後、1時間以内にBIG-WEBよりお送りする【ご注文確認】メール（自動メール）にてご確認いただけます。また、ご注文状況は「<a href="/login">会員情報</a>」画面にて、ID・パスワードを入力して頂きますと確認いただけます。<br />
            <br />
            尚、【ご注文発送メール】送信後の配送状況は各宅配業者様のWebサイトでご確認ください。
        </p>
        <div class="borderBox delivery">
            <ul>
                <li><h3 class="headingUnder04">佐川急便（宅配便）</h3>
                    <a href="http://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp" target="_blank">http://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp</a></li>
                <li><h3 class="headingUnder04">ヤマト運輸（ネコポス・宅急便）</h3>
                    <a href="http://toi.kuronekoyamato.co.jp/cgi-bin/tneko" target="_blank">http://toi.kuronekoyamato.co.jp/cgi-bin/tneko</a></li>
                <li><h3 class="headingUnder04">郵便局（ゆうパケット）</h3>
                    <a href="https://trackings.post.japanpost.jp/services/srv/search/input" target="_blank">https://trackings.post.japanpost.jp/services/srv/search/input</a></li>
            </ul>
        </div>

    </section>

    <section class="guide-content qanda">
        <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
        <ul class="qaList">
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">注文したのに【ご注文確認】【発送完了】メールが届かない</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
Eメールが届かない場合は、ご注文を承っていないかEメールアドレスに誤りがある可能性があります。ご登録いただいたEメールアドレスに間違いがありますと大切なご案内がお届けできません。<br />
<br />
<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ</a>で登録メールアドレスをご確認ください。誤りがございましたら、再度正しいメールアドレスを登録してください。<br />
アドレスに誤りがない場合や【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
<br />
ご注文の確認は<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ</a>からでもご確認いただけます。<br />
<br />
注文完了等のメールが届かない場合、迷惑メールボックスに送られている場合がございます。 <br />
迷惑メールボックスをご確認頂いてもメールが届いていない場合、お手数ですが、登録を別のアドレスにご変更ください。<br />
メールアドレス変更後【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
<br />
※現在、発生しているメールの不具合に関する情報は<a href="/informations/trouble-mail">こちら</a>です。<br />
<br />
大変ご迷惑をおかけしますが、宜しくお願いいたします。<br />
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">パスワードを忘れたため、「ご注文・配送状況」画面が表示できない</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            メンバーID、パスワードをEメールでご案内します。<a href="">こちら</a>
                            をご覧ください。どうしても「ご注文・配送状況」画面が表示できない場合は、お手数ですが、Eメール<a href="<?php echo $this->Url->build(['prefix' => false, 'controller' => 'informations', 'action' => 'contact']); ?>"> support@big-web.tv </a>または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">注文した商品が表示されてない</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            ご注文後に、【ご注文確認】メールが届いているかご確認ください。【ご注文確認】メールが届いていない場合は、ご注文を承っていない可能性があります。Eメール<a href="mailto:support@big-web.tv">support@big-web.tv</a>または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">注文した商品が届かない</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            Eメール<a href="mailto:support@big-web.tv">support@big-web.tv</a>または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">プレゼント包装はできるの？</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            申し訳ございません。基本的にプレゼント包装・ラッピングは行っておりません。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">	注文後にキャンセルはできるの？</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            お客様が、【注文を確定する】または【申込みを確定する】ボタンを押した時点で、ご注文が完了したものとさせていただいております。但し、出荷作業が始まっていないご注文については、キャンセルをすることができます。<br><font face="ＭＳ Ｐゴシック, Osaka">※ご注文後２日以上経過している場合はキャンセル・変更はお受け出来ませんのでご注意ください。（配達指定等で出荷準備中の場合も含みます）</font><br>くわしくはEメール<a href="<?php echo $this->Url->build(['prefix' => false, 'controller' => 'informations', 'action' => 'contact']); ?>"> support@big-web.tv </a>または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">返品や交換はできるの？</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            原則として、お客様のご都合による返品・交換はお受けできませんので、あらかじめご了承ください。
                            商品の品質、お届け方法には万全を期しておりますが、万一「商品違い」「数量違い」「不良品」などがございましたら取替えさせていただきます。商品お受取り後、7日以内にEメール<a href="mailto:support@big-web.tv">support@big-web.tv</a>または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">発送完了後に配送先の変更はできるの？</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            既に発送が完了している場合、一度当店へ荷物を返送後、ご希望の配送先へ再度出荷いたします。<br />
                            ※別途送料が発生いたします。地域ごとによって送料が異なりますので、<br />
                            くわしくはEメール<a href="/contact"> support@big-web.tv </a>または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
                        </div>
                    </dd>
                </dl>
            </li>
        </ul>
    </section>

</section><!--  /.contentBox-->
