<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under cardconditions">
    <h1 class="headingUnder01">カードの状態について</h1>
    <p>Bigwebで販売しているシングルカードの状態は、こちらの3種類です。</p>
    <section class="guide-content">
        <h2 class="headingUnder02">【プレイ用】</h2>
        <p>・パックから開封した物は基本的にこちらに分類されますが初期仕様を含む軽い傷、擦れ、汚れ、白欠け、爪跡などもこちらに分類されます。<br>
           ・特別な表記が無い場合当店のニアミントはNM+〜EXを含みます。
        </p>
        <figure>
            <figcaption>
                <ul class="cardimgkizu">
                    <li>
                        <a href="assets/images/card-conditions/etc012.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc012-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc013.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc013-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc014.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc014-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc015.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc015-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc016.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc016-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc017.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc017-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc001.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc001-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc002.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc002-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc003.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc003-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc004.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc004-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc005.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc005-small.jpg" alt="">
                        </a>
                    </li>
                </ul>
            </figcaption>
        </figure>
        <p class="small">※画像はあくまで参考です。カードの傷の具合や多さなどによって、状態表記を変更する場合があります。</p>
    </section>
    <section class="guide-content">
        <h2 class="headingUnder02">【キズ】/特価[傷含む]</h2>
        <p>・目立った傷、擦れ、汚れ、へこみ、白欠け、爪跡などがある状態。<br>
           ・不透明なスリーブに入れればゲームでの使用には差支え無い状態。
        </p>
        <figure>
            <figcaption>
                <ul class="cardimgkizu">
                    <li>
                        <a href="assets/images/card-conditions/etc006-d.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc006-small-d.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc007-d.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc007-small-d.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc008-d.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc008-small-d.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc009-d.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc009-small-d.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc010-d.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc010-small-d.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions/etc011-d.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions/etc011-small-d.jpg" alt="">
                        </a>
                    </li>
                </ul>
            </figcaption>
        </figure>
    </section>
    <hr>
    <section class="guide-content">
        <h2 class="headingUnder02">【状態の補足】</h2>
        <p>・+：表記している状態表現よりも状態が良いが、ランク1つ上の状態に至るほどではない。（ランク1つ上の状態の「−」よりは劣る）<br>
           ・−：表記している状態表現よりも状態が悪いが、ランク1つ下の状態に至るほどではない。（ランク1つ下の状態の「+」よりは勝る）<br>
           ・インクド：ペンやマジック等で修正した跡があります。<br>
           ・サインド：アーティストやプレイヤー等のサインが書いてあります。<br>
           ・スタンプド:リミテッドの競技イベントなどでカードに押されるスタンプの跡があります
        </p>
    </section>
    <hr>
    <section class="guide-content">
        <h2 class="headingUnder02">【センタリングについて】</h2>
        <p>・センタリングは状態のランクには加味されません。<br>
           ・特に表記の無い場合写真のようなルール上問題無いレベルの印刷ズレが含まれる場合があります。<br>
        </p>
    </section>
</section>
