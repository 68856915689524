import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-recruit',
    templateUrl: './recruit.component.html',
    styleUrls: ['./recruit.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class RecruitComponent implements OnInit {

    title: string = '採用情報';
    is_purchase: boolean = false;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: '店舗ページ', url: 'shop/'},
        {title: '採用情報', url: 'shop/recruit'},
    ];

    constructor() {}

    ngOnInit(): void {
        window.scroll(0, 0);
    }


}
