<div class="game-grid-items" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
  <ng-container *ngFor="let item of items">
    <div *ngIf="headerArrayList && headerArrayList.indexOf(item.id) > -1 && groupType">
      <div class="game-name-header" *ngIf="(groupType=='rarity'|| (groupType == 'none' && game.id == TypeConstant.SUPPLY)) && item.rarity.web!=='-'">
        {{item.rarity.web}}
      </div>
      <div class="game-name-header" *ngIf="groupType=='cardset'">
        {{item.cardset.web}}
      </div>
    </div>
    <div class="game-grid-items-list">
      <app-grid-itembox [item]="item" [is_purchase]="is_purchase" (addToCartItems)="runAddToCartItems(item)">
      </app-grid-itembox>
    </div>
  </ng-container>
</div>
