import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    token: string;
    body: any = new HttpParams();
    options: any;
    constructor(public http: HttpClient) {

        this.options = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.token
            })
        };
    }

    register(request) {

        let me = this;
        let url = environment.server + 'register';
        let body = new FormData();
        let headers = {headers: new HttpHeaders()};

        body.append('userId', request['userId']);
        body.append('password', request['password']);
        body.append('email', request['email']);
        body.append('lastName', request['lastName']);
        body.append('firstName', request['firstName']);
        body.append('lastNameKana', request['lastNameKana']);
        body.append('firstNameKana', request['firstNameKana']);
        body.append('postalCode', request['postalCode']);
        body.append('prefecture', request['prefecture']);
        body.append('municipality', request['municipality']);
        body.append('address', request['address']);
        body.append('phoneNumber', request['phoneNumber']);
        body.append('scope', '*');

        return me.http.post(url, body, headers);
    }

    duplicateCheck(request) {

        let me = this;
        // let url = environment.server + 'register';
        let url = environment.server + 'register?email=' + request['email'];
        let body = new FormData();
        //let headers = {headers: new HttpHeaders()};

        body.append('email', request['email']);
        body.append('scope', '*');

        return me.http.get(url, request['email']);
    }

}
