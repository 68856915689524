<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under cancel">
    <h1 class="headingUnder01">キャンセルと返品・交換</h1>
    <p>原則として、お客様のご都合によるキャンセル・返品・交換はお受けできませんので、あらかじめご了承ください。</p>

    <section class="guide-content">
      <h2 class="headingUnder02">キャンセル・変更</h2>
      <p>お客様が、【注文を確定する】または【申込みを確定する】ボタンを押した時点で、ご注文が完了したものとさせて頂いております。原則として購入手続き完了後のキャンセル・変更はお受けすることができません｡ 予めご了承ください｡</p>
    </section>

    <section class="guide-content">
      <h2 class="headingUnder02">返品・交換</h2>
      <p>原則として、お客様のご都合による返品・交換はお受けできませんので、あらかじめご了承ください。
          商品の品質、お届け方法には万全を期しておりますが、万一「商品違い」「数量違い」「発送時の破損品」などがございましたらお取替えをいたします。（代替品が無い場合はご返金させて頂きます。）商品お受取り後、7日以内にEメール(<a href="mailto:support@big-web.tv">support@big-web.tv</a>) または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
          </p>

          <p>商品の初期不良に関しましては、商品パッケージのメーカーサポートセンターの方にお問合せ下さい。サポートセンターの電話番号がわからない場合やサポートセンターの対応にご不満がある場合は当店が代わってメーカーに問合せいたしますので商品お受取り後、7日以内にEメール(<a href="mailto:support@big-web.tv">support@big-web.tv</a>)または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
      </p>

      <div class="borderBox">
        <h3 class="headingUnder03">次の場合の返品・交換はご容赦ください。</h3>
        <ul class="textList">
          <li> 当サイト上、およびお届けした商品に添付した発送票に返品不可と表示されている</li>
          <li>一度ご使用になられた場合</li>
          <li>お客様の責任で傷や破損が生じた場合</li>
          <li>商品のお受取り日から7日以内にご連絡がない場合</li>
          <li>説明書や付属品等の同梱物のいずれか1つでも破棄または紛失した場合</li>
          <li>当サイト以外でご注文された商品の場合</li>
        </ul>
      </div>
    </section>


    <section class="guide-content qanda">
      <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
      <ul class="qaList">
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">	届いた商品がこわれていた。</div>
            </dt>
            <dd>
              <div class="qTitle">A</div><div class="qText">
                商品パッケージ内の破損、不良に関しましては、メーカーサポートセンターまでお問合せ願います。<br>
                発送時に生じた破損等は、交換（返金）させて頂きます。商品到着後7日以内にEメール(<a href="mailto:support@big-web.tv">support@big-web.tv</a>) または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">	注文していない商品が届いた。</div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                商品到着後7日以内にEメール(<a href="mailto:support@big-web.tv">support@big-web.tv</a>) または、BIG-通販06-6245-7767（10:00～17:00）までご連絡ください。
              </div>
            </dd>
          </dl>
        </li>
      </ul>
    </section>

  </section><!--  /.contentBox-->
