import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-category-tile',
    templateUrl: './category-tile.component.html',
    styleUrls: ['./category-tile.component.scss']
})
export class CategoryTileComponent implements OnInit {
    @Input() game: any;
    @Input() is_purchase: boolean;
    @Input() has_reservation: boolean;
    @Input() category: string;
    @Input() selectedCardsets: any = [];
    @Input() criteria: any;
    @Input() cardsets;
    current: string;
    salesUrl: string = 'top';
    purchaseUrl: string = 'purchase/top';
    langParam: string = '/ja/';
    urlPath: any;
    constructor(
        private router: Router, public dialog: MatDialog,

        //public loadingService: LoadingService,
    ) {

    }

    ngOnInit(): void {
        let me = this;

        me.urlPath = me.router.url;

        me.langParam = location.pathname;

        me.salesUrl = me.urlPath.replace('/purchase/', '/products/');
        me.purchaseUrl = me.urlPath.replace('/products/', '/purchase/');
    }

    goToCategoryTop(category) {
        let me = this;
        if (me.category != category) {
            //me.loadingService.loading()
            setTimeout(function () {
                //me.router.navigate(['/' + (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.name + '/' + category]);
                me.router.navigateByUrl('/' + (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code + '/' + category);
            }, 10);
        }
    }

    goToSupply() {
        let me = this;
        //me.loadingService.loading()
        setTimeout(function () {
            //me.router.navigate(['/' + (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code + '/list'], { queryParams: { is_supply: 1 } });
            me.router.navigateByUrl('/' + (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code + '/list?is_supply=1');
        }, 10);
    }
}
