<button mat-mini-fab color="warn" class="modal-close-button" (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>

<div fxLayout="row wrap" class="menu-content" fxLayoutAlign="center center">
  <div class="menu-item" fxLayoutAlign="center center" fxFlex="30">
    <a mat-button href="">
      <mat-icon>home</mat-icon>
      <span i18n>総合TOP</span>
    </a>
  </div>
  <div class="menu-item" fxLayoutAlign="center center" fxFlex="30">
    <a mat-button href="purchase">
      <mat-icon>local_shipping</mat-icon>
      <span i18n>郵送買取</span>
    </a>
  </div>
  <div class="menu-item" fxLayoutAlign="center center" fxFlex="30">
    <a mat-button fxflex="50" href="guide" fxLayoutAlign="center center">
      <mat-icon>info_outline</mat-icon>
      <span i18n>ご利用案内</span>
    </a>
  </div>
  <div class="menu-item" fxLayoutAlign="center center" fxFlex="30">
    <a mat-button href="shop">
      <mat-icon>holiday_village</mat-icon>
      <span i18n>店舗</span>
    </a>
  </div>
  <div class="menu-item" fxLayoutAlign="center center" fxFlex="30">
    <a mat-button href="contact">
      <mat-icon>contact_support</mat-icon>
      <span i18n>お問合せ</span>
    </a>
  </div>
  <div class="menu-item" fxLayoutAlign="center center" fxFlex="30">
    <a mat-button href="login" *ngIf="!loginUser!">
      <mat-icon>person</mat-icon>
      <span i18n>新規登録</span>
    </a>
    <a mat-button (click)="logout()" *ngIf="loginUser!">
      <mat-icon>logout</mat-icon>
      <span i18n>ログアウト</span>
    </a>
  </div>
</div>
<div fxLayout="row wrap" class="menu-link">
  <div class="link-item" fxFlex="31">
    <a mat-button color="primary" href="https://twitter.com/big_web_all" target="_blank">
      <span i18n><i class="twitter"><img src="assets/icons/icon-twitter.png"></i>Bigweb総合</span>
    </a>
  </div>
  <div class="link-item" fxFlex="32">
    <a mat-button color="primary" href="https://twitter.com/MtgBigmagic" target="_blank">
      <span i18n><i class="twitter"><img src="assets/icons/icon-twitter.png"></i>BIGMAGIC</span>
    </a>
  </div>
  <div class="link-item" fxFlex="31">
    <a mat-button color="primary" href="https://www.facebook.com/bigwebmtg" target="_blank">
      <span i18n><i class="facebook"><img src="assets/icons/icon-facebook.png"></i>facebook</span>
    </a>
  </div>
  <div class="link-item" fxFlex="31">
    <a mat-button color="primary" href="https://www.youtube.com/user/BIGWEBMTG" target="_blank">
      <span i18n><i class="youtube"><img src="assets/icons/icon-youtube.png"></i>Youtube</span>
    </a>
  </div>
  <div class="link-item" fxFlex="32">
    <a mat-button color="primary" href="https://www.twitch.tv/bigmagiclive" target="_blank">
      <span i18n><i class="twitch"><img src="assets/icons/icon-twitch.svg"></i>Twitch</span>
    </a>
  </div>
</div>

<div class="">
  <mat-grid-list cols="2" rowHeight="4.0:1" class="game-panel-list">
    <mat-grid-tile col="1" *ngFor="let game of games" class="game-panel">
      <a href="{{url}}/{{game.code}}">
        <mat-card-content class="game-title-panel">
          <img mat-card-image src="/{{image_url}}/top_{{game.id}}.png" class="side-game-thumb" alt="{{game.id}}">
          <span>{{game.name}}</span>
        </mat-card-content>
      </a>
    </mat-grid-tile>
    <mat-grid-tile col="1" class="game-panel">
      <a href="products/supply">
        <mat-card-content class="game-title-panel">
          <img mat-card-image src="/{{image_url}}/supply.png" class="side-game-thumb">
          <span>サプライ</span>
        </mat-card-content>
      </a>
    </mat-grid-tile>
    <mat-grid-tile col="1" class="game-panel">
      <a href="products/character-supply">
        <mat-card-content class="game-title-panel">
          <img mat-card-image src="/{{image_url}}/character_supply.png" class="side-game-thumb">
          <span>キャラサプライ</span>
        </mat-card-content>
      </a>
    </mat-grid-tile>
  </mat-grid-list>
</div>
