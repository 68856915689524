<div class="top_menu">
    <mat-button-toggle-group [value]="page">
        <mat-button-toggle class="top_menu-button" (click)="onClickMenu('products')" value="products">
            <i class='fas fa-home fa-lg'></i>
            総合TOP
        </mat-button-toggle>
        <mat-button-toggle class="top_menu-button purchase-button" (click)="onClickMenu('purchase')" value="purchase">
            <i class='fas fa-people-carry fa-lg'></i>
            郵送買取
        </mat-button-toggle>
        <mat-button-toggle class="top_menu-button" value="guide"
            (click)="onClickExternalMenu('https://mtg.bigweb.co.jp/informations')">
            <mat-icon class="tool-icon" aria-hidden="false" aria-label="Cart icon">error_outline</mat-icon>
            ご利用案内
        </mat-button-toggle>
        <mat-button-toggle class="top_menu-button" value="shop"
            (click)="onClickExternalMenu('https://mtg.bigweb.co.jp/shop')">
            <i class='fas fa-store fa-lg'></i>店舗
        </mat-button-toggle>
        <mat-button-toggle class="top_menu-button" value="contact"
            (click)="onClickExternalMenu('https://mtg.bigweb.co.jp/informations/contact')">
            <i class='fas fa-envelope fa-lg'></i>
            お問合せ
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>