<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="p_under terms">
    <section class="contentBox">
      <h1 class="headingUnder01">利用規約</h1>
      <p>
          本規約はビックウェブ（以下｢当社｣といいます）が運営するBig-Web.tv（以下｢本サービス｣といいます）をご利用いただくにあたって、一切について適用するものです。必ず本規約をご一読いただき、規約内容にご同意のうえで、お客様情報のご登録、商品・サービスのご注文、メールマガジンのお申込み等を行なっていただくよう、お願いいたします。
      </p>
      <section class="guide-content">
        <h2 class="headingUnder02">I．個人情報保護方針（プライバシーポリシー）について</h2>
        <p class="info_des">当社は、その運営する本サービスを通じてお客様から収集した、個人を識別または特定できる情報（以下「個人情報」といいます）、ご注文情報（お客様の注文履歴に関する情報を含む）の保護に最大限の注意を払っています。当社は責任をもってお客様の個人情報を保護するため、個人情報の保護に関する方針を以下の通り定め、社内体制の構築、施策の実施・維持、それらの継続的な改善を行っています。</p>
        <ul class="textList noListstyle">
            <li>1.  当社は、お客様から収集させていただいた個人情報、ご注文情報（お客様の注文履歴に関する情報を含む）を、本サービスを提供する目的の他に、以下の各号に定める目的のために利用することがあります。本サービスの提供または以下に定める目的以外に、当社はお客様の個人情報を利用することはありません。
                <ul>
                    <li>（1） お客様に対して、当社の商品やサービスをご紹介する場合</li>
                    <li>（2） 当社サービスセンターにおいて、お客様に代行してご注文手続き、ご注文内容の確認、変更手続きを行なう場合</li>
                    <li>（3） お客様に対して、当社のサービスに対するご意見やご感想のご提供をお願いするため</li>
                    <li>（4） 当社がお客様に別途連絡の上、個別にご了解をいただいた目的に利用するため</li>
                    <li>（5） お客様の属性（年齢、住所など）ごとに分類された統計的資料を作成するため</li>
                </ul>
            <li>2.  当社がお客様から収集させていただいた個人情報は、以下の何れかに該当する場合を除き、第三者に提供・開示等をすることはありません。
                <ul>
                    <li>（1） お客様の事前の同意・承諾を得た場合</li>
                    <li>（2） あらかじめ当社との間で機密保持に関する契約を締結している会社等に本サービスに関連して必要な限度内で開示・提供する場合</li>
                    <li>（3） 本サービスのご利用や商品の注文等で決済が必要な場合に、金融機関等との間で個人情報の確認交換をする場合</li>
                    <li>（4） お客様からのお問い合せや、お買い上げ商品に関するアフターサービス等で、機密保持に関する契約を締結している会社等から直接ご連絡させていただくことが適切と判断される場合</li>
                    <li>（5） その他法令等により開示・提供が必要な場合</li>
                </ul>
            </li>
            <li>3.  当社が、上記2．において個人情報を開示・提供等を行う会社等には、お客様の個人情報に関して当社と同様適切な管理を行うよう、契約により義務付けをいたします。</li>
            <li>4.  お客様から収集させていただいた個人情報は、当社によって適切、厳重に管理し、お客様の個人情報への不正なアクセスや情報の紛失、破壊、改竄及び漏洩等が起きぬよう、予防並びに安全対策を講じます。</li>
            <li>5.  当社では、お客様と当社との間でのクレジットカード番号を含むすべての通信内容に対して、SSL（Secure Socket Layer）テクノロジーによる暗号化を施す等して、合理的な範囲内でセキュリティの強化に努めます。しかしながら、SSL (Secure Socket Layer) テクノロジー等のセキュリティ技術であっても、そのセキュリティが完全であるとまではいいきれません。万一、当社によるセキュリティ施策にもかかわらず、ハッカー等による不当な行為により、お客様および第三者に損害が生じた場合については、当社は責任を負いかねますのでご了承ください。</li>
            <li>6.  お客様がご自身の個人情報に関する照会、確認、修正等を希望される場合には、合理的な範囲で速やかに対応します。</li>
            <li>7.  当社は、個人情報保護に関する法令及びその他の規範を遵守します。</li>
            <li>8.  当社は、個人情報の保護及び取扱いについて、継続的改善に努めます。</li>
        </ul>
      </section>
      <section class="guide-content">
        <h2 class="headingUnder02">II．お客様情報の登録によるサービスについて</h2>
        <p>お客様情報を一度ご登録いただきますと、次回以降のご購入での手続きが簡単になるサービスがご利用できます。</p>
        <ul class="textList noListstyle">
            <li>1.  お客様情報の登録
                お客様情報の登録を希望されるお客様は、本規約を承諾していただいた上で、当社が別途指定する手続に従ってお客様情報を登録していただきます。
            </li>
            <li>2.  変更の届出
                お客様情報の登録を希望されるお客様は、本規約を承諾していただいた上で、当社が別途指定する手続に従ってお客様情報を登録していただきます。
            </li>
            <li>3.  譲渡等の禁止
                お客様情報の登録によって有する権利を第三者に譲渡、売買、名義変更、質権の設定その他の担保に供する等の行為はできないものとします。
            </li>
            <li>
                4.  お客様情報の取消しお客様情報の登録を取消す場合には、所定の方法にて当社に届出るものとします。また、登録されたお客様情報は、一身専属のものであり、当社は当該お客様の死亡を知り得た時点を以って、同様の届出があったものとして取扱います。
            </li>
            <li>
                5.  お客様情報登録による権利の取消し
                お客様情報を登録されたお客様が以下の何れかに該当する場合、当社は、事前に通知することなく、直ちに当該お客様の権利を取消すことができるものとします。また、当社が前述の措置をとったことで、当該サービスを利用できなくなり、これにより当該お客様または第三者に損害が発生したとしても、当社は一切の責任を負いません。
                <ul>
                    <li>（1） 第7項、第8項の行為を行なった場合</li>
                    <li>（2） 当社への申告、届出内容に虚偽があった場合</li>
                    <li>（3） 決済手段として当該お客様が届出たクレジットカードが、クレジット会社による利用契約の解除その他の理由により、利用が認められなくなった場合</li>
                    <li>（4） 料金等の支払債務の履行遅延または不履行があった場合</li>
                    <li>（5） 電話、FAX、Eメールその他の手段によっても、お客様情報を登録されたお客様との連絡が取れなくなった場合</li>
                    <li>（6） その他、本規約に違反した場合</li>
                    <li>（7） その他、不適切と当社が判断した場合</li>
                </ul>
            </li>
            <li>6.  メンバーIDおよびパスワードの管理責任
                メンバーIDおよびパスワードが大切なものであることを認識し、メンバーIDおよびパスワードの管理については、お客様が一切の責任を負うものとします。また、メンバーIDおよびパスワードを第三者に使用させたり、貸与、譲渡、名義変更、売買、質入等をしてはならないものとします。
            </li>
            <li>7.  禁止事項
                お客様情報を登録されるお客様は、以下の行為を行ってはならないものとします。
                <ul>
                    <li>（1） ご利用の際に虚偽の登録内容を申請する行為</li>
                    <li>（2） 本サービスの運営を妨げ、その他本サービスに支障をきたすおそれのある行為</li>
                    <li>（3）クレジットカードを不正使用して本サービスを利用する行為</li>
                    <li>（4） メンバーIDおよびパスワードを不正に使用する行為</li>
                    <li>（5） 他のお客様、第三者もしくは当社に迷惑、不利益もしくは損害を与える行為、またはそれらのおそれのある行為</li>
                    <li>（6） 他のお客様、第三者もしくは当社の著作権、プライバシーその他の権利を侵害する行為、またはそれらのおそれのある行為</li>
                    <li>（7） 公序良俗に反する行為その他法令に違反する行為、またはそれらのおそれのある行為</li>
                    <li>（8） その他、当社が不適当と判断する行為</li>
                </ul>
            </li>
            <li>8.  著作権
                本サービスにて提供している情報および画像の著作権は、当社または情報提供者に帰属します。著作権法にて定める個人の私的使用の範囲を超えて、情報および画像等を利用したり、転載、使用、複製、配布、変造等を行うことはできません。
            </li>
            <li>9.  サービスの提供
                サービスの内容は、当社がその時点で合理的に提供可能なものとします。また、理由の如何を問わず、お客様情報を登録されたお客様に事前の通知をすることなく、サービスの内容の一部または全部の変更、追加および廃止をすることができるものとします
            </li>
            <li>10. サービスの中止
                当社は以下の場合、サービスの利用の全部または一部を中止することがあります。また、サービスの利用の全部または一部を中止するときは、あらかじめその旨をお客様情報を登録されたお客様に通知します。但し、緊急でやむを得ない場合はこの限りではありません。
                <ul>
                    <li>（1） システムの定期保守および緊急保守の場合</li>
                    <li>（2） 第一種電気通信事業者が電気通信サービスを中止した場合</li>
                    <li>（3） 火災、停電等によりサービスの提供ができなくなった場合</li>
                    <li>（4） 地震、噴火、洪水、津波等の天災によりサービスの提供ができなくなった場合</li>
                    <li>（5） 戦争、動乱、暴動、騒乱、労働争議等によりサービスの提供ができなくなった場合</li>
                    <li>（6） その他、運用上、技術上当社がサービスの一時的な中断が必要と判断した場合</li>
                </ul>
            </li>
            <li>11. 免責事項
                当社は以下の事項について免責されるものとします
                <ul>
                    <li>（1） 本サービスで提供する情報やサービス、データ、あるいはEメールや電話、FAX等でお客様にお送りする情報、データ等について、その正確性、適用性、有用性、最新性、確実性、動作性、安全性、完成度等、いかなる保証も行いません</li>
                    <li>（2） 本サービスからリンクされている各サイトに含まれている情報、サービス、データ等についいては、一切関知しておりません</li>
                    <li>（3） お客様の個人情報、注文情報の消失、破壊、他者による改竄に関し、いかなる責任も負わないものとします</li>
                    <li>（4） お客様が本サービスをご利用になれなかったことにより発生した一切の損害について、いかなる責任も負わないものとします。ただし、当社の故意または重過失の場合は除きます</li>
                    <li>（5） お客様の受けた不利益等が登録内容に従い事務を処理したことによる場合には免責されるものとします</li>
                    <li>（6） お客様が、本サービスをご利用になることにより、他のお客様または第三者に対して損害等を与えた場合には、当該お客様は自己の責任と費用において解決し、当社には一切迷惑を与えないものとします</li>
                    <li>（7） 未成年者のお客様が商品をご注文する場合は、保護者の同意の上でご注文いただき、ご注文いただいた商品については、保護者の同意を得ているものとみなします</li>
                </ul>
            </li>
            <li>12. その他
                当社は、お客様に事前の承諾を得ることなく、当社が適当と判断する方法でお客様に通知することにより、本規約の内容を変更できるものとします。 また、その他の事項については以下の通りとします
                <ul>
                    <li>（1）本サービスに関する準拠法は、日本法といたします</li>
                    <li>（2）本サービスのご利用に関して、本規約または当社の指導により解決できない問題が生じた場合には、当社とお客様との間で双方誠意をもって話し合い、これを解決するものとします</li>
                    <li>（3）本サービスの利用に関して訴訟の必要が発生した場合には、大阪地方裁判所または大阪簡易裁判所を第一審の専属管轄裁判所といたします</li>
                </ul>
  
            </li>
        </ul>
      </section>
      <section class="guide-content">
        <h2 class="headingUnder02">Ⅲ．契約の成立</h2>
        <p class="info_des">当社にて商品をご注文いただくと、ご注文の受領確認とご注文内容を記載した「ご注文の確認のお知らせ」のメールが当社から送信されます。お客様からのご注文は、商品購入についての契約の申込となります。お客様が選択された商品の支払い方法に拘わらず、当社が販売する商品をご注文いただいた場合、当社から「ご注文商品発送のお知らせ」メールがお客様に送信されたときにお客様の契約申し込みは承諾され、契約が成立します。1つの注文にて複数の商品をまとめてご注文いただいた場合で、「ご注文商品発送のお知らせ」メールにおいて発送が確認されない商品については、当該「ご注文商品発送のお知らせ」メールにより成立する契約の対象に含まれません。</p>
      </section>
  
      <section class="guide-content">
        <h2 class="headingUnder02">Ⅳ．商品の価格</h2>
        <p class="info_des">当社では、正確な商品情報を表示するよう最善の努力をしています。万が一商品の誤った価格または価格に関連する情報（ポイント付与数を含む、以下「価格」）がサイト上に表示されていた場合は、次のように価格調整をさせていただきます。
            商品の正しい価格が、当サイトに表示されていた価格より低い場合は、その低い方の価格で請求させていただきます。
            商品の正しい価格が、当サイトに表示されていた価格より高い場合は、当社の裁量により、発送前にお客様にご連絡し改めて正しい価格をお知らせした上で商品発送のご指示をお願いするか、または、ご注文をキャンセルさせていただきその旨をご連絡いたします。</p>
      </section>
      <section class="guide-content">
        <h2 class="headingUnder02">Ⅴ．返品について</h2>
        <p class="info_des">「特定商取引に関する法律」第11条に基づき、以下に明示いたします。
            ご購入いただきました商品の初期不良や配送中の破損および汚損、欠品、商品間違いにつきましては、交換または返品を承らせていただきますので、商品のご到着後7日以内にBIG-通販06-6245-7767（10:00～17:00）まで、ご連絡をお願いいたします。また、商品をご返送いただく際には、その商品に同梱されているすべての付属品も必ず一緒にご返送ください。なお、お客様都合による返品につきましては、お受けすることができませんので、ご了承ください。</p>
      </section>
    </section>
  </div>
  