import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-namba',
    templateUrl: './namba.component.html',
    styleUrls: ['./namba.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class NambaComponent implements OnInit {

    title: string = 'BIG MAGIC 難波店';
    is_purchase: boolean = false;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: '店舗ページ', url: 'shop/'}
        ,
        {title: '難波店', url: 'shop/namba'},
    ];

    constructor() {}

    ngOnInit(): void {
        window.scroll(0, 0);
    }

}
