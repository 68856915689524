
<app-toolbar  [title]="title" [is_purchase]="is_purchase" [is_static]="is_static">
</app-toolbar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>
<div class="login-container" >
    <mat-card class="login-card" >
        <mat-card-header>
            <div mat-card-avatar class="login-header-image"></div>
            <mat-card-title><span i18n>bigweb会員の方はこちら</span></mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form  [formGroup]="loginForm">
                <div>
                    <mat-form-field fxFlex    >
                        <mat-label><span i18n>ユーザー名</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="username" required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex    >
                        <mat-label><span i18n>パスワード</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="password" type="password"required>
                    </mat-form-field>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="end">
            <button (click)="onLogin()" mat-flat-button type="submit" color="primary"><span i18n>ログイン</span></button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="register-card">
        <mat-card-header>
            <div mat-card-avatar class="login-header-image"></div>
            <mat-card-title><span i18n>初めてご利用の方はこちら！</span></mat-card-title>
        </mat-card-header>

        <mat-card-content>

        </mat-card-content>
        <mat-card-actions fxLayoutAlign="end">
            <a href="https://mtg.bigweb.co.jp/users/signin" target="mtg" mat-flat-button color="warn"><span i18n>会員登録</span></a>
        </mat-card-actions>
    </mat-card>
</div>