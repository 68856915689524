<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="col-xs-12 individual-shop-info">
    <div class="entry_article_wrap">
        <article class="entry_article">
            <div class="entry_txt">
                <div class="shop-content">
                    <h1 class="shop-title">BIG MAGIC 秋葉原店</h1>
                    <div class="shop-content-label" >
                        <h4><strong>店舗情報</strong></h4>
                    </div>
                    <h4><strong><a href="https://mtg.bigweb.co.jp/shop/recruit">採用情報</a> / <a href="bmakiba-kessai">お支払い一覧</a> / <a href="bmakiba-sch">店舗大会スケジュール</a></strong></h4>
                    <p>■住所<br>
                        〒101-0021 東京都千代田区外神田1-15-16 秋葉原ラジオ会館 9F</p>
                    <p>■TEL<br>
                        03-3526-2667</p>
                    <p>■営業時間<br>
                        10：00～20：00</p>
                    <p><strong><span class="shop-information">※新型コロナウイルスの影響により、現在BIG MAGIC各店の営業時間に変更がある場合がございます。詳細は各店にてお問い合わせ下さい。</span></strong></p>
                    <p><a href="https://twitter.com/bigmagicakb">BIG MAGIC秋葉原店 Twitter</a></p>
                    <p>■取り扱い商品<br>
                        MTG・遊戯王・ヴァイスシュヴァルツ・ヴァンガード・Z/X・WIXOSS・デュエルマスターズ・バトルスピリッツ・デジモンカードゲーム（デジカ）・ファイアーエムブレムサイファ・ポケモンカード・FFTCG・サプライ品各種</p>
                    <p><a href="https://bmakihabaramtg.diarynote.jp/">BIG MAGIC秋葉原店 DiaryNote</a></p>
                    <p><a href="https://twitter.com/bigmagicakb">BIG MAGIC秋葉原店 Twitter</a></p>
                    <p>　</p>
                    <div class="embed-container">
                        <iframe class="shop-google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12960.587941372827!2d139.7719449!3d35.6980004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbcafbfd6e6ae1a6a!2zQklHIE1BR0lDIOadseS6rOeni-iRieWOn-W6lw!5e0!3m2!1sja!2sjp!4v1547887834203"></iframe></div><br>
                    <div id="bmtp-akiba">&nbsp;</div><p>&nbsp;</p>
                </div>
            </div>
            <div align="center"><h4 class="bmtp-title"><strong>BMTP交換賞品一覧表</strong></h4>
                <img src="https://mtg.bigmagic.net/article/about/bmtpikebukuro002.jpg" alt="BMTP 秋葉原店交換賞品一覧表" border="0" class="shop-info-image"><br><br>
                <img src="https://mtg.bigmagic.net/article/about/bmtpikebukuro07.jpg" alt="BMTP 池袋店交換可能サプライ一覧表" border="0" class="shop-info-image"></div>
            <div class="bm-point-info">
                <p>内容は予告無く変更になる場合がございますのでご了承ください。</p>
                <p>　</p>
                <a id="bmakiba-kessai" name="bmakiba-kessai"></a>
                <div class="shop-content-label">
                    <h4><strong>お支払い一覧</strong></h4>
                </div>
                <div align="center"><img src="https://mtg.bigmagic.net/article/about/BIGMAGIC-kessai-akihabara.jpg" alt="秋葉原店お支払い一覧" border="0" class="shop-info-image"></div>
                <p>　</p>
                <a id="bmakiba-sch" name="bmakiba-sch"></a>
                <div class="shop-content-label">
                    <h4><strong>店舗大会スケジュール</strong></h4>
                </div>
                <p><iframe width="100%" height="400" src="https://calendar.google.com/calendar/embed?src=bm.mtg.akb%40gmail.com&amp;ctz=Asia%2FTokyo" frameborder="0" scrolling="no"></iframe></p>
                <p>イベントをクリックすると、イベント内容をご覧頂けます。イベントの日時は予告なく変更する場合がございます。詳細はイベント開催店舗の方にお問い合わせ下さい。</p>
                <p>　</p>
                <p><a href="https://mtg.bigweb.co.jp/shop">BIG MAGIC店舗一覧ページに戻る</a></p>
                <p><a href="https://mtg.bigweb.co.jp/shop/recruit">BIG MAGICスタッフ募集ページ</a></p>
            </div>
        </article></div>

</div>