<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under payment">
    <h1 class="headingUnder01">お支払方法</h1>
    <p>ご注文ごとに、お支払い方法をお選びいただけます。</p>
    <section class="content">

        <table class="payment">
          <tbody>
            <tr>
              <th>代金引換</th>
              <td>
                手数料: 250円</td>
            </tr>
            <tr>
              <th>クレジットカード払い</th>
              <td class="attention">
                手数料: クレジットカードでの購入の場合BIG-WEBポイントはつきませんのでご注意ください。</td>
            </tr>
            <tr>
              <th>銀行振込（三菱UFJ銀行・ゆうちょ銀行）</th>
              <td class="attention">
                手数料: 振込手数料は、お客様負担となります。</td>
            </tr>
          </tbody>
        </table>
    </section>
    <section class="content">
      <h2 class="headingUnder02">クレジットカードでお支払い</h2>
      <p>クレジットカードは、JCB・日本信販・UC・DC・ミリオンカード・UFJカード・VISA・マスターカードがご利用いただけます。お支払い方法は、一括のみとさせていただきます。<br>
        相当金額が各クレジットカード会社との契約に基づき、お客様の口座から自動引き落としされます。お引き落とし金額等の詳細は各カード会社様へご確認ください。<br>
        クレジットカードご購入の際、ご注文完了時にクレジット決済が完了となります。<br>
        後日発送や予約商品をご注文された際</p>
    </section>
    <section class="content">
      <h2 class="headingUnder02">	現金でお支払い （代引）</h2>
        <p>午前中にご注文の場合は当日発送いたしますので、翌日～2日前後（一部の地域は除きます）に到着予定となります。<br>
          商品到着時にお支払いただきます。</p>
    </section>

    <section class="content qanda">
      <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
      <ul class="qaList">
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">クレジットカードでのお買い物は安全？</div>
            </dt>
            <dd>
              <div class="qTitle">A</div><div class="qText">BIG-WEBでは、SSL(Secure Socket Layer)という暗号システムを採用し、クレジットカードの支払いにおける安全性を確保しておりますので、クレジットカードナンバーが盗まれることはありません。<br>
                ただし、お客様のブラウザがこのSSLによる暗号システムが利用できる必要があります。</div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">
                いつ商品は届きますか？
              </div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                午前中にご注文の場合は当日発送いたしますので、翌日～2日前後（一部の地域は除きます）に到着予定となります。
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">
                日時の指定はできますか？
              </div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                はい、可能です（<a href="">一部の地域</a>は除きます）。ただし、翌日の指定はできない場合もございます。<br>（交通事情等により、ご希望通りに配達できない場合もございます。）
              </div>
            </dd>
          </dl>
        </li>

      </ul>
    </section>

  </section><!--  /.contentBox-->
