<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under orderconfirmation">
    <h1 class="headingUnder01">Bigwebからのお知らせ</h1>

    <section class="guide-content">
        <h2 class="headingUnder02">在庫について</h2>
        <p class="attention">
            <strong>
                在庫については、以下の点にご注意ください。
            </strong>
        </p>
        <ul class="textList">
            <li>在庫不備で商品がご用意出来ない場合があり、こちらより不足商品の一部キャンセルをお願いする場合がございます。
                その場合はその旨をメールでお知らせいたします。</li>
            <li>品切れの商品の入荷につきましては 他のお客様より買取りがあった場合に在庫が追加されますので、 次回入荷日は未定となります。
                ※入荷日は未定となりますので、次回入荷時にご連絡することはできかねます。
                予めご了承ください。</li>
        </ul>
        <p>
            その他、ご不明な点がございましたらお問い合わせください。
        </p>

        <div class="borderBox">
            <h3 class="headingUnder03">お問い合わせ先</h3>
            <ul class="textList">
                <li><a href="/contact">お問い合わせフォーム</a></li>
                <li>e-mail: <a href="mailto:support@big-web.tv">support@big-web.tv</a></li>
                <li>BIGWEB通販部 06-6245-7767（10:00～17:00）</li>
            </ul>
        </div>
    </section>
</section><!--  /.contentBox-->