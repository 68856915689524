<table class="sameNameItemstable" [ngClass]="{'sameNameItemstable_purchase':is_purchase}">
  <thead>
    <tr>
      <th class="sameNameItemstable-cardset">収録</th>
      <th class="sameNameItemstable-rarity">レアリティ</th>
      <th class="sameNameItemstable-language">言語</th>
      <th *ngIf="!is_purchase" class="sameNameItemstable-card_condition">状態</th>
      <th *ngIf="!is_purchase" class="sameNameItemstable-stock_count">在庫</th>
      <th *ngIf="!is_purchase" class="sameNameItemstable-price">値段</th>
      <th *ngIf="is_purchase" class="sameNameItemstable-stock_count">買取上限枚数</th>
      <th *ngIf="is_purchase" class="sameNameItemstable-price">WEB限定買取価格</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of items">
      <tr [ngClass]="{'active-tr':item.id==current_item.id,'sold_out-row':item.is_sold_out}"
        (click)="selectCurrentItem(item)">
        <td class="sameNameItemstable-cardset">{{item.cardset.web}}</td>
        <td class="sameNameItemstable-rarity">{{item.rarity.web}}</td>
        <td class="sameNameItemstable-language">
          <ng-container *ngIf="item.language && item.language.web && item.language.web != '指定なし'">
            {{item.language.web}}
          </ng-container>
        </td>
        <td *ngIf="!is_purchase && validItemCondition(item.card_condition)"
          class="sameNameItemstable-card_condition">{{item.card_condition.web}}</td>
        <td *ngIf="!item.is_sold_out" class="sameNameItemstable-stock_count">{{item.stock_count}}</td>
        <td *ngIf="!item.is_sold_out" class="sameNameItemstable-price">{{item.price}}円</td>
        <td *ngIf="item.is_sold_out" class="sameNameItemstable-soldout" colspan='2'>
          <ng-container *ngIf='!is_purchase'>売り切れ</ng-container>
          <ng-container *ngIf='is_purchase'>買取終了</ng-container>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div class="sameitem_count" *ngIf="items.length!=0" fxLayoutAlgin="end">

  <span *ngIf="pagenate.pageCount>1">
    {{pagenate.page}}/{{pagenate.pageCount}}ページ
    <a (click)="loadPage(pagenate.page-1)" mat-icon-button>
      <</a> <a (click)="loadPage(pagenate.page+1)" mat-icon-button>>
    </a>
  </span>
  [同名件数：{{pagenate.count}}件]
</div>
