import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { SlickCarouselModule } from 'ngx-slick-carousel';


import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
//-------Mat--------------
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
//import {MatSidenavModule} from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
//import {MatDatepickerModule} from '@angular/material/datepicker';
//import {MatNativeDateModule} from '@angular/material/core';
//import {MatRippleModule} from '@angular/material/core';
//import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
//import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
//----------------------
import { MatBadgeModule } from '@angular/material/badge'
import { MatChipsModule } from '@angular/material/chips';
//import { MatCarouselModule } from '@ngmodule/material-carousel';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { TopComponent } from './products/top/top.component';
import { TopSliderComponent } from './products/slider/slider.component';



import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';

import { MatSidenavModule } from '@angular/material/sidenav';

import { FabMenuComponent } from './products/fab-menu/fab-menu.component';
import { PurchaseFabMenuComponent } from './purchase/fab-menu/fab-menu.component';

import { MatListModule } from '@angular/material/list';

import { ProductsComponent } from './products/main/main.component';
import { GamesComponent } from './products/games/games.component';

import { ItemViewComponent } from './commons/item-view/item-view.component';
import { ImageViewComponent } from './commons/image-view/image-view.component';
import { RowListComponent } from './products/games/row-list/row-list.component';
import { RowItemComponent } from './products/games/row-list/row-item/row-item.component';
import { OneImageComponent } from './products/games/one-image/one-image.component';
import { ImageBoxComponent } from './products/games/one-image/image-box/image-box.component';
import { DetailBoxComponent } from './products/games/one-image/detail-box/detail-box.component';
import { GridImagesComponent } from './products/games/grid-images/grid-images.component';
import { GridItemboxComponent } from './products/games/grid-images/itembox/itembox.component';



import { CartViewComponent } from './products/cart/cart-view/cart-view.component';
import { CartRowItemComponent } from './products/cart/cart-view/row-cart-item/row-cart-item.component';
import { FavoritesComponent } from './products/favorites/favorites.component';
import { SupplyGenreComponent } from './products/supply-genre/supply-genre.component';

import { CategoryTileComponent } from './category/category-tile/category-tile.component';

import { PurchaseTopComponent } from './purchase/top/top.component';
import { PurchaseComponent } from './purchase/main/main.component';
import { PurchaseGamesComponent } from './purchase/games/games.component';

//import {PurchaseItemViewComponent} from './purchase/games/item-view/item-view.component';
//import {RowListComponent} from './purchase/games/row-list/row-list.component';
//import {RowItemComponent} from './purchase/games/row-list/row-item/row-item.component';
//import {OneImageComponent} from './purchase/games/one-image/one-image.component';
//import {ImageBoxComponent} from './purchase/games/one-image/image-box/image-box.component';
//import {GridImagesComponent} from './purchase/games/grid-images/grid-images.component';
//import {PurchaseGridItemboxComponent} from './purchase/games/itembox/itembox.component';



import { PurchaseCartViewComponent } from './purchase/cart/cart-view/cart-view.component';
//import {CartRowItemComponent} from './purchase/cart-view/row-cart-item/row-cart-item.component';
//import {FavoritesComponent} from './purchase/favorites/favorites.component';
//import {SupplyGenreComponent} from './purchase/supply-genre/supply-genre.component';

//import {CategoryTileComponent} from './purchase/category-tile/category-tile.component';


import { ToolbarComponent } from './toolbar/toolbar.component';




import { ContactComponent } from './pages/contact/contact.component';
import { ShopComponent } from './pages/shop/shop.component';
import { GuideComponent } from './pages/guide/guide.component';

import { PagesBreadcrumbComponent } from './pages/breadcrumb/breadcrumb.component';



import { RegisterComponent } from './register/register.component';
import { NoticeComponent } from './notice/notice.component';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchFormComponent } from './search-bar/search-form/search-form.component';
import { SideMenuComponent } from './toolbar/side-menu/side-menu.component';
import { CardsetPickerComponent } from './search-bar/cardset-picker/cardset-picker.component';
import { LoadingComponent } from './loading/loading.component';

import { ExpandedComponent } from './search-bar/expanded/expanded.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HistoriesComponent } from './histories/histories.component';

import { HistoriesItemViewComponent } from './histories/item-view/item-view.component';
import { PaymentComponent } from './pages/guide/payment/payment.component';
import { ShippingComponent } from './pages/informations/shipping/shipping.component';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { NoticeModalComponent } from './notice/notice-modal/notice-modal.component';
import { MenubarComponent } from './menubar/menubar.component';
import { AkihabaraComponent } from './pages/shop/akihabara/akihabara.component';
import { IkebukuroComponent } from './pages/shop/ikebukuro/ikebukuro.component';
import { NagoyaComponent } from './pages/shop/nagoya/nagoya.component';
import { NagoyaZeroComponent } from './pages/shop/nagoya-zero/nagoya-zero.component';
import { NambaComponent } from './pages/shop/namba/namba.component';
import { RecruitComponent } from './pages/shop/recruit/recruit.component';
import { CategoryComponent } from './category/category.component';

import { CardViewerComponent } from './commons/card-viewer/card-viewer.component';
import { CardItemboxComponent } from './commons/card-viewer/itembox/itembox.component';
import { SameitemListComponent } from './commons/card-viewer/itembox/sameitem-list/sameitem-list.component';

import { CardsetListComponent } from './cardset-list/cardset-list.component';

import { OrderguideComponent } from './pages/guide/orderguide/orderguide.component';
import { PostageComponent } from './pages/guide/postage/postage.component';
import { OrderconfirmationComponent } from './pages/guide/orderconfirmation/orderconfirmation.component';
import { CancelComponent } from './pages/guide/cancel/cancel.component';
import { PointbackComponent } from './pages/guide/pointback/pointback.component';
import { CardconditionsMtgComponent } from './pages/guide/cardconditions-mtg/cardconditions-mtg.component';
import { CardconditionsComponent } from './pages/guide/cardconditions/cardconditions.component';
import { NoticeSharedcardsComponent } from './pages/guide/notice-sharedcards/notice-sharedcards.component';
import { StockInfoComponent } from './pages/guide/stock-info/stock-info.component';
import { TermsComponent } from './pages/guide/terms/terms.component';
import { PrivacyComponent } from './pages/guide/privacy/privacy.component';
import { CompanyComponent } from './pages/guide/company/company.component';
import { ReservationsInfoComponent } from './pages/guide/reservations-info/reservations-info.component';
import { TroubleMailComponent } from './pages/informations/trouble-mail/trouble-mail.component';


import { WebPurchaseComponent } from './pages/guide/web-purchase/web-purchase.component';
import { BulkPurchaseComponent } from './pages/guide/bulk-purchase/bulk-purchase.component';
import { ShopPurchaseComponent } from './pages/guide/shop-purchase/shop-purchase.component';
import { ExplainFlowsComponent } from './purchase/explain-flows/explain-flows.component';
import { WhatIsBigwebmanComponent } from './pages/guide/what-is-bigwebman/what-is-bigwebman.component';


@NgModule({
    declarations: [
        AppComponent,
        TopComponent,
        TopSliderComponent,

        LoginComponent,

        FooterComponent,
        GamesComponent,

        ItemViewComponent,
        ImageViewComponent,
        GridItemboxComponent,
        FabMenuComponent,
        PurchaseFabMenuComponent,

        OneImageComponent,
        RowListComponent,
        RowItemComponent,
        ImageBoxComponent,
        DetailBoxComponent,
        GridImagesComponent,

        PurchaseTopComponent,
        PurchaseComponent,
        PurchaseGamesComponent,
        PurchaseCartViewComponent,

        ProductsComponent,

        ToolbarComponent,
        SearchFormComponent,

        ContactComponent,
        ShopComponent,
        GuideComponent,

        PagesBreadcrumbComponent,

        CartViewComponent,
        CartRowItemComponent,

        RegisterComponent,
        NoticeComponent,
        SideMenuComponent,
        BreadcrumbComponent,
        SearchBarComponent,
        CardsetPickerComponent,

        LoadingComponent,
        FavoritesComponent,
        ExpandedComponent,
        HistoriesComponent,

        HistoriesItemViewComponent,
        PaymentComponent,
        ShippingComponent,
        PageNotFoundComponent,
        NoticeModalComponent,
        MenubarComponent,
        AkihabaraComponent,
        IkebukuroComponent,
        NagoyaComponent,
        NagoyaZeroComponent,
        NambaComponent,
        RecruitComponent,
        CategoryComponent,
        CardViewerComponent,
        CardItemboxComponent,
        CardsetListComponent,
        SupplyGenreComponent,

        OrderguideComponent,
        PostageComponent,
        OrderconfirmationComponent,
        CancelComponent,
        PointbackComponent,
        CardconditionsMtgComponent,
        CardconditionsComponent,
        NoticeSharedcardsComponent,
        StockInfoComponent,
        TermsComponent,
        PrivacyComponent,
        CompanyComponent,
        ReservationsInfoComponent,
        TroubleMailComponent,
        SameitemListComponent,
        CategoryTileComponent,

        WebPurchaseComponent,
        BulkPurchaseComponent,
        ShopPurchaseComponent,
        ExplainFlowsComponent,
        WhatIsBigwebmanComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        OverlayModule,
        SlickCarouselModule,
        MatSidenavModule,
        NgxUsefulSwiperModule,
        MatDialogModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatBadgeModule,
        MatListModule,
        DragDropModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatGridListModule,
        MatMenuModule,
        MatMenuModule,
        FormsModule, ReactiveFormsModule,
        MatTabsModule,
        MatFormFieldModule, MatButtonModule, MatInputModule, MatCheckboxModule, MatSelectModule, MatSlideToggleModule, MatExpansionModule,
        InfiniteScrollModule,
        //MatCarouselModule.forRoot(),
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    exports: [InfiniteScrollModule],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {}
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
