
<div >
    <mat-toolbar >
        <mat-toolbar-row  class="default_header">
            <a href="">
                <img class="logo-img" src="assets/images/logo.png">
            </a>
            <span class="spacer"></span>


        </mat-toolbar-row>

    </mat-toolbar>

    <!-- <a class="lang-menu" *ngIf="localeId=='ja'" href="{{en_host}}/en/{{urlPath}}">
        <span class="active-lang" >JP</span>/EN
    </a>
    <a class="lang-menu" *ngIf="localeId=='en'" href="{{ja_host}}/ja/{{urlPath}}">
        JP/<span class="active-lang" >EN</span>
    </a> -->

    <mat-card class="login-card" >
        <mat-card-header>
            <div mat-card-avatar class="login-header-image"></div>
            <mat-card-title><span i18n>新規会員登録</span></mat-card-title>

        </mat-card-header>

        <mat-card-content>
            <form  [formGroup]="registerForm">
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>ユーザーID</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="userId" required>
                        <mat-error *ngIf="registerForm.value.userId.invalid">{{getUserIdsError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>パスワード</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="password" required>
                        <mat-error *ngIf="registerForm.value.password.invalid">{{getPasswordsError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>パスワード(確認)</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="passwordsConfirm" required>
                        <mat-error *ngIf="registerForm.value.passwordsConfirm.invalid">{{getPasswordsConfirmError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>メールアドレス</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput placeholder="sample@login.com" formControlName="email" required>
                        <mat-error *ngIf="registerForm.value.email.invalid">{{getEmailsError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>メールアドレス(確認)</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="emailsConfirm" required>
                        <mat-error *ngIf="registerForm.value.emailsConfirm.invalid">{{getEmailsConfirmError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label><span i18n>ユーザー名(姓)</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="lastName" required>
                        <mat-error *ngIf="registerForm.value.lastName.invalid">{{getLastNamesError()}}</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label><span i18n>ユーザー名(名)</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="firstName" required>
                        <mat-error *ngIf="registerForm.value.firstName.invalid">{{getFirstNamesError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label><span i18n>フリガナ(姓)</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="lastNameKana" required>
                        <mat-error *ngIf="registerForm.value.lastNameKana.invalid">{{getLastNameKanasError()}}</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label><span i18n>フリガナ(名)</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="firstNameKana" required>
                        <mat-error *ngIf="registerForm.value.firstNameKana.invalid">{{getFirstNameKanasError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>郵便番号</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="postalCode" required>
                        <mat-error *ngIf="registerForm.value.postalCode.invalid">{{getPostalCodesError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>都道府県</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="prefecture" required>
                        <mat-error *ngIf="registerForm.value.prefecture.invalid">{{getPrefecturesError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>市区町村</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="municipality" required>
                        <mat-error *ngIf="registerForm.value.municipality.invalid">{{getMunicipalitiesError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>番地・建物名</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="address" required>
                        <mat-error *ngIf="registerForm.value.address.invalid">{{getAddressesError()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <mat-label><span i18n>電話番号</span></mat-label>
                        <button mat-icon-button matPrefix >
                            <mat-icon>parson</mat-icon>
                        </button>
                        <input matInput formControlName="phoneNumber" required>
                        <mat-error *ngIf="registerForm.value.phoneNumber.invalid">{{getPhoneNumbersError()}}</mat-error>
                    </mat-form-field>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="end">
            <button  (click)="onRegister()" mat-flat-button type="submit" color="primary"><span i18n>登録</span></button>
        </mat-card-actions>
    </mat-card>

</div>