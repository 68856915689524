<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under reservations">
    <h1 class="headingUnder01">予約商品の購入</h1>
    <section class="guide-content">
      <h2 class="headingUnder02">予約商品の購入と注意事項をご案内致します。</h2>
      <ul class="textList">
        <li>ご注文方法は通常の商品と同じになります。</li>
        <li>複数の予約商品をご注文頂きました場合、全ての商品が揃ってから発送致します。別発送を希望の場合はコメント欄より『別発送』の旨ご指示ください。（クレジットカード決済は別発送出来ませんのでご注意下さい）</li>
        <li>通常商品と予約商品との同時購入も可能ですが、予約商品を含めまして全ての商品が揃ってからの発送となります。（途中のキャンセルはできませんのでご注意下さい。）</li>
        <li>通常商品と予約商品との同時購入されて別発送を希望される場合、コメント欄に『予約商品と別発送』等のご指示をお願い致します。（クレジットカード決済は別発送出来ませんのでご注意下さい）</li>
        <li>予約商品が入荷いたしましたら、メール【商品入荷のご案内】にてお知らせ致します<br>発送後、メール【商品発送のご案内】にてお知らせ致します。</li>
        <li>お客様の都合による「キャンセル」はお受けいたしません。ご購入の際十分ご検討の上 ご購入願います。</li>
        <li>メーカーの都合上 少量しか入荷できない（数量減数の為）場合は、大変申し訳ありませんが、ご予約受付順に納品させて頂きます。予約商品をお渡しできなかったお客様に対しましては、改めましてこちらからメールにて御連絡させていただきます。ご了承ください。クレジットカードでご購入のお客様にはご返金致します。</li>
      </ul>
    </section>


    <section class="guide-content qanda">
      <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
      <ul class="qaList">
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">予約商品はいつとどくの</div>
            </dt>
            <dd>
              <div class="qTitle">A</div><div class="qText">発売日の前後2日間以内に発送致します。<br>※海外の商品で海外での発売日に入荷できない商品も御座いますのでご了承ください</div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">予約商品と通常商品を同時購入したい</div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                予約商品と通常商品の同時購入希望の場合、同じカートで購入できますが、全ての商品のがそろってから発送致します。<br>
                別発送をご希望される場合は、コメント欄に『予約商品と別発送』とご記入お願い致します。（クレジットカード決済は別発送出来ませんのでご注意下さい）
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">複数の予約商品を同時購入したい</div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                複数の予約商品をご注文頂きました場合、全ての商品が揃ってから発送致します。別発送を希望の場合はコメント欄より『別発送』の旨ご指示ください。（クレジットカード決済は別発送出来ませんのでご注意下さい）
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">予約注文できたか確認したい</div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                  ご注文を承りましたら【ご注文確認】メールをお送りします。<br />
                  また、ご注文の確認は<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【注文履歴】</a>からでもご確認いただけます。
                  ご注文内容をお知らせしていますのでご確認ください。<br />
              </div>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="question">
            <dt>
              <div class="qTitle">Q</div>
              <div class="qText">予約注文したのに【ご注文確認】メールが届かない</div>
            </dt>
            <dd>
              <div class="qTitle">A</div>
              <div class="qText">
                  Eメールが届かない場合は、ご注文を承っていないかEメールアドレスに誤りがある可能性があります。ご登録いただいたEメールアドレスに間違いがありますと大切なご案内がお届けできません。<br />
                  <br />
                  <a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【会員情報の確認】</a>で登録メールアドレスをご確認ください。誤りがございましたら、再度正しいメールアドレスを登録してください。<br />
                  アドレスに誤りがない場合や【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
                  <br />
                  ご注文の確認は<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【注文履歴】</a>からでもご確認いただけます。<br />
                  <br />
                  注文完了等のメールが届かない場合、迷惑メールボックスに送られている場合がございます。 <br />
                  迷惑メールボックスをご確認頂いてもメールが届いていない場合、お手数ですが、登録を別のアドレスにご変更ください。<br />
                  メールアドレス変更後【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
                  <br />
                  ※現在、発生しているメールの不具合に関する情報は<a href="/informations/trouble-mail">こちら</a>です。<br />
                  <br />
                  大変ご迷惑をおかけしますが、宜しくお願いいたします。<br />
              </div>
            </dd>
          </dl>
        </li>

      </ul>
    </section>

  </section><!--  /.contentBox-->
