import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GamesService } from '../../_services';
import { ItemsService } from '../../_services';
import { SearchService } from '../../_services';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { BrowserStandardEncoder } from '../../utils/browser-standard-encoder';
import { TypeConstant } from '../../../appConstant';

@Component({
    selector: 'app-cardset-picker',
    templateUrl: './cardset-picker.component.html',
    styleUrls: ['./cardset-picker.component.scss']
})
export class CardsetPickerComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;

    game_id: number = 0;
    game: any;

    selectedCardsets: any[] = [];
    originalCardsets: any;
    searchword: string = '';
    cardsetOptions: any;
    criteria: any;
    selectCartsetForm: FormGroup;
    is_purchase: boolean;
    category: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CardsetPickerComponent>,
        public gamesService: GamesService,
        public itemsService: ItemsService,
        public searchService: SearchService,
        private router: Router,
        private activeRouter: ActivatedRoute,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        let me = this;
        me.game = me.data.game;
        me.criteria = me.data.criteria;

        me.initSearchFormFields();

        me.selectCartsetForm = me.formBuilder.group({
                cardset: new FormControl(),
                isMultipleSelections: new FormControl(),
        });

        me.selectCartsetForm.patchValue({
            'isMultipleSelections': localStorage.getItem('isMultipleSelections') ? true : false,
            'cardset': me.selectedCardsets
        })
    }

    initSearchFormFields() {
        let me = this;

        me.cardsetOptions = me.data.cardsets;
        me.originalCardsets = me.cardsetOptions;
        me.is_purchase = me.data.is_purchase;
        me.selectedCardsets = me.data.selectedCardsets;

        me.category = me.data.category ? me.data.category : 'list';
        if (me.category == 'boxes') {
            me.criteria.is_box = 1;
        } else {
            me.criteria.is_box = 0;
        }

        /**
         * @todo cardsetsがJSON形式の場合に変換して配列型に戻す
         */
        for (const [key, value] of Object.entries(me.criteria)) {
            if (key === 'cardsets') {
                me.criteria.cardsets = this.searchService.ConvertJsonStringToArray(value);
            }
        }
    }

    onToggle(event) {
        if (event.checked) {
            localStorage.setItem('isMultipleSelections', 'true');
        } else {
            localStorage.removeItem('isMultipleSelections');
        }
    }

    closeDialog() {
        let me = this;
        me.dialogRef.close();
    }

    resetFilter() {
        let me = this;
        me.selectedCardsets = [];
        me.criteria.cardsets = [];
        me.selectCartsetForm.patchValue({
            'cardset': []
        });
    }

    submitFilter() {
        let me = this;
        let cardsets = me.selectCartsetForm.value.cardset;
        me.criteria.cardsets = me.selectCartsetForm.value.cardset;
        me.dialogRef.close();

        Object.keys(me.criteria).map(function(key){
            if(me.criteria[key] == null) {
                delete me.criteria[key];
            }
            /**
             * @todo セットを検索した場合はピックアップを外す
             */
            if(key === 'recommend_id') {
                delete me.criteria[key];
            }
        });

        let criteria;

        if (cardsets) {
            me.criteria = Object.assign(me.criteria, { "cardsets": JSON.stringify(cardsets) });
        }
        criteria = me.criteria;

        let params = new HttpParams({encoder: new BrowserStandardEncoder()}).appendAll(criteria);
        let url = '/' + (me.is_purchase ? 'purchase' : 'products')
        + '/'
        + me.game.code
        + '/list?'
        + params.toString();

        return me.router.navigateByUrl(url);
    }

    setCardsetKeyword(val) {
        let me = this;
        me.cardsetOptions = me.originalCardsets.filter(
            cardset => {
                return cardset.name.toLowerCase().indexOf(val.toLowerCase()) >= 0;
            }
        );
    }

    filterCardset(cardset) {
        let me = this;
        me.dialogRef.close();

        me.criteria.cardsets = cardset.id;
        me.criteria.is_box = me.category == 'boxes' ? 1 : 0
        let params = new HttpParams({encoder: new BrowserStandardEncoder()}).appendAll(me.criteria);
        let url = '/' + (me.is_purchase ? 'purchase' : 'products')
        + '/'
        + me.game.code
        + '/list?'
        + params.toString();

        me.router.navigateByUrl(url);
    }

}