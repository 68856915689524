import { Component, OnInit, Input } from '@angular/core';
import { ItemsService, StoresService, SettingsService } from '../../../../_services';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { ImageViewComponent } from '../../../../commons/image-view/image-view.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompileShallowModuleMetadata } from '@angular/compiler';


@Component({
    selector: 'app-detail-box',
    templateUrl: './detail-box.component.html',
    styleUrls: ['./detail-box.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                //   height: 'auto',
                opacity: '0.9'
            })),
            state('closed', style({
                //     height: '0',
                opacity: '0'
            })),
            transition('open <=> closed', [
                animate('200ms')
            ])
        ]),
    ],
})
export class DetailBoxComponent implements OnInit {

    localeId: string;

    @Input() item: any;
    @Input() is_purchase: boolean;
    @Input() is_reservation: boolean;
    @Input() is_cardview: boolean;
    @Input() is_modal_open: boolean;
    tempCount: number = 0;
    path_str: string;
    is_single: boolean = false;
    imageL: string = '';
    webpL: string = '';

    @Input() category: string = 'list';

    constructor(
        private router: Router,
        public dialog: MatDialog,
        public itemsService: ItemsService,
        public storesService: StoresService,
        public settingsService: SettingsService
    ) {
        let me = this;
        let path = this.router.url.split('/');
        me.path_str = path[1] == 'purchase' ? 'purchase' : 'products';
        me.is_purchase = me.path_str === 'purchase' ? false : true;
        me.localeId = this.settingsService.getLocale();
    }

    ngOnInit(
    ): void {
        let me = this;

        let regx = /(jp(e)?g|png|gif)$/i;
        me.is_single = me.item.is_single;
        me.imageL = me.item.imageL;
        me.webpL = me.item.imageL.replace(regx, 'webp');
    }

    getBadgeCount(item: any) {
        let tempCount = 0;
        if (item.relatedItems) {
            tempCount = item.relatedItems.reduce((prev, current) => {
                return prev + current.quantity - current.temp_quantity;
            }, 0);
        }

        if (tempCount != 0) {
            return tempCount;
        } else {
            return false;
        }
    }

    openModalItem(item, is_purchase: boolean = false) {
        this.itemsService.openImageView(item, this.is_purchase);
    }

    showOverlay(item) {
        item.is_open = true;
    }

    hideOverlay(item) {
        item.is_open = false;
    }

    toggleOverlay(item) {
        item.is_open = !item.is_open ? true : false;
    }

    toggleFavorite(item) {
        item.is_favorite = !item.is_favorite ? true : false;
        this.storesService.addFavorite(item, item.is_favorite);
    }

    addCount(item, num) {
        let relatedItem = item.relatedItems[0];
        if (num > 0) {
            if (relatedItem.stock_count > relatedItem.quantity) {
                relatedItem.quantity = num + relatedItem.quantity;
            }
        } else {
            if (relatedItem.quantity > 0) {
                relatedItem.quantity = num + relatedItem.quantity;
            } else {
                relatedItem.quantity = relatedItem.stock_count;
            }
        }
    }

    selectionChange($event, item) {
        if ($event.value < 0) {
            item.relatedItems[0].quantity = item.relatedItems[0].stock_count
        } else {
            item.relatedItems[0].quantity = $event.value;
        }
    }

    checkStock($event, item) {

        let relatedItem = item.relatedItems[0];
        if (relatedItem.stock_count < parseInt($event.target.value)) {
            relatedItem.quantity = relatedItem.stock_count;
            $event.target.value = relatedItem.stock_count;
        } else if (parseInt($event.target.value) < 0) {
            relatedItem.quantity = 0;
            $event.target.value = 0;
        } else {
            relatedItem.quantity = parseInt($event.target.value);
        }
    }

    getStockOptions(stock: number) {
        let options: number[] = [];
        let max_stock = stock > 10 ? 11 : stock + 1;
        for (let i = 0; i < max_stock; i++) {
            options.push(i);
        }
        return options;
    }

    addToCart(item) {
        let me = this;
        item.relatedItems[0]['game_slip'] = item.game.slip;

        item.relatedItems
            .filter(relatedItem => relatedItem.quantity != relatedItem.temp_quantity)
            .map(relatedItem => relatedItem.temp_quantity = relatedItem.quantity);
        let tempCarts = me.item.relatedItems;
        me.storesService.updateCartItem(tempCarts);
    }

    addToCartByNow(item: any) {
        let me = this;
        me.item.relatedItems[0]['game_slip'] = item.game.slip;
        if (me.item.relatedItems[0].quantity < me.item.relatedItems[0].stock_count) {
            me.item.relatedItems[0].quantity = me.item.relatedItems[0].quantity + 1;
            me.item.relatedItems[0].temp_quantity = me.item.relatedItems[0].quantity;
            let tempCarts = me.item.relatedItems;
            me.storesService.addCartItems(tempCarts);
        };
    }

    addToPurchaseCart(item) {
        let me = this;
        item.relatedItems[0]['game_slip'] = item.game.slip;

        item.relatedItems
            .filter(relatedItem => relatedItem.quantity != relatedItem.temp_quantity)
            .map(relatedItem => relatedItem.temp_quantity = relatedItem.quantity);
        let tempCarts = me.item.relatedItems;
        me.storesService.updatePurchaseCartItem(tempCarts);
    }

    addToPurchaseCartByNow(item: any) {
        let me = this;
        me.item.relatedItems[0]['game_slip'] = item.game.slip;
        if (me.item.relatedItems[0].quantity < me.item.relatedItems[0].stock_count) {
            me.item.relatedItems[0].quantity = me.item.relatedItems[0].quantity + 1;
            me.item.relatedItems[0].temp_quantity = me.item.relatedItems[0].quantity;
            let tempCarts = me.item.relatedItems;
            me.storesService.addPurchaseCartItems(tempCarts);
        };
    }

    searchSameProducts(item) {
        let me = this;
        me.itemsService.searchSameProducts(item, me.is_purchase)
    }

    openImageModal() {
        let me = this;
        let dialogRef = this.dialog.open(ImageViewComponent, {
            panelClass: 'p-modal-confirm',
            id: 'modal-image-view',
            disableClose: false,
            autoFocus: true,
            width: '400px',
            data: { 'item': me.item, 'is_purchase': me.is_purchase }
        });
    }
}
