import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-purchase',
  templateUrl: './web-purchase.component.html',
  styleUrls: ['./web-purchase.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class WebPurchaseComponent implements OnInit {

  title: string = 'WEB買取について';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: 'WEB買取について', url: '/guide/web-purchase'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}