import { Component, HostListener, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GamesService, ItemsService, StoresService, SettingsService } from '../../_services';
import { Swiper } from 'swiper';
import { Subscription } from 'rxjs';
import { TypeConstant, TypeGenreConstant } from '../../../appConstant';

@Component({
    selector: 'app-purchase',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class PurchaseComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;
    TypeGenreConstant: typeof TypeGenreConstant = TypeGenreConstant;

    is_purchase: boolean = true;
    is_reservation: boolean = true;

    breadcrumbs: any[];
    @Output() addToCartItems: EventEmitter<any> = new EventEmitter();
    private subscription: Subscription;
    private favoriteSubscription: Subscription;

    title: string = '';
    game_title: string = '';
    searchword: string = '';
    game: any;
    favorites: any;
    criteria: any = {};
    swiper: any;
    is_scrolled: boolean;
    recommends: any = [];
    boxItems: any = [];
    slides: any;
    cardsets: any;
    notice: string;
    category: string = '';
    bulk_purchase_id: any;

    supplytypes: any = [];
    supplytype_id: number;

    constructor(
        private route: ActivatedRoute,
        public gamesService: GamesService,
        public itemsService: ItemsService,
        public storesService: StoresService,
        public settingsService: SettingsService,

        //public loadingService: LoadingService,
    ) {

    }

    ngOnInit(): void {
        let me = this;
        me.route.paramMap.pipe(
            //map(params => new Map([
            //    ['title', params.get('title')],
            //    ['category', params.get('category')]
            //])),
        ).subscribe(params => {
            me.game_title = params.get('title');
            //me.game_title = params['title'];

            me.loadGameConfig(me.game_title);

            if (me.game.type_genre_id == TypeGenreConstant.SUPPLY || me.game.type_genre_id == TypeGenreConstant.CHARACTER_SUPPLY) {
                me.category = TypeConstant.STR_SUPPLY;
            } else {
                me.category = params.get('category');
                //me.category = params['category'];
            }

            me.subscription = this.storesService.$purchase_cart.subscribe(
                items => {
                    if (items) {
                        me.onChangeItem(items);
                    } else {
                        me.removeCartItems();
                    }

                }
            );

            me.scrollTop();
        });

    }

    ngAfterViewInit() {
        let me = this;
        //me.loadingService.hideLoading();
    }


    ngOnDestroy() {
        let me = this;
        if (me.subscription) {
            me.subscription.unsubscribe();
        }
    }

    loadGameConfig(game_title) {
        let me = this;
        let game = me.gamesService.getConfigs(game_title, {
            'is_buying' : true
        });
        me.game = game;

        me.bulk_purchase_id = me.gamesService.getBulkPurchaseId(me.game.id);
        me.setCardset();
        me.setGameTitle();
        me.gamesService.getBanners(me.game.id).subscribe(banners => {
            me.slides = me.filterSlides(banners);
        });
    }

    setCardset() {
        let me = this;
        //me.criteria.game_id = me.game.id;
        me.criteria.is_purchase = me.is_purchase ? 1 : 0;
        me.cardsets = me.game.cardsets;
        //  毎回読み込むようにコメントアウト
        //me.setRecommends();
        me.loadRecommends();
    }

    setGameTitle() {
        let me = this;

        me.title = '' + me.game.title + '高価買取なら【BIGWEB】';

        let description = '';
        if(me.game.meta_description != null) {
            description = me.game.meta_description;
        } else {
            description = me.game.title
            + "の通販なら、日本最大級の品揃えのカードショップ【BIGWEB】におまかせください。"
            + "数量限定の最新弾シングルや、海外版シングル、海外版パックなど、あなたの欲しいカードが見つかります。";
        }

        me.settingsService.setTitle(me.title, description);
        me.breadcrumbs = me.settingsService.getBreadcrumbsByGame(me.game, me.is_purchase);
    }

    filterSlides(banner) {
        let me = this;

        return banner.filter(item => {
            if (me.category == 'boxes') {
                return (item.is_boxes);
            } else if (me.category == 'supply') {
                return (item.is_supply);
            }
            return true;
        });
    }

    setRecommends() {
        let me = this;

        me.recommends = me.gamesService.getRecommends(me.game.id);
        if (me.recommends.length == 0) {
            me.notice = "おすすめ商品が登録されていません。"
        } else {
            me.notice = null;
        }

        me.loadAllBoxes();
    }

    loadRecommends() {
        let me = this;
        me.itemsService.loadBuyingFutures(me.game.id).subscribe((result: any) => {
            if (result) {
                me.recommends = result.items;
                if (me.recommends.length == 0) {
                    me.notice = "おすすめ商品が登録されていません。"
                } else {
                    me.notice = null;
                }
                me.loadAllBoxes();
            }
        });
    }

    loadAllBoxes() {
        let me = this;
        if (me.category == 'reservation') {
            me.is_reservation = true;
        }
        if (me.category == 'boxes') {
            me.criteria.is_box = 1;
            me.criteria.in_stock = 1;
            me.itemsService.load(me.game.id, me.criteria).subscribe((result: any) => {
                me.boxItems = result.items;
                //                me.boxItems = result.items.filter(item => {
                //                    return !item.relatedItems[0].is_sold_out;
                //                });
                me.refresFreatureItems();
            });
        } else {
            me.refresFreatureItems();
        }
    }

    refresFreatureItems() {
        let me = this;
        setTimeout(function () {
            me.storesService.refreshPurchaseItems();
            me.setSwiper();
            me.scrollTop();
            //me.loadingService.hideLoading();
        }, 100);
    }

    onChangeFavorites(favorites) {

        let me = this;
        let itemsId = favorites.map(item => { if (item) return item.id });
        me.recommends = me.recommends.map(recommend => {
            recommend.products = recommend.products.map(product => {
                if (itemsId.indexOf(product.id) > -1) {
                    let tage = favorites.filter(tItem => { if (tItem && tItem.id == product.id) return tItem });
                    if (tage.length > 0) {
                        product.is_favorite = true;
                    }
                }
                return product;
            })
            return recommend;
        });
    }


    onChangeItem(targetItems) {
        let me = this;
        let itemsId = targetItems.map(item => { if (item) return item.id });
        me.recommends = me.recommends.map(recommend => {
            recommend.products = recommend.products.map(product => {
                if (itemsId.indexOf(product.id) > -1) {
                    let tage = targetItems.filter(tItem => { if (tItem && tItem.id == product.id) return tItem });
                    if (tage.length > 0) {
                        product.relatedItems[0].quantity = tage[0].quantity;
                        product.relatedItems[0].temp_quantity = product.relatedItems[0].quantity;
                    }
                }
                return product;
            })
            return recommend;
        });


        me.boxItems = me.boxItems.map(item => {
            item.relatedItems.map(relatedItem => {
                if (itemsId.indexOf(relatedItem.id) > -1) {
                    let tage = targetItems.filter(tItem => { if (tItem && tItem.id == relatedItem.id) return tItem });
                    if (tage.length > 0) {
                        relatedItem.quantity = tage[0].quantity;
                        relatedItem.temp_quantity = relatedItem.quantity;
                    }
                }
                return relatedItem;
            })
            return item;
        });

    }

    removeCartItems() {

        //        let me = this;
        //        me.items = me.items.map(item => {
        //            item.relatedItems.map(relatedItem => {
        //                relatedItem.quantity = 0;
        //                relatedItem.temp_quantity = 0;
        //                return relatedItem;
        //            })
        //            return item;
        //        });
    }

    runAddToCartItems(items: any) {
        this.storesService.addPurchaseCartItems(items);

    }
    scrollTop() {
        window.scroll(0, 0);

    }
    onSelectCardset(param) {
        //this.loadingService.loading();
    }

    setSwiper() {
        let me = this;
        new Swiper('.recommend-items', {
            preloadImages: false, //画像の先読みを無効に
            lazy: true, // Lazy Loading を有効に
            //loop: true,
            spaceBetween: 5,
            //slidesPerView: 'auto',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loopedSlides: 1,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            slideActiveClass: 'swiper-slide-active',
            breakpoints: {
                480: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                640: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                800: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                960: {
                    slidesPerView: 6,
                    slidesPerGroup: 6,
                },
                1080: {
                    slidesPerView: 7,
                    slidesPerGroup: 7,
                },
                1600: {
                    slidesPerView: 8,
                    slidesPerGroup: 8,
                }
            }
        });
    }
}

