<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under orderconfirmation">
    <h1 class="headingUnder01">Bigwebからのお知らせ</h1>

    <section class="guide-content">
        <h2 class="headingUnder02">BIGMAGIC店舗との共有商品について</h2>
        <p class="attention">
            <strong>
                BIGMAGIC店舗との共有商品については、以下の点にご注意ください。
            </strong>
        </p>
        <ul class="textList">
            <li>この商品は店舗に商品を展示している為、注文が重なった場合、ご用意できない場合がございます。</li>
            <li>発送については店舗から商品を取り寄せた後に発送となる為、通常より1～2日程度発送が遅れる場合がございます。予めご了承頂きますようお願い致します。</li>
        </ul>
        <p>
            その他、ご不明な点がございましたらお問い合わせください。
        </p>

        <div class="borderBox">
            <h3 class="headingUnder03">お問い合わせ先</h3>
            <ul class="textList">
                <li><a href="/contact">お問い合わせフォーム</a></li>
                <li>e-mail: <a href="mailto:support@big-web.tv">support@big-web.tv</a></li>
                <li>BIGWEB通販部 06-6245-7767（10:00～17:00）</li>
            </ul>
        </div>
    </section>
</section><!--  /.contentBox-->
