import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FavoritesComponent } from '../products/favorites/favorites.component';
import { HistoriesComponent } from '../histories/histories.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class StoresService {
    //販売
    private cartStore = new Subject<any>();
    public $cart = this.cartStore.asObservable();

    private tempCartStore = new Subject<any>();
    public $tempCart = this.tempCartStore.asObservable();

    private cartItemEvent = new Subject<any>();
    public $cartItem = this.cartItemEvent.asObservable();

    private cartOpenEvent = new Subject<any>();
    public $cartOpen = this.cartOpenEvent.asObservable();

    private cartCountEvent = new Subject<any>();
    public $cartCount = this.cartCountEvent.asObservable();


    private favoritesStore = new Subject<any>();
    public $favorite = this.favoritesStore.asObservable();

    private historiesStore = new Subject<any>();
    public $history = this.historiesStore.asObservable();

    private filtersStore = new Subject<any>();
    public $filters = this.filtersStore.asObservable();

    favorites: any[] = [];

    histories: any[] = [];
    favoriteModal: any;
    historyModal: any;

    cartItems: any[] = [];
    tempCartCount: number = 0;


    private purchase_cartStore = new Subject<any>();
    public $purchase_cart = this.purchase_cartStore.asObservable();

    private purchase_tempCartStore = new Subject<any>();
    public $purchase_tempCart = this.purchase_tempCartStore.asObservable();

    private purchase_cartOpenEvent = new Subject<any>();
    public $purchase_cartOpen = this.purchase_cartOpenEvent.asObservable();

    private purchase_cartItemEvent = new Subject<any>();
    public $purchase_cartItem = this.purchase_cartItemEvent.asObservable();

    private purchase_cartCountEvent = new Subject<any>();
    public $purchase_cartCount = this.purchase_cartCountEvent.asObservable();

    purchase_cartItems: any[] = [];
    purchase_tempCartCount: number = 0;
    constructor(public dialog: MatDialog) {
        let me = this;
        let favorites = sessionStorage.getItem('favorites');
        let histories = sessionStorage.getItem('histories');

        me.favorites = favorites ? JSON.parse(favorites) : [];
        me.histories = histories ? JSON.parse(histories) : [];
    }

    getFavoritess() {
        return this.favorites;
    }

    getHistories(game) {
        let me = this;
        let histories = sessionStorage.getItem('histories');
        return histories ? JSON.parse(histories) : [];
    }

    onAddedFavorite() {

        this.favoritesStore.next(this.favorites);
    }
    addFavorite(item: any, isFavorite: boolean = true) {

        let me = this;
        for (let i = 0; i < me.favorites.length; i++) {
            if (me.favorites[i].id == item.id) {
                me.favorites.splice(i, 1);
            }
        }
        if (isFavorite) {
            me.favorites.unshift(item);
        }

        sessionStorage.setItem('favorites', JSON.stringify(me.favorites));
        me.onAddedFavorite();
    }

    addHistory(history: any) {
        let me = this;
        if (me.histories[0] == null) {
            me.histories = [];
        }
        if (me.histories.length > 0) {
            for (let i = 0; i < me.histories.length; i++) {
                if (history.item) {
                    if (me.histories[i] && me.histories[i].id == history.item.id) {
                        me.histories.splice(i, 1);
                    }
                }
            }
        }
        me.histories.unshift(history.item);
        sessionStorage.setItem('histories', JSON.stringify(me.histories));
        me.onChengedHistory();
    }

    onChengedHistory() {
        this.historiesStore.next(this.histories);
    }

    removeFromHistory(history: any) {
        let me = this;
        for (let i = 0; i < me.histories.length; i++) {
            if (me.histories[i].id == history.item.id) {
                me.histories.splice(i, 1);
            }
        }
        sessionStorage.setItem('histories', JSON.stringify(me.histories));
        me.onChengedHistory();
    }

    onClearkeyword() {
        this.filtersStore.next();
    }

    onAddedCartItems(items: any) {
        this.cartStore.next(items);
    }

    onAddTempCartItems(items: any) {
        this.tempCartStore.next(items);
    }

    openCart() {
        this.cartOpenEvent.next();
    }

    openPurchaseCart() {
        this.purchase_cartOpenEvent.next();
    }

    openFavorite() {
        this.favoriteModal = this.dialog.open(FavoritesComponent, {
            panelClass: 'p-modal-confirm',
            width: '860px',
            height: '80%',
        });
    }

    openHistory() {
        this.historyModal = this.dialog.open(HistoriesComponent, {
            panelClass: 'p-modal-confirm',
            width: '860px',
            height: '80%',

        });
    }

    refreshItems() {
        let cartItems = sessionStorage.getItem('cartItems');
        if (cartItems) {
            this.cartItems = JSON.parse(cartItems);
            this.cartStore.next(this.cartItems);
        }
        this.favoritesStore.next(this.favorites);
    }

    isArray(item) {
        return Object.prototype.toString.call(item) === '[object Array]';
    }

    isObject(item) {
        return typeof item === 'object' && item !== null && !this.isArray(item);
    }

    addCartItems(items: any) {
        let me = this;

        if (me.cartItems == null || me.cartItems.length == 0) {
            if (items == null) {
                me.cartItems = [];
            } else {
                me.cartItems = items;
            }
        } else {
            for (let i = 0; i < items.length; i++) {
                let item
                for (let y = 0; y < me.cartItems.length; y++) {
                    if (me.cartItems[y] && items[i] && me.cartItems[y].id == items[i].id) {
                        me.cartItems[y] = items[i];
                    }
                }
            }
        }
        this.tempCartStore.next(items);
    }

    getCartItems() {
        return this.cartItems;
    }

    getPurchaseCartItems() {
        return this.purchase_cartItems;
    }


    onResetCart() {
        this.cartItems = [];
        sessionStorage.removeItem('cartItems');
        this.cartStore.next();
    }

    onResetPurchaseCart() {
        this.purchase_cartItems = [];
        sessionStorage.removeItem('purchaseCartItems');
        this.purchase_cartStore.next();
    }


    updateCartItem(item) {
        this.cartItemEvent.next(item);
    }

    setCartItems(items) {
        this.cartStore.next(items);
    }


    removeItems() {
        this.cartStore.next();
    }

    setPurchaseCartItems(items) {
        this.purchase_cartStore.next(items);
    }

    removePurchaseItems() {
        this.purchase_cartStore.next();
    }

    refreshPurchaseItems() {
        let cartItems = sessionStorage.getItem('purchaseCartItems');
        if (cartItems) {
            this.purchase_cartItems = JSON.parse(cartItems);
            this.purchase_cartStore.next(this.purchase_cartItems);
        }
    }

    updatePurchaseCartItem(item) {
        this.purchase_cartItemEvent.next(item);
    }

    addPurchaseCartItems(items: any) {
        let me = this;

        if (me.purchase_cartItems == null || me.purchase_cartItems.length == 0) {
            if (items == null) {
                me.purchase_cartItems = [];
            } else {
                me.purchase_cartItems = items;
            }
        } else {
            for (let i = 0; i < items.length; i++) {
                for (let y = 0; y < me.purchase_cartItems.length; y++) {
                    if (me.purchase_cartItems[y] && items[i] && me.purchase_cartItems[y].id == items[i].id) {
                        me.purchase_cartItems[y] = items[i];
                    }
                }
            }
        }
        this.purchase_tempCartStore.next(items);
    }


    setCartCount(cartCount) {
        this.cartCountEvent.next(cartCount);
    }

    setPurchaseCartCount(cartCount) {
        this.purchase_cartCountEvent.next(cartCount);
    }

}
