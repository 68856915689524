<div class="explain-flows">
  <div fxLayout="row" fxLayoutGap="5px" class="purchase-guide-btn">
    <div fxFlex="50" fxLayout="row" fxLayoutGap="5px" class="purchase-guide-bulk" *ngIf="bulk_purchase_id">
      <div fxFlex="60" class="purchase-guide-bulk-flow">
        <a mat-flat-button href="/guide/bulk-purchase" color="accent" fxFill>
          <i class="icon-purchase-bulk"><img src="assets/images/purchase/purchase-icon-box.svg"></i>一括買取の流れ
        </a>

      </div>
      <div fxFlex="40" class="bulk-cart">
        <a mat-flat-button href="{{cart_url}}&additem={{bulk_purchase_id}}&num=1" color="accent" target="_blank" fxFill>
          <i class="icon-purchase-bulk"><img src="assets/images/purchase/purchase-icon-box.svg"></i>一括買取申込
        </a>
      </div>
    </div>
    <div fxFlex="50" fxLayout="row" fxLayoutGap="5px" class="purchase-guide-bulk" *ngIf="!bulk_purchase_id">
      <div fxFlex="100" class="purchase-guide-bulk-flow">
        <a mat-flat-button href="/guide/bulk-purchase" color="accent" fxFill>
          <i class="icon-purchase-bulk"><img src="assets/images/purchase/purchase-icon-box.svg"></i>一括買取の流れ
        </a>
      </div>
    </div>
    <div fxFlex="25" class="purchase-guide">
      <a mat-flat-button href="/guide/web-purchase" color="purchase" fxFill>
        <mat-icon>shopping_cart</mat-icon>WEB買取の流れ
      </a>
    </div>
    <div fxFlex="25" class="purchase-guide-shop">
      <a href="guide/shop-purchase">
        <img src="assets/images/purchase/purchase-shop-bunner.png">
      </a>
    </div>
  </div>
  <div>

    <div class="explain-flows-text">
      ※記載されている買取価格は当通販サイト「bigweb」での買取価格です。「BIG MAGIC」各店での買取価格と異なる場合がございます。
    </div>
    <div class="explain-flows-text">
      買取金額が¥50以下のカードは、状態がNMでない場合買取金額が0になる場合があります。
    </div>
  </div>
</div>
