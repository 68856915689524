import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-sameitem-list',
    templateUrl: './sameitem-list.component.html',
    styleUrls: ['./sameitem-list.component.scss']
})
export class SameitemListComponent implements OnInit {
    @Input() items: any;
    @Input() game: any;
    @Input() pagenate: any;
    @Input() current_item: any;
    @Input() is_purchase: boolean;
    @Output() onSelectCurrentItem: EventEmitter<any> = new EventEmitter();
    @Output() loadSameCardPage: EventEmitter<any> = new EventEmitter();

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
    }

    validItemCondition(condition){
        if(Array.isArray(condition) && 'web' in condition){
            return true;
        }
        return false;
    }

    selectCurrentItem(item) {
        let me = this;

        let regx = /(jp(e)?g|png|gif)$/i;
        item.trust_comment = me.sanitizer.bypassSecurityTrustHtml(item.comment);
        item.webp = item.image.replace(regx, 'webp');

        // シングルカードのみ対応
        item.is_single = false;
        if(!item.is_supply && !item.is_box) {
            // Lに差し替え (404は.htaccessで転送)
            item.imageL = item.image.replace(/(.+)(?!_L)\.(jpe?g|gif|png)/, '$1_L.$2');
            item.webpL = item.imageL.replace(regx, 'webp');
            item.is_single = true;
        }
        this.onSelectCurrentItem.emit(item);
    }

    loadPage(page) {
        this.loadSameCardPage.emit(page);
    }
}
