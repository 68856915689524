import {Component, OnInit, Inject} from '@angular/core';
import {StoresService} from '../../_services';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
    selector: 'app-favorites',
    templateUrl: './favorites.component.html',
    styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

    game: any;
    is_purchase: boolean;
    title: string = 'お気に入り'
    items: any;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: 'products'},
        {title: 'お気に入り', url: 'products/favorites/'}
    ];
    constructor(
        public storesService: StoresService, @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FavoritesComponent>,
    ) {

    }

    ngOnInit(): void {
        let me = this;
        me.items = me.storesService.getFavoritess();
    }

    runAddToCartItems(items: any) {
        //this.addToCartItems.emit(items);
        this.storesService.addCartItems(items);

        let CartItems = this.storesService.getCartItems();

    } closeDialog() {
        let me = this;
        me.dialogRef.close();
    }
}
