<form *ngIf="searchForm" class="search-form" [formGroup]="searchForm">
  <div class="container" fxLayout="column">
    <div fxLayout="row">
      <div fxFlex class="item-title">
        <mat-icon>filter_alt</mat-icon> <span i18n>条件で絞り込む</span>
      </div>
      <div class="close-btn-container" fxFlex="10">
        <button mat-mini-fab color="warn" class="modal-close-button" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <ng-container *ngFor="let config of filterConfigs">

      <ng-container *ngIf="config.search_filter_type.name=='text' else searchOthers">

        <mat-form-field appearance="outline">
          <mat-label>{{config.label}}</mat-label>
          <input matInput [placeholder]='config.placeholder' formControlName="{{config.name}}">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label
            *ngIf="game.id === TypeConstant.SUPPLY || game.id === TypeConstant.CHARACTER_SUPPLY">カテゴリ</mat-label>
          <mat-label
            *ngIf="game.id !== TypeConstant.SUPPLY && game.id !== TypeConstant.CHARACTER_SUPPLY">カードセット</mat-label>
          <mat-select [multiple]="isMultipleSelections?true:false" formControlName="cardsets">
            <!-- <mat-option>指定なし</mat-option> -->

            <ng-container *ngFor="let group of cardsetGroups">
              <mat-optgroup *ngIf="group.grouping else single" [label]="group.name">
                <mat-option *ngFor="let option of group.options" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-optgroup>
              <ng-template #single>
                <mat-option *ngFor="let option of group.options" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </ng-template>
            </ng-container>

          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-template #searchOthers>
        <mat-form-field appearance="outline">
          <mat-select *ngIf="config.search_filter_type.name=='select'" [placeholder]='config.placeholder'
            formControlName="{{config.name}}" multiple="true" (openedChange)="onChangeSelectFilter(config.name)" [disableOptionCentering]="true">
            <mat-option *ngFor="let option of config.search_filter_options" [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
          <button matSuffix mat-icon-button (click)="clearFilter(config.name)" *ngIf="criteria[config.name]">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-form-field>
      </ng-template>

    </ng-container>

    <div fxFlex fxLayout="row" class="modal-footer" fxFill>
      <div class="modal-footer search-form-bottom" fxLayout="row" fxFill fxFlex fxLayoutGap="10px">
        <button mat-stroked-button class="reset-filter-button" (click)="resetFilter()" fxFlex="35">
          <i class="material-icons">delete_outline</i> <span i18n>リセット</span>
        </button>
        <button mat-flat-button class="search-button" (click)="submitFilter()" color="primary" fxFlex="65">
          <i class="material-icons">search</i><span i18n>検索</span>
        </button>
      </div>
    </div>
  </div>
</form>
