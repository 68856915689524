import {Component, OnInit} from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterService, SettingsService} from '../_services';
import {Router} from '@angular/router';
import {environment} from "../../environments/environment";
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    error_msg: string = "";
    token: string;

    urlPath: any;
    localeId: string = 'ja';
    ja_host: string = "";
    en_host: string = "";

    constructor(
        private formBuilder: FormBuilder,
        public registerService: RegisterService,
        public settingsService: SettingsService,
        private router: Router
    ) {
        this.localeId = this.settingsService.getLocale();
        this.ja_host = environment.ja_host;
        this.en_host = environment.en_host;

        this.registerForm = this.formBuilder.group({
            userId: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
            passwordsConfirm: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            emailsConfirm: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            firstName: new FormControl('', [Validators.required]),
            lastNameKana: new FormControl('', [Validators.required]),
            firstNameKana: new FormControl('', [Validators.required]),
            postalCode: new FormControl('', [Validators.required]),
            prefecture: new FormControl('', [Validators.required]),
            municipality: new FormControl('', [Validators.required]),
            address: new FormControl('', [Validators.required]),
            phoneNumber: new FormControl('', [Validators.required]),
        });
    }

    ngOnInit(): void {
        let me = this;
        me.urlPath = me.router.url;
    }

    getUserIdsError() {
        if (this.registerForm.value.userId.hasError('required')) {
            return 'ユーザーIDを入力してください';
        }
    }

    getPasswordsError() {
        if (this.registerForm.value.password.hasError('required')) {
            return 'パスワードを入力してください';
        }
    }

    getPasswordsConfirmError() {
        if (this.registerForm.value.passwordsConfirm.hasError('required')) {
            return 'パスワード(確認)を入力してください';
        }
    }

    getEmailsError() {
        if (this.registerForm.value.email.hasError('required')) {
            return 'メールアドレスを入力してください';
        }

        return this.registerForm.value.email.hasError('email') ? 'Not a valid email' : '';
    }

    getEmailsConfirmError() {
        if (this.registerForm.value.emailsConfirm.hasError('required')) {
            return 'メールアドレス(確認)を入力してください';
        }
    }

    getLastNamesError() {
        if (this.registerForm.value.lastName.hasError('required')) {
            return 'ユーザー名(姓)を入力してください';
        }
    }

    getFirstNamesError() {
        if (this.registerForm.value.firstName.hasError('required')) {
            return 'ユーザー名(名)を入力してください';
        }
    }

    getLastNameKanasError() {
        if (this.registerForm.value.lastNameKana.hasError('required')) {
            return 'フリガナ(姓)を入力してください';
        }
    }

    getFirstNameKanasError() {
        if (this.registerForm.value.firstNameKana.hasError('required')) {
            return 'フリガナ(名)を入力してください';
        }
    }

    getPostalCodesError() {
        if (this.registerForm.value.postalCode.hasError('required')) {
            return '郵便番号を入力してください';
        }
    }

    getPrefecturesError() {
        if (this.registerForm.value.prefecture.hasError('required')) {
            return '都道府県を入力してください';
        }
    }

    getMunicipalitiesError() {
        if (this.registerForm.value.municipality.hasError('required')) {
            return '市区町村を入力してください';
        }
    }

    getAddressesError() {
        if (this.registerForm.value.address.hasError('required')) {
            return '番地・建物名を入力してください';
        }
    }

    getPhoneNumbersError() {
        if (this.registerForm.value.phoneNumber.hasError('required')) {
            return '電話番号を入力してください';
        }
    }

    onRegister() {
        let me = this;
        // this.registerService.register(me.registerForm.value).subscribe((result: any) => {
        //     if (result) {
        //         // me.setLoginUser(result.access_token);

        //     } else {
        //         me.error_msg = '※ 認証に失敗しました';

        //     }
        // },
        //     (err: any) => {
        //         me.error_msg = '※ 認証に失敗しました。';

        //     });

        this.registerService.duplicateCheck(me.registerForm.value).subscribe((result: any) => {
            if (result) {
                // me.setLoginUser(result.access_token);
                me.error_msg = '※ 該当あり';

            } else {
                me.error_msg = '※ 該当なし';

            }
        },
            (err: any) => {
                me.error_msg = '※ 認証に失敗しました。';

            });
    }

}
