import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    @Input() title;
    @Input() breadcrumbs: any[] = [];
    @Input() is_purchase: boolean;

    constructor() {

    }

    ngOnInit(): void {

    }

}
