import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-purchase',
  templateUrl: './shop-purchase.component.html',
  styleUrls: ['./shop-purchase.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class ShopPurchaseComponent implements OnInit {

  title: string = '店頭買取について';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: '店頭買取について', url: '/guide/shop-purchase'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}