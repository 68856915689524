<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>
<div class="container content_wrap p_under contact">
    <h1 class="headingUnder01"><span i18n>お問い合わせ</span></h1>
    <h2 class="headingUnder02"><span i18n>買い取りに関するお問い合わせ</span></h2>
    <section class="content">
        <p><span i18n>e-mail（買取）: </span><a href="mailto:kaitori@big-web.tv">kaitori@big-web.tv</a></p>
    </section>
    <hr>
    <h2 class="headingUnder02" i18n>商品発送状況のお問い合わせ</h2>
    <section class="content">
        <p i18n>
            メール便や宅配便などの商品発送状況のお問い合わせは各宅配業者様のWebサイトでご確認ください。</p>
        <div class="borderBox delivery">
            <ul>
                <li><h3 class="headingUnder04" i18n>佐川急便（宅配便）</h3>
                    <a href="http://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp" target="_blank">http://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp</a></li>
                <li><h3 class="headingUnder04" i18n>ヤマト運輸（ネコポス・宅急便）</h3>
                    <a href="http://toi.kuronekoyamato.co.jp/cgi-bin/tneko" target="_blank">http://toi.kuronekoyamato.co.jp/cgi-bin/tneko</a></li>
                <li><h3 class="headingUnder04" i18n>郵便局（ゆうパケット）</h3>
                    <a href="https://trackings.post.japanpost.jp/services/srv/search/input" target="_blank">https://trackings.post.japanpost.jp/services</a></li>
            </ul>
        </div>
    </section>
</div>