import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemViewComponent } from '../commons/item-view/item-view.component';
import { ImageViewComponent } from '../commons/image-view/image-view.component';
import { MatDialog } from '@angular/material/dialog';
import { BrowserStandardEncoder } from '../utils/browser-standard-encoder';
import { SettingsService } from './settings.service';


@Injectable({
    providedIn: 'root'
})

export class ItemsService {

    token: string;
    body: any = new HttpParams();
    options: any;
    searchCriteria: any;
    localeId :string;

    constructor(
        public http: HttpClient,
        public router: Router,
        public dialog: MatDialog,
        public settingsService: SettingsService

    ) {
        let me = this;
        me.options = {
            headers: new HttpHeaders({
                //'Authorization': 'Bearer ' + this.token
                'Content-Type': 'application/json'
            })
        };

        this.localeId = this.settingsService.getLocale();
    }

    load(game_id, searchCriteria: any = {}) {
        let me = this;
        let http_params: any = {};
        let url
        url = me.getApiUrl(environment.server + 'products', game_id, searchCriteria);
        let result: any = me.http.get(url, http_params);
        return result;
    }

    loadSupply(game_id, searchCriteria: any = {}) {
        let me = this;
        let http_params: any = {};
        let url = '';
        url = me.getApiUrl(environment.server + 'products/loadSupply', game_id, searchCriteria);
        let result: any = me.http.get(url, http_params);
        return result;
    }


    getById(id, is_purchase = false) {
        let me = this;
        let http_params: any = {};
        let url


        url = environment.server + 'products/getById/' + id + '?is_purchase=' + (is_purchase ? 1 : 0);
        let result: any = me.http.get(url, http_params);
        return result;
    }

    loadSameCards(name, is_purchase = false, page, game_id) {
        let me = this;
        let http_params: any = {};
        let url

        url = environment.server + 'products/getSameNameCards/' + name + '?is_purchase=' + (is_purchase ? 1 : 0) + '&page=' + page + '&game_id=' + game_id;
        let result: any = me.http.get(url, http_params);
        return result;
    }

    loadPickups(game_id) {
        let me = this;
        let http_params: any = {};
        let url

        url = environment.server + 'Features/?game_id=' + game_id;
        let result: any = me.http.get(url, http_params);
        return result;
    }

    loadFeatures(game_id) {
        let me = this;
        let http_params: any = {};
        let url

        url = environment.server + 'Features/?game_id=' + game_id;
        let result: any = me.http.get(url, http_params);
        return result;
    }

    loadBuyingFutures(game_id) {
        let me = this;
        let http_params: any = {};
        let url

        url = environment.server + 'Features/buying/' + game_id;
        //url = environment.server + 'Features/?game_id=' + game_id;
        let result: any = me.http.get(url, http_params);
        return result;
    }

    loadBoxesFeatures(game_id) {
        let me = this;
        let http_params: any = {};
        let url;
        url = environment.server + 'Features/?game_id=' + game_id + '&is_box=1';

        let result: any = me.http.get(url, http_params);
        return result;
    }

    getSearchParams(searchCriteria) {
        let values = {};
        for (const key of Object.keys(searchCriteria)) {
            if(key === 'cardsets'){
                values[key] = JSON.stringify(searchCriteria[key]);
            } else
            if (key != 'searchword' && key != 'game_id') {
                // q と2重になってしまう為、除外
                values[key] = searchCriteria[key];
            }
        }
        let params = new HttpParams({encoder: new BrowserStandardEncoder()}).appendAll(values);
        return params.toString();
    }

    getApiUrl(apiUrl, game_id, searchCriteria) {
        if (!game_id) {
            return apiUrl;
        }
        if (searchCriteria) {
            return apiUrl + '?game_id=' + game_id + '&' + this.getSearchParams(searchCriteria);
        } else {
            return apiUrl + '?game_id=' + game_id;
        }
    }

    getProductNames(val, gameId: number = 0) {
        let me = this;
        let http_params: any = { keyword: val };
        let url;
        //let productNameUrl = 'products/getNames';
        let productNameUrl = 'productsEs';

        if (gameId == 0) {
            url = environment.server + productNameUrl + '?keyword=' + val;
        } else {
            url = environment.server + productNameUrl + '?keyword=' + val + '&game_id=' + gameId;
        }
        let result: any = me.http.get(url, http_params);
        return result;
    }

    loadBuying() {
        let items = []

        return items;
    }

    loadFavorites() {
        let items = []
        return items;
    }

    loadReserved(game_id) {
        let me = this;
        let http_params: any = {};
        let url

        url = environment.server + 'Products/getReserved/?game_id=' + game_id;

        let result: any = me.http.get(url, http_params);
        return result;
    }

    openItemView(item, is_purchase = false) {
        const dialogRef = this.dialog.open(ItemViewComponent, {
            panelClass: 'p-modal-confirm',
            id: 'modal-item-view',
            disableClose: false,
            autoFocus: true,
            data: { 'item': item, 'is_purchase': is_purchase }
        });
    }

    openImageView(item, is_purchase = false) {
        const dialogRef = this.dialog.open(ImageViewComponent, {
            panelClass: 'p-modal-confirm',
            id: 'modal-image-view',
            disableClose: false,
            autoFocus: true,
            width: '400px',
            data: { 'item': item, 'is_purchase': is_purchase }
        });
    }

    searchSameProducts(item, is_purchase: boolean = false) {
        let me = this;
        setTimeout(function () {
            me.router.navigate(['/'
                + (is_purchase ? 'purchase' : 'products')
                + '/' + item.game.code
                + '/cardViewer/'
                + item.id
            ]);
        }, 10);
    }
}
