<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="container content_wrap p_under contact">
    <h1 class="headingUnder01" i18n>ご利用案内</h1>
    <h2 class="headingUnder02" i18n>ご利用について</h2>
    <section class="content">
        <ul>
            <li><a href="/guide/orderguide"><span i18n>ご注文手順</span></a>
            </li>
            <li><a href="/informations/shipping"><span i18n>送料・手数料</span></a>
            </li>
            <li><a href="/guide/orderconfirmation"><span i18n>ご注文・配送状況の確認</span></a>
            </li>
            <li><a href="/guide/reservations-info"><span i18n>予約商品の購入</span></a>
            </li>
            <li><a href="/guide/cancel"><span i18n>キャンセルと返品・交換</span></a>
            </li>
            <li><a href="/guide/pointback"><span i18n>ポイントバックについて</span></a>
            </li>
            <li><a href="/guide/payment"><span i18n>お支払方法</span></a>
            </li>
            <li><a href="/guide/cardconditions"><span i18n>国産カードの状態について</span></a>
            </li>
            <li><a href="/guide/cardconditions-mtg"><span i18n>MTGカードの状態について</span></a>
            </li>
            <li><a href="/guide/notice-sharedcards"><span i18n>BIGMAGIC店舗との共有商品について</span></a>
            </li>
            <li><a href="/guide/stock-info"><span i18n>在庫について</span></a>
            </li>
        </ul>
    </section>
    <hr>
    <h2 class="headingUnder02" i18n>その他</h2>
    <section class="content">
        <ul>
            <li><a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp"><span i18n>お客様情報の登録と変更</span></a>
            </li>
            <li><a href="/guide/terms"><span i18n>ご利用規約</span></a>
            </li>
            <li><a href="/guide/privacy"><span i18n>個人情報の取り扱い</span></a>
            </li>
            <li><a href="/guide/company"><span i18n>特定商取引法に基づく表示、会社概要</span></a>
            </li>
        </ul>
    </section>
    <hr>
    <h2 class="headingUnder02" i18n>ご意見・ご感想</h2>
    <section class="content">
        <ul>
            <li><a href="/contact"><span i18n>専用フォーム</span></a>
            </li>
        </ul>
    </section>
</div>