<ng-container *ngIf="is_purchase">
    <div [@showCart]="!isShowCart" class="fab-button-purchase-bottom-right" >
        <div >
            <button  mat-icon-button color="Warn" (click)="scrollTop()" [@showScrollTopButton]="isShowScrollButton" *ngIf="isShowScrollButton" class="scroll-up-button-purchase">
                <mat-icon>publish</mat-icon>
            </button>
        </div>
        <div class="cart-float-button">
            <button *ngIf="tempCartCount>0"  mat-mini-fab color="warn" (click)="openCart()" [ngClass]="{'isScrolling':isScrolling}">
                <mat-icon >add_shopping_cart</mat-icon>
            </button>
            <button mat-fab color="primary" (click)="openCart()" [ngClass]="{'isScrolling':isScrolling}">
                <mat-icon *ngIf="cartCount==0">shopping_cart</mat-icon>
                <mat-icon *ngIf="cartCount>0" matBadge="{{cartCount}}" matBadgeColor="primary">shopping_cart</mat-icon>
                <span class="purchase-cart-button-txt">買取</span>
            </button>

        </div>
    </div>
</ng-container>