<div class="cart-view">
  <div class="cart-body">
    <div fxLayout="row" class="cart-view-title">
      <span fxFlex fxAlign="start" class="item-title">
        <mat-icon>shopping_cart</mat-icon> カート
      </span>
      <div fxFlex="40px" fxLayoutAlign="end">
        <button mat-mini-fab color="warn" class="modal-close-button" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <ng-container *ngFor="let item of cartItems">
      <div class="game-name-header" *ngIf="headerArrayList.indexOf(item.id) > -1">
        {{item.game_name}}
      </div>
      <div>
        <app-row-cart-item [item]="item" (addToCartItems)="runAddToCartItems(item)" (removeItem)="onRemoveItem(item)"
          (addItem)="onAddItem(item)" (closeDialog)="closeDialog()"></app-row-cart-item>
      </div>
    </ng-container>

    <div class="cart-reset">
      <button mat-button class="go-register-button mat-stroked-button mat-primary" (click)="resetCart()">
        <mat-icon color="primary" matBadgeColor="accent">remove_shopping_cart</mat-icon><span i18n>カートの中身をリセットする</span>
      </button>
    </div>

    <div class="cart-bottom-link" fxLayoutAlign="space-around">
      <div fxFlex="50">
        <a mat-button class="go-register-button" (click)="goRegister()">
          <mat-icon class="material color_white" matBadgeColor="accent">point_of_sale</mat-icon>
          <span i18n>レジに進む</span>
        </a>
      </div>
    </div>

    <div style="display:flex; justify-content: center; color: red; padding: 2rem;">
      <p>『MTGカード』と同時購入の際は、こちらのカートをレジに移動させてから『MTGカード』の購入をお願いします</p>
    </div>
  </div>

</div>

<div class="cart-footer" fxLayout="column">

  <div fxLayout="row" fxLayoutAlign="end" class="cart-to-register">
    <div class="cart-total-amount cart-emphasize">
      合計：<span>{{getTotalAmount()|number}}</span>円
    </div>
  </div>

  <div class="cart-link-container">
    <ul fxLayout="row">
      <li><a href="/guide/cardconditions"><span i18n>国産カードの状態について</span></a>|</li>
      <li><a href="/guide/cardconditions-mtg"><span i18n>MTGカードの状態について</span></a>|</li>
      <li><a href="/guide/orderguide"><span i18n>ご注文手順</span></a>|</li>
      <li><a href="/informations/shipping"><span i18n>送料・手数料</span></a>|</li>
      <li><a href="/guide/pointback"><span i18n>ポイントバックについて</span></a>|
      <li>
      <li><a href="/guide/payment"><span i18n>お支払方法</span></a>|</li>
    </ul>
  </div>
</div>
