import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class CompanyComponent implements OnInit {

  title: string = '特定商取法に基づく表示、会社概要';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: '特定商取法に基づく表示、会社概要', url: '/guide/company'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
