<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under orderconfirmation">
    <h1 class="headingUnder01">メールについてのご注意</h1>

    <section class="content">
        <h2 class="headingUnder02">softbank.jpをご利用の場合</h2>
        <p>
			iPhoneのメッセージアプリで、softbank.jpのメールアドレスをご利用の場合、<br>新規登録にあたっては、i.softbank.jpなどその他のメールアドレスの登録をお願いします。<br />
            <br>
            また、既に登録済みの方は<a href="<?php echo $this->Url->build(['prefix' => 'mypage', 'controller' => 'users', 'action' => 'edit-profile', 'from_bigwebcojp']); ?>">マイページ【会員情報の編集】</a>から変更いただくか、メールもしくはお電話でお問い合わせください。<br />
        </p>

        <div class="borderBox">
            <h3 class="headingUnder03">お問い合わせ先</h3>
            <ul class="textList">
                <li><a href="/contact">お問い合わせフォーム</a></li>
                <li>e-mail: <a href="mailto:support@big-web.tv">support@big-web.tv</a></li>
                <li>BIGWEB通販部 06-6245-7767（10:00～17:00）</li>
            </ul>
        </div>
    </section>

    <section class="content qanda">
        <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
        <ul class="qaList">
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">注文したのに【ご注文確認】【発送完了】メールが届かない</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            Eメールが届かない場合は、ご注文を承っていないかEメールアドレスに誤りがある可能性があります。ご登録いただいたEメールアドレスに間違いがありますと大切なご案内がお届けできません。<br />
                            <br />
                            <a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【会員情報の確認】</a>で登録メールアドレスをご確認ください。誤りがございましたら、再度正しいメールアドレスを登録してください。<br />
                            アドレスに誤りがない場合や【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
                            <br />
                            ご注文の確認は<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【注文履歴】</a>からでもご確認いただけます。<br />
                            <br />
                            注文完了等のメールが届かない場合、迷惑メールボックスに送られている場合がございます。 <br />
                            迷惑メールボックスをご確認頂いてもメールが届いていない場合、お手数ですが、登録を別のアドレスにご変更ください。<br />
                            メールアドレス変更後【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
                            <br />
                            ※現在、発生しているメールの不具合に関する情報は<a href="/informations/trouble-mail">こちら</a>です。<br />
                            <br />
                            大変ご迷惑をおかけしますが、宜しくお願いいたします。<br />
                        </div>
                    </dd>
                </dl>
            </li>
        </ul>
    </section>

</section><!--  /.contentBox-->
