import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservations-info',
  templateUrl: './reservations-info.component.html',
  styleUrls: ['./reservations-info.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class ReservationsInfoComponent implements OnInit {

  title: string = '予約商品の購入';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: '予約商品の購入', url: '/guide/reservations-info'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
