import {Injectable, LOCALE_ID, Injector, } from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    breadcrumbs: any[] = [];
    localeId = "";
    is_refresh: boolean = false;// ローカルストレージを消した時 true;

    limitHours = 1;//1時間経過していた場合、localstorageを削除
    limitTargets = ['Games', 'gameConfig'];
    limitGameTargets = [
        //'filterConfigs_',
        'categories_'
    ];
    gameIds = [];

    constructor(
        private injector: Injector,
        private titleService: Title,
        private meta: Meta
    ) {
        this.localeId = this.injector.get(LOCALE_ID);
    }

    clearStorage() {
        let me = this;
        let temp_timestamp = sessionStorage.getItem('timestamp');

        let last_timestamp = new Date(
            temp_timestamp ? JSON.parse(temp_timestamp) : ''
        );
        let timestamp = new Date();

        if (me.is_refresh || timestamp.getTime() > last_timestamp.getTime()) {
            // 設定は一定時間保持
            for (let i = 0; i < me.limitTargets.length; i++) {
                localStorage.removeItem(me.limitTargets[i]);
            }
        }
    }


    setTitle(title: string, description: string = null) {
        this.titleService.setTitle(title);
        this.setDescription(description ? description : title);
    }

    setDescription(description: string) {
        let tags = {name: 'description', content: description};
        this.meta.addTag(tags);
    }

    setMetataags(tags: any) {

        this.meta.addTags(tags);
    }

    getBreadcrumbsByGame(game: any, is_purhase: boolean = false) {
        let me = this;
        me.breadcrumbs = [];
        let temp = '専門店';
        if (game) {
            if (is_purhase) {
                me.breadcrumbs.push({title: '買取トップ', url: 'purchase'});
                temp = '買取' + temp;
                me.breadcrumbs.push({
                    title: game.title + temp,
                    url: "/" +　me.localeId + '/purchase/' + game.code
                });
            } else {
                me.breadcrumbs.push({
                    title: 'Bigweb',
                    url: "/" +　me.localeId + '/'
                });
                temp = '通販' + temp;
                me.breadcrumbs.push({
                    title: game.title + temp,
                    url: "/" +　me.localeId + '/products/' + game.code
            });
            }
        }
        return me.breadcrumbs;
    }

    getLocale() {
        return this.localeId;
    }

    addGameIdLog(game_id) {

    }

    setTimestamp() {
        let timestamp = new Date();

        this.clearStorage();
        timestamp.setHours(timestamp.getHours() + this.limitHours);
        sessionStorage.setItem('timestamp', JSON.stringify(timestamp));

    }

}
