export class TypeConstant {
    public static readonly TCGJP: number = 7;
    public static readonly SUPPLY: number = 63;
    public static readonly CHARACTER_SUPPLY: number = 173;

    public static readonly STR_BUYING: string = 'buying';

    public static readonly STR_SUPPLY: string = 'supply';
    public static readonly STR_SUPPLIES: string = 'supplies';
    public static readonly STR_CHARACTER_SUPPLY: string = 'character_supply';

    public static readonly STR_CATEGORY: string = 'category';
}

export class TypeGenreConstant {
    public static readonly TCG: number = 1;
    public static readonly SUPPLY: number = 2;
    public static readonly CHARACTER_SUPPLY: number = 3;
}
