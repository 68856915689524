<app-toolbar [title]="title" [is_purchase]="is_purchase" [games]="games">
</app-toolbar>
<app-menubar></app-menubar>

<div class="row user-main-notice-row" style="background-color: antiquewhite; display: flex; justify-content: center; border-bottom: 2px solid #1823b1;">
  <div class="col-xs-12">

    <div class="user-main-notice" style="padding: 5px 20px">
      <span class="notice-message" style="display: block; line-height: 2rem; text-align: center;">
        <a href="https://mtg.bigweb.co.jp/informations/orderconfirmation" target="mtg" style="color: orangered;">
          【 能登半島地震の影響による配送遅延について 】
        </a><br>
      </span>
    </div>

  </div>
</div>

<div class="contents">
  <app-top-slider [slides]="slides">
  </app-top-slider>
  <mat-sidenav-container class="container">
    <mat-sidenav mode="side" class="side-menu" opened>
      <div class="side">
        <!--<app-notice [title]="title" [is_purchase]="is_purchase" class="notice-wrap" *ngIf="has_notice">
        </app-notice>-->
        <div class="banner-recruit">
          <a href="https://mtg.bigweb.co.jp/shop/recruit"><img src="/{{localeId}}/assets/images/bm-stuff420.png"
              alt="スタッフ募集"></a>
        </div>
        <div class="side-twitter">

          <div fxLayout="row wrap" class="menu-link">
            <div class="link-item">
              <a mat-button color="primary" href="https://twitter.com/big_web_all" target="_blank">
                <span i18n><i class="link-item-icon twitter"><img
                      src="assets/icons/icon-twitter.png"></i>Bigweb総合</span>
              </a>
            </div>
            <div class="link-item">
              <a mat-button color="primary" href="https://twitter.com/MtgBigmagic" target="_blank">
                <span i18n><i class="link-item-icon twitter"><img
                      src="assets/icons/icon-twitter.png"></i>BIGMAGIC</span>
              </a>
            </div>
            <div class="link-item">
              <a mat-button color="primary" href="https://www.facebook.com/bigwebmtg" target="_blank">
                <span i18n><i class="link-item-icon facebook"><img
                      src="assets/icons/icon-facebook.png"></i>facebook</span>
              </a>
            </div>
            <div class="link-item">
              <a mat-button color="primary" href="https://www.youtube.com/user/BIGWEBMTG" target="_blank">
                <span i18n><i class="link-item-icon youtube"><img src="assets/icons/icon-youtube.png"></i>Youtube</span>
              </a>
            </div>
            <div class="link-item">
              <a mat-button color="primary" href="https://www.twitch.tv/bigmagiclive" target="_blank">
                <span i18n><i class="link-item-icon twitch"><img src="assets/icons/icon-twitch.svg"></i>Twitch</span>
              </a>
            </div>
          </div>
        </div>
        <div class="banner-bigwebman">
          <a href="https://mtg.bigweb.co.jp/bigwebman" target="mtg"><img src="/{{localeId}}/assets/images/bwman480.png"
              alt="Bigwebマンって何者？"></a>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="wrap">
        <div>
          <mat-grid-list cols="5" rowHeight="2.2:1" *ngIf="games">
            <mat-grid-tile col="1" *ngFor="let game of games">
              <ng-container [ngSwitch]="game.type_genre_id">
                <div *ngSwitchCase="2">
                  <a href="products/{{game.code}}/">
                    <mat-card-content class="game-title-card">
                      <img mat-card-image src="{{image_url}}/top_{{game.id}}.png" alt="{{game.id}}：{{game.title}}">
                    </mat-card-content>
                  </a>
                </div>
                <div *ngSwitchCase="3">
                  <a href="products/{{game.code}}/">
                    <mat-card-content class="game-title-card">
                      <img mat-card-image src="{{image_url}}/top_{{game.id}}.png" alt="{{game.id}}：{{game.title}}">
                    </mat-card-content>
                  </a>
                </div>
                <div *ngSwitchDefault>
                  <a *ngIf="game.id !== 1 else mtgEC" href="products/{{game.code}}">
                    <mat-card-content class="game-title-card">
                      <img mat-card-image src="{{image_url}}/top_{{game.id}}.png" alt="{{game.id}}：{{game.title}}">
                    </mat-card-content>
                  </a>
                  <ng-template #mtgEC>
                    <a href="{{escape_url}}" target="bigweb">
                      <mat-card-content class="game-title-card">
                        <img mat-card-image src="{{image_url}}/top_{{game.id}}.png" alt="{{game.id}}：{{game.title}}">
                      </mat-card-content>
                    </a>
                  </ng-template>
                </div>
              </ng-container>
            </mat-grid-tile>
          </mat-grid-list>
        </div>

        <div>
          <ul class="sougou-btn-link">
            <li>
              <a href="https://mtg.bigweb.co.jp/shop" target="mtg">
                <div class="sougou-btn-inner">
                  <i class="fas fa-store"></i>
                  <span class="sougou-btn-title">店舗情報</span>
                  <span class="sougou-btn-description">東京秋葉原/東京池袋/<br>名古屋/大阪なんば</span>
                </div>
              </a>
            </li>
            <li>
              <a href="purchase">
                <div class="sougou-btn-inner">
                  <i class="delivery"></i>
                  <span class="sougou-btn-title">カード買取</span>
                  <span class="sougou-btn-description">一括買取 / WEB買取 / 店舗買取</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="three-point-large">
          <img src="/{{localeId}}/assets/images/Bigweb3par600.jpg" alt="ポイント3%" max-width="600px">
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
