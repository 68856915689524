<div class="item-box cart-item-box" fxLayout="row wrap">
  <div fxFlex="30">
    <div class="item-image-box cart-image-box" *ngIf="item.game">
      <div class="item-image cart-item-image">
        <img src="{{item.image}}">
      </div>
      <div *ngIf="item.card_condition && item.card_condition.is_foiled"
        class="product-tag cart-product-tag is_foiled cart-is_foiled">
        FOIL
      </div>
      <div *ngIf="item.card_condition && item.card_condition.is_scratched"
        class="product-tag cart-product-tag is_scratched">
        キズ有り
      </div>
      <div *ngIf="is_purchase" class="product-tag purchase-tag cart-purchase-tag">
        買取
      </div>
    </div>
  </div>
  <div fxFlex="70" class="cart-item-container">
    <div fxLayout="column" class="item-title-box">
      <div class="images-item-title cart-images-item-title">
        <a href="/{{localeId}}/{{is_purchase?'purchase':'products'}}/{{item.game.code}}/cardViewer/{{item.id}}">{{item.name}}</a>
      </div>
      <div class="images-item-title cart-images-item-title" *ngIf="item.fname">
        {{item.fname}}
      </div>
      <div class="images-item-title cart-images-item-title" *ngIf="item.cardset">
        {{item.cardset.web}}
      </div>
    </div>

    <div fxLayout="column">
      <div>
        <div class="cart-item-condition" fxFlex="50" fxLayoutAlign="center center">
          <ng-container *ngIf="item.language && item.language.web && item.language.web != '指定なし'">
            [{{item.language.web}}]
          </ng-container>
          <ng-container *ngIf="item.rarity" class="item-rarity-box">
            <span *ngIf="item.rarity.slip && item.rarity.slip != ''; else rarityName">[{{item.rarity.slip}}]</span>
            <ng-template #rarityName><span *ngIf="item.rarity.web && item.rarity.web != ''">[{{item.rarity.web}}]</span>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="item.condition" class="item-condition-box">
            <span *ngIf="item.condition.slip && item.condition.slip!=''">[{{item.condition.slip}}]</span>
            <span *ngIf="item.condition.web && item.condition.web!=''">{{item.condition.web}}</span>
          </ng-container>
        </div>
        <div>
          <span class="row-item-comment" [innerHTML]="item.trust_comment"></span>
        </div>

        <div fxFlex="50" class="item-price" *ngIf="item.quantity>1||item.quantity==0"
          [ngClass]="{'item-product-price':!is_purchase,'item-purchase-price':is_purchase}">
          <span *ngIf="item.is_bargain" class="base-price cart-base-price">
            {{item.sale_prices|number}}円
          </span>
          単価 {{0+item.price|number}}円
        </div>
      </div>
      <div class="item-buttons cart-item-buttons">
        <button fxFlex [attr.aria-label]="'delete'" fxFlex="50" class="cart-button" (click)="onRemoveItem(item)"
          *ngIf="item.quantity==0">
          <i class="material-icons">delete</i>カートから削除
        </button>
        <button fxFlex [attr.aria-label]="'remove'" fxFlex="0 0 40px" class="cart-button" (click)="addCount(item,-1)"
          *ngIf="item.quantity>0">
          <i class="material-icons">remove</i>
        </button>
        <div fxFlex fxLayout="row" class="row-item-quantitiy cart-item-quantitiy"
          [ngClass]="{'largeQuantity' : item.stock_count > 99}">
          数量：

          <mat-select class="cart-input" [value]="item.quantity" (selectionChange)="selectionChange($event,item)" fxFlex
            *ngIf="item.quantity<11 && item.quantity>0">
            <mat-option [value]="-99">
              -- この商品を削除する --
            </mat-option>
            <mat-option *ngFor="let num of getStockOptions(item.stock_count)" [value]="num">
              {{num}}
            </mat-option>
            <mat-option [value]="11" *ngIf="item.stock_count >10">
              -- 直接入力 --
            </mat-option>

          </mat-select>
          <mat-label>
            <input matInput type="number" class="cart-input" [value]="item.quantity"
              [ngClass]="{'hasTempCart' : item.quantity==0}" (change)="checkStock($event,item)" fxFlex
              autocomplete="off" *ngIf="item.quantity>10||item.quantity==0">
            <span matSuffix fxFlex="24px" class="cart-input-suffix">/{{item.stock_count}}</span> 点
          </mat-label>
        </div>
        <button fxFlex [attr.aria-label]="'add'" fxFlex="0 0 40px" class="cart-button" (click)="addCount(item,1)">
          <i class="material-icons">add</i>
        </button>
      </div>
      <div fxLayoutAlign="end center" class="item-price cart-item-price" [class.cart-item-purchase]="is_purchase">
        {{item.quantity*(0+item.price)|number}}円
      </div>
    </div>

  </div>
</div>