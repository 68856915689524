<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="p_under privacy">
    <section class="contentBox">
      <h1 class="headingUnder01">個人情報保護方針</h1>
      <p>
          ビックウェブ（以下｢当社｣といいます）が運営するBIG-WEB.tv（以下｢本サービス｣といいます）をご利用いただくにあたって、お客様のお名前・ご住所・Eメールアドレス・電話番号・クレジットカード番号等、必要な情報を入力していただく場合がございます。お客様の個人情報の取扱いにつきましては、下記の規定に基づいてお客様の情報を保護しています。安心してご利用ください。
      </p>
      <section class="guide-content">
        <h2 class="headingUnder02">個人情報保護方針（プライバシーポリシー）について</h2>
        <p>当社は、その運営する本サービスを通じてお客様から収集した、個人を識別または特定できる情報（以下「個人情報」といいます）、ご注文情報（お客様の注文履歴に関する情報を含む）の保護に最大限の注意を払っています。当社は責任をもってお客様の個人情報を保護するため、個人情報の保護に関する方針を以下の通り定め、社内体制の構築、施策の実施・維持、それらの継続的な改善を行っています。</p>
        <ul class="textList noListstyle">
            <li>1. 当社は、お客様から収集させていただいた個人情報、ご注文情報（お客様の注文履歴に関する情報を含む）を、本サービスを提供する目的の他に、以下の各号に定める目的のために利用することがあります。本サービスの提供または以下に定める目的以外に、当社はお客様の個人情報を利用することはありません。
              <ul>
                  <li>（1）お客様に対して、当社の商品やサービスをご紹介する場合</li>
                  <li>（2）当社サービスセンターにおいて、お客様に代行してご注文手続き、ご注文内容の確認、変更手続きを行なう場合</li>
                  <li>（3）お客様に対して、当社のサービスに対するご意見やご感想のご提供をお願いするため</li>
                  <li>（4）当社がお客様に別途連絡の上、個別にご了解をいただいた目的に利用するため</li>
                  <li>（5）お客様の属性（年齢、住所など）ごとに分類された統計的資料を作成するため</li>
              </ul>
            </li>
  
            <li>2. 当社がお客様から収集させていただいた個人情報は、以下の何れかに該当する場合を除き、第三者に提供・開示等をすることはありません。
              <ul>
                  <li>（1）お客様の事前の同意・承諾を得た場合</li>
                  <li>（2）あらかじめ当社との間で機密保持に関する契約を締結している会社等に本サービスに関連して必要な限度内で開示・提供する場合</li>
                  <li>（3）本サービスのご利用や商品の注文等で決済が必要な場合に、金融機関等との間で個人情報の確認交換をする場合</li>
                  <li>（4）お客様からのお問い合せや、お買い上げ商品に関するアフターサービス等で、機密保持に関する契約を締結している会社等から直接ご連絡させていただくことが適切と判断される場合</li>
                  <li>（5）その他法令等により開示・提供が必要な場合</li>
              </ul>
            </li>
  
            <li>3. 当社が、上記2．において個人情報を開示・提供等を行う会社等には、お客様の個人情報に関して当社と同様適切な管理を行うよう、契約により義務付けをいたします。</li>
  
            <li>4. お客様から収集させていただいた個人情報は、当社によって適切、厳重に管理し、お客様の個人情報への不正なアクセスや情報の紛失、破壊、改竄及び漏洩等が起きぬよう、予防並びに安全対策を講じます。</li>
  
            <li>5. 当社では、お客様と当社との間でのクレジットカード番号を含むすべての通信内容に対して、SSL（Secure Socket Layer）テクノロジーによる暗号化を施す等して、合理的な範囲内でセキュリティの強化に努めます。しかしながら、SSL (Secure Socket Layer) テクノロジー等のセキュリティ技術であっても、そのセキュリティが完全であるとまではいいきれません。万一、当社によるセキュリティ施策にもかかわらず、ハッカー等による不当な行為により、お客様および第三者に損害が生じた場合については、当社は責任を負いかねますのでご了承ください。</li>
  
            <li>6. お客様がご自身の個人情報に関する照会、確認、修正等を希望される場合には、合理的な範囲で速やかに対応します。</li>
  
            <li>7. 当社は、個人情報保護に関する法令及びその他の規範を遵守します。</li>
  
            <li>8. 当社は、個人情報の保護及び取扱いについて、継続的改善に努めます。</li>
        </ul>
      </section>
    </section>
  </div>
  