import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trouble-mail',
  templateUrl: './trouble-mail.component.html',
  styleUrls: ['./trouble-mail.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class TroubleMailComponent implements OnInit {

  title: string = 'メールについてのご注意';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: 'メールについてのご注意', url: '/informations/trouble-mail'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
