<app-toolbar [title]="title" [is_purchase]="is_purchase" [game]="game">
</app-toolbar>
<ng-container *ngIf='game'>
  <app-menubar [game]="game"></app-menubar>
  <app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>
  <div>
    <app-search-bar [is_purchase]="is_purchase" [game]="game" [category]="category">
    </app-search-bar>
  </div>
</ng-container>
<div class="contents" [ngClass]="{'purchase-contents':is_purchase}">
  <mat-drawer-container class="main-container">
    <mat-drawer mode="side" opened class="cardset-list" *ngIf='game' id="cardset-list">
      <app-category-tile
        *ngIf='game.type_genre_id!=TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
        [game]="game" [has_reservation]="has_reservation" [category]="category" [is_purchase]="is_purchase">
      </app-category-tile>
      <app-cardset-list [game]="game" [is_purchase]="is_purchase" [cardsets]="cardsets" [criteria]="criteria"
        [category]="category" *ngIf="category!='supply'" (onSelectCardset)="onSelectCardset($event)">
      </app-cardset-list>
      <app-supply-genre *ngIf="category===TypeConstant.STR_SUPPLY" [game]="game" [supplytypes]="supplytypes"
        [supplytype_id]="supplytype_id" [subtype_id]="subtype_id" [is_purchase]="is_purchase">
      </app-supply-genre>

    </mat-drawer>
    <mat-drawer-content>
      <app-category class="category-display" [game]="game" [is_purchase]="is_purchase"></app-category>

      <div class="card-viewer-image">
        <app-card-itembox [current_item]="current_item" [items]="items" [game]="game" [pagenate]="pagenate"
          [is_purchase]="is_purchase" (addToCartItems)="runAddToCartItems(items)"
          (onSelectCurrentItem)="selectCurrentItem($event)" (loadSameCardPage)="onLoadSamecardPage($event)">
        </app-card-itembox>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>

</div>
