<div class="cardset-select" [ngClass]="!is_purchase?'mat-product-accent':'mat-purchase'">
  <div class="cardset-list-title">
    <mat-icon>style</mat-icon><span i18n>セットを選ぶ</span>
  </div>
  <div>
    <a class="window-open-button" (click)="openCardsetWindow()" mat-stroked-button color="primary"
      *ngIf="category!='supply'">
      セットを検索<mat-icon>filter_alt</mat-icon>
    </a>
  </div>
</div>
<div [ngClass]="!is_purchase?'':'purchase'">
  <div *ngFor="let cardset of cardsets">
    <div *ngIf="cardset.name.trim().substring(0,2)=='--'" class='card-list-separation'>
      {{cardset.name}}
    </div>
    <ng-container *ngIf="cardset.name.trim().substring(0,2)!='--'">

      <!-- cardset -->
      <button *ngIf="cardset.id!=selectedCardsets[0]" class='cardset-list-name cardset'
        (click)="goToListPage($event,{cardsets:cardset.id})">
        <span *ngIf="cardset.is_reservation" class="tag tag-reservation">【予約】
        </span>
        {{cardset.name}}
      </button>

      <!-- current -->
      <button *ngIf="cardset.id==selectedCardsets[0]"
        [ngClass]="{'arrow_drop_up':currentCardset==cardset.id,'arrow_drop_down':currentCardset!=cardset.id}"
        class='cardset-list-name active-cardset' (click)="openSubmenu(cardset.id)">
        <span *ngIf="cardset.is_reservation" class="tag tag-reservation">【予約】
        </span>
        {{cardset.name}}
      </button>
      <ng-container *ngIf="isLoading">
        loading...<img src="./assets/images/bigweb_big.gif" class="loading-icon">
      </ng-container>
      <ng-container *ngIf="!isLoading && currentCardset==cardset.id">
        <ng-container *ngIf="rarities.length > 0">
          <ng-container *ngIf="cardset.id == selectedCardsets[0]">
            <button class="cardset-list-item" color="primary" (click)="goToListPage($event,{cardsets:cardset.id})"
              *ngIf="selectedRarity.length > 0 && !is_box || is_box">
              全て
            </button>
            <button class="cardset-list-item active-rarity" color="primary" *ngIf="selectedRarity.length == 0 && !is_box">
              全て
            </button>
            <ng-container *ngFor="let rarity of rarities">
              <button (click)="goToListPage($event,{cardsets:cardset.id,rarity:rarity.id,is_box:0})"
                class="cardset-list-item" *ngIf="rarity.id!=selectedRarity[0]">
                {{rarity.name}}
              </button>
              <button class="cardset-list-item active-rarity" *ngIf="rarity.id==selectedRarity[0]">
                {{rarity.name}}
              </button>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="cardset.is_box==1">
          <button (click)="goToListPage($event,{cardsets:cardset.id,is_box:1})" class="cardset-list-item"
            *ngIf="selectedRarity.length>0||!is_box">
            箱・パック
          </button>
          <button *ngIf="selectedRarity.length==0&&is_box" class="cardset-list-item active-rarity">
            箱・パック
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
