import {Component, OnInit, Input, Inject} from '@angular/core';
import {StoresService} from '../_services';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-histories',
    templateUrl: './histories.component.html',
    styleUrls: ['./histories.component.scss']
})
export class HistoriesComponent implements OnInit {

    game: any;
    is_purchase: boolean;
    title: string = '履歴'
    items: any[] = [];
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: 'products'},
        {title: '履歴', url: ''}
    ];

    private historiesSubscription: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<HistoriesComponent>,
        public storesService: StoresService,
    ) {

    }

    ngOnInit(): void {
        let me = this;

        let histories = sessionStorage.getItem('histories');
        if (histories) {
            me.items = JSON.parse(histories);
        }
        me.historiesSubscription = me.storesService.$history.subscribe(
            history => {

                if (history.action == 'add') {
                    me.onChangeHistories(history);
                }
            }
        );


    }

    runAddToCartItems(items: any) {
        //this.addToCartItems.emit(items);
        this.storesService.addCartItems(items);

        let CartItems = this.storesService.getCartItems();
    }

    removeHistory(item) {
        let me = this;
        for (let i = 0; i < me.items.length; i++) {
            if (me.items[i].id == item.id) {
                me.items.splice(i, 1);
            }
        }
        sessionStorage.setItem('histories', JSON.stringify(me.items));
    }

    reloadHistories(game) {
        let me = this;
        me.items = me.storesService.getHistories(game);
    }

    onChangeHistories(history: any) {
        let me = this;
        me.items.push(history);

        sessionStorage.setItem('histories', JSON.stringify(me.items));
    }

    closeDialog() {
        let me = this;
        me.dialogRef.close();
    }

}
