import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

    title: string = '店舗ページ';
    is_purchase: boolean = false;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: '店舗ページ', url: 'shop/'}
    ];

    constructor() {}

    ngOnInit(): void {
        window.scroll(0, 0);
    }

}
