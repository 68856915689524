<div class="search-bar">

  <form class="search-form" [formGroup]="searchForm" [ngClass]="{'scrolled':is_scrolled}">
    <div class="search-toolbar" fxLayout="row" fxLayoutAlign="start center"
      [ngClass]="!is_purchase?'mat-product-accent':'mat-purchase'" *ngIf="game">
      <a fxFlex="200px"
        *ngIf="!is_purchase && category!=TypeConstant.STR_SUPPLY && category!=TypeConstant.STR_CHARACTER_SUPPLY"
        href="products/{{game.code}}" class="game-title">
        <img class="game-logo-img" src="{{image_url}}/logo/logo-{{game.id}}.png" alt="{{game.id}}：{{game.title}}">
      </a>

      <a fxFlex="200px" class="purchase_label" *ngIf="is_purchase" href="purchase/{{game.code}}" class="game-title">
        <img class="game-logo-img" src="{{image_url}}/logo/logo-{{game.id}}.png" alt="{{game.id}}：{{game.title}}">
      </a>

      <div fxFlex="" fxLayout="row" fxLayoutAlign="center" class="search-bar-inner">
        <div fxFlex="fit-content" fxLayoutAlign="center">
          <a class="selected-button cardset-button" mat-raised-button (click)="openCardsetWindow()" color="primary">
            <mat-icon>filter_alt</mat-icon>
            <span matBadgeColor="warn">
              <ng-container
                *ngIf="game.id === TypeConstant.SUPPLY || game.id === TypeConstant.CHARACTER_SUPPLY; else selectCardsets">
                <span i18n>カテゴリを検索</span>
              </ng-container>
              <ng-template #selectCardsets>
                <span i18n>セットを検索</span>
              </ng-template>
            </span>
          </a>
          <a class="selected-button" mat-raised-button (click)="openSearchWindow()" color="primary">
            <mat-icon>filter_alt</mat-icon>
            <span matBadge="{{getFilterCount()}}" matBadgeColor="warn">
              <span i18n>条件で絞り込む</span>
            </span>
          </a>
        </div>

        <div fxFlex class="search_word_wrap">
          <mat-form-field fxFlex="1 0 auto" id="search_word" appearance="legacy">

            <mat-label><span i18n>検索キーワード</span></mat-label>
            <button mat-icon-button matPrefix (click)="scrollTop()" class="search-scrolltop">
              <mat-icon>search</mat-icon>
            </button>
            <div class="search_input_inner">
              <input type="text" placeholder="商品名を入力してください。" aria-label="Number" matInput id="search_word-input"
                formControlName="searchword" [matAutocomplete]="auto" #trigger="matAutocompleteTrigger"
                (keyup.enter)="onEnter($event,trigger)">
              <div>
                <button mat-icon-button *ngIf="searchForm && searchForm.value.searchword" matSuffix mat-icon-button
                  aria-label="Clear" (click)="clearKeyword()">
                  <mat-icon class="search-bar-close">close</mat-icon>
                </button>
              </div>

              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectKeyword($event.option)">
                <mat-optgroup *ngFor="let group of searchOptions | async" [label]="group.shape">
                  <mat-option *ngFor="let name of group.names" [value]="name">
                    {{name}}
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
              <button mat-icon-button *ngIf="searchForm && searchForm.value.searchword" mat-button
                (click)="onSubmitSearch()" class="search-btn">
                <mat-icon>search</mat-icon>検索
              </button>
            </div>

          </mat-form-field>
        </div>

        <div fxFlex="380px" fxLayoutAlign="end" class="select-set" *ngIf="category!='supply'">
          <div class="product-buttons purchase-switch-sub" *ngIf="!is_purchase">
            <a class="product-buttons-link mat-purchase" *ngIf="!is_purchase" mat-button color="primary"
              href="/{{langParam}}{{!game?'purchase':purchaseUrl}}">
              <span i18n>買取はこちら></span>
            </a>
          </div>
          <div class="product-buttons purchase-switch-sub" *ngIf="is_purchase">
            <a class="purchase-buttons-link is_purchase" *ngIf="is_purchase" mat-button color="primary"
              href="/{{langParam}}{{!game?'products':salesUrl}}">
              <span i18n>購入はこちら></span>
            </a>
          </div>
        </div>
        <div fxFlex="80px" *ngIf="is_scrolled&&category!='supply'&&!is_purchase">
          <div class="product-buttons ">
            <button mat-icon-button class="serach-cart_icon_box" (click)="openCart()">

              <mat-icon *ngIf="cartCount==0">shopping_cart</mat-icon>
              <mat-icon *ngIf="cartCount>0" matBadge="{{cartCount}}" matBadgeColor="primary">shopping_cart</mat-icon>
              <div class="login-button-label"><span i18n>カート</span></div>
            </button>
          </div>
        </div>
        <div fxFlex="80px" *ngIf="is_scrolled&&category!='supply'&&is_purchase">
          <div class="product-buttons">
            <button mat-icon-button class="serach-cart_icon_box_purchase" (click)="openPurchaseCart()">
              <i class="fas fa-box-open fa-lg"></i>
              <mat-icon class="purchase-cart-btn" *ngIf="purchaseCartCount>0" matBadge="{{purchaseCartCount}}"
                matBadgeColor="primary"></mat-icon>
              <div class="login-button-label"><span i18n>買取カート</span></div>
            </button>
          </div>
        </div>
      </div>

    </div>



  </form>
</div>