import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../_services';
 
@Component({
    selector   : 'app-footer',
    templateUrl:  './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    localeId: string;
    
    constructor(
        public settingsService: SettingsService,
    ) {
        this.localeId = this.settingsService.getLocale();
    }

    ngOnInit(): void {
    }

}
