<div>
  <div class="in-cart-container">
    <div *ngIf="item.relatedItems&&item.relatedItems[0].temp_quantity" class="added"></div>
    <div *ngIf="item.relatedItems&&item.relatedItems[0].temp_quantity" class="added-comment-box">
      <div class="added-comment">in cart</div>
    </div>
  </div>

  <div class="item-box grid-item-box">

    <div class="item-image-box grid-item-image-box"
      [ngClass]="{'in-cart-items':item.relatedItems&&item.relatedItems[0].temp_quantity}"
      [ngClass]="{'sold_out_image_box':item.relatedItems&&item.relatedItems[0].is_sold_out && !is_reservation}">
      <div class="grid-item-image">
        <picture>
          <!-- <source type="image/webp" srcset="{{item.webp}}" (click)="openModalItem(item)"
          class="swiper-lazy item-view-image grid-item-view-image" /> -->
          <source type="image/jpeg" srcset="{{item.image}}" (click)="openModalItem(item)"
          class="swiper-lazy item-view-image grid-item-view-image" />
          <img data-src="{{item.image}}" alt="{{item.name}}" (click)="openModalItem(item)"
          class="swiper-lazy item-view-image grid-item-view-image" />
          <span *ngIf="item.sale_words" class="sale-words">{{item.sale_words}}</span>
        </picture>
      </div>
      <div class="item-title-box grid-item-title-box">
        <div *ngIf="item.card_condition && item.card_condition.is_foiled" class="product-tag is_foiled">
          FOIL
        </div>
        <div *ngIf="item.card_condition && item.card_condition.is_scratched"
          class="product-tag is_scratched grid-is_scratched">
          キズ有り
        </div>
        <div *ngIf="is_purchase" class="product-tag purchase-tag grid-purchase-tag">
          買取
        </div>
        <ng-container *ngIf="item.relatedItems&&item.relatedItems[0].temp_quantity">
          <div *ngIf="!is_purchase" matBadge="{{item.relatedItems[0].temp_quantity}}" matBadgeColor="accent"
            class="shopping-cart-button grid-shopping-cart-button">
          </div>
          <div *ngIf="is_purchase" matBadge="{{item.relatedItems[0].temp_quantity}}" matBadgeColor="purchase"
            class="shopping-cart-button grid-shopping-cart-button">
          </div>
        </ng-container>

        <div class="images-item-title grid-images-item-title" *ngIf="item.cardset; else nonCardset">
          <a href="/{{localeId}}/{{is_purchase?'purchase':'products'}}/{{item.game.code}}/cardViewer/{{item.id}}">
            {{item.name}} [{{item.cardset.slip}}]</a>
        </div>
        <ng-template #nonCardset>
          <div class="images-item-title grid-images-item-title">
            <a
              href="/{{localeId}}/{{is_purchase?'purchase':'products'}}/{{item.game.code}}/cardViewer/{{item.id}}">{{item.name}}</a>
          </div>
        </ng-template>

        <div class="images-item-title grid-images-item-title"
          [ngClass]="{'sold_out_item_text':item.relatedItems&&item.relatedItems[0].is_sold_out && !is_reservation}">
          <span *ngIf="item.fname">{{item.fname}}</span>
          <span *ngIf="item.language && item.language.web && item.language.web != '指定なし'">
            [{{item.language.web}}]
          </span>
          <ng-container *ngIf="item.rarity" class="item-rarity-box">
            <span *ngIf="item.rarity.slip && item.rarity.slip != ''; else rarityName">[{{item.rarity.slip}}]</span>
            <ng-template #rarityName><span *ngIf="item.rarity.web != ''">[{{item.rarity.web}}]</span></ng-template>
          </ng-container>
          <ng-container *ngIf="!is_purchase">
            <span *ngIf="item.card_condition && item.card_condition.web">
              {{item.card_condition.web}}
            </span>
          </ng-container>
        </div>
        <div>
          <span class="grid-item-comment" [innerHTML]="item.trust_comment"></span>
        </div>

        <div *ngIf="item.relatedItems&&item.relatedItems[0].is_sold_out" class="sold_out-box grid-sold_out-box"
          (click)="openModalItem(item)">
          <div class="sold_out-label grid-sold_out-label buying" *ngIf="is_purchase" else itemSoldOut>STOP</div>
          <ng-template #itemSoldOut>
            <div class="sold_out-label grid-sold_out-label" *ngIf="!item.is_reserve_closed;" else itemClosed>SOLD OUT
            </div>
            <ng-template #itemClosed>
              <div class="sold_out-label grid-sold_out-label">CLOSED</div>
            </ng-template>
          </ng-template>
        </div>

      </div>
    </div>
    <div class="item-buttons grid-item-buttons" *ngIf="item.relatedItems" [ngClass]="{'is_sold_out-price':item.relatedItems && item.relatedItems[0].is_sold_out && !is_reservation
             ,'has-cart-item' : item.relatedItems[0].quantity>0
             }">

      <div *ngIf="!item.is_hidden_price&&item.price>0"
        [ngClass]="{'is_scratched-price':item.card_condition && item.card_condition.is_scratched}">
        <div class="grid-images-item-price">
          <span class="item-point">
            <ng-container *ngIf="item.point>0">
              {{item.point|number}}ポイント
            </ng-container>
          </span>
          <span *ngIf="item.is_bargain" class="base-price">
            {{item.sale_prices|number}}円
          </span>
          <span *ngIf="item.is_bargain" class="scale" [ngClass]="{'sales-price':item.is_bargain}" class="item-price">
            {{item.price|number}}円
          </span>
          <span class="scale" *ngIf="!item.is_bargain && item.price"
            [ngClass]="{'item-product-price':!is_purchase,'item-purchase-price':is_purchase}">
            {{item.price|number}}円
          </span>
        </div>
      </div>
      <div *ngIf="!item.relatedItems[0].is_sold_out" class="itembox-cart-gadget">
        <button [attr.aria-label]="'remove'" class="cart-button small" (click)="addCount(item,-1)">
          <i class="material-icons">remove</i>
        </button>
        <div [ngClass]="{'largeQuantity' : item.stock_count > 99}">
          <mat-select class="cart-input" [value]="item.relatedItems[0].quantity"
            [ngClass]="{'hasTempCart' : item.relatedItems[0].quantity!=item.relatedItems[0].temp_quantity,'hasCart':item.relatedItems[0].quantity>0}"
            (selectionChange)="selectionChange($event,item)" *ngIf="item.relatedItems[0].quantity<11">
            <mat-option *ngFor="let num of getStockOptions(item.relatedItems[0].stock_count)" [value]="num"
              [ngClass]="{'hasTempCart' : item.relatedItems[0].quantity==num,'hasCart':item.relatedItems[0].temp_quantity==num}">
              {{num}}
            </mat-option>
            <mat-option [value]="11" *ngIf="item.relatedItems[0].stock_count >10">
              -- 直接入力 --
            </mat-option>
          </mat-select>
          <input matInput type="number" class="cart-input" [value]="item.relatedItems[0].quantity"
            [ngClass]="{'hasTempCart' : item.relatedItems[0].quantity!=item.relatedItems[0].temp_quantity,'hasCart':item.relatedItems[0].quantity>0}"
            (change)="checkStock($event,item)" autocomplete="off" *ngIf="item.relatedItems[0].quantity>10">
          <span matSuffix class="cart-input-suffix">/{{item.relatedItems[0].stock_count}}</span>
        </div>
        <button [attr.aria-label]="'add'" class="cart-button small" (click)="addCount(item,1)">
          <i class="material-icons">add</i>
        </button>
      </div>

      <div *ngIf="item.relatedItems[0].is_sold_out && !is_reservation">
        <div *ngIf="is_purchase">
          <div>買取終了</div>
          <div>
            <button (click)="searchSameProducts(item)" mat-flat-button class=" mat-primary samename_search_btn">
              <i class="material-icons">search</i><span i18n>同名検索</span>
            </button>
          </div>
        </div>
        <div *ngIf="!is_reservation && !is_purchase">
          <div>売り切れ</div>
          <div>
            <button (click)="searchSameProducts(item)" mat-flat-button class=" mat-primary samename_search_btn">
              <i class="material-icons">search</i><span i18n>同名検索</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!is_purchase">
        <div class="cart-item-buttons" *ngIf="!getBadgeCount(item)"
          [ngClass]="{'maxcount-button':item.relatedItems[0].temp_quantity==item.relatedItems[0].stock_count}">
          <div>
            <button class="add-to-cart-button" (click)="addToCartByNow(item)"
              *ngIf="item.relatedItems[0].temp_quantity <item.relatedItems[0].stock_count">
              <span class="plus-one-cart-button-label">カートに追加</span>
            </button>
            <div class="maxcount-message"
              *ngIf="!item.is_sold_out&&item.relatedItems&&item.relatedItems[0].temp_quantity ==item.relatedItems[0].stock_count">
              在庫上限です
            </div>
          </div>
        </div>
        <div *ngIf="getBadgeCount(item)">
          <div>
            <button *ngIf="item.relatedItems[0].temp_quantity" class="add-to-cart-button" (click)="addToCart(item)"
              color="warn">
              <span class="add-to-cart-button-label">数量を変更</span>
            </button>
            <button *ngIf="!item.relatedItems[0].temp_quantity" class="add-to-cart-button" (click)="addToCart(item)"
              color="warn">
              <span class="add-to-cart-button-label">カートに入れる</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="is_purchase">
        <div class="cart-item-buttons" *ngIf="!getBadgeCount(item)">
          <div>
            <button class="add-to-cart-button" (click)="addToPurchaseCartByNow(item)"
              *ngIf="item.relatedItems[0].temp_quantity <item.relatedItems[0].stock_count">
              <span class="plus-one-cart-button-label">買取カートに追加</span>
            </button>
            <div class="maxcount-message" *ngIf="item.relatedItems[0].temp_quantity ==item.relatedItems[0].stock_count">
              買取上限です
            </div>
          </div>
        </div>
        <div *ngIf="getBadgeCount(item)">
          <div>
            <button *ngIf="item.relatedItems[0].temp_quantity" class="add-to-cart-button"
              (click)="addToPurchaseCart(item)" color="warn">
              <span class="add-to-cart-button-label">数量を変更</span>
            </button>
            <button *ngIf="!item.relatedItems[0].temp_quantity" class="add-to-cart-button"
              (click)="addToPurchaseCart(item)" color="warn">
              <span class="add-to-cart-button-label">買取カートに入れる</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<!-- <div class="swiper-lazy-preloader"></div> -->