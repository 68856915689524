import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-akihabara',
    templateUrl: './akihabara.component.html',
    styleUrls: ['./akihabara.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class AkihabaraComponent implements OnInit {

    title: string = 'BIG MAGIC 秋葉原店';
    is_purchase: boolean = false;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: '店舗ページ', url: 'shop/'},
        {title: '秋葉原店', url: 'shop/akihabara'},
    ];

    constructor() {}

    ngOnInit(): void {
        window.scroll(0, 0);
    }


}
