import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
    @Input() game: any;
    @Input() is_purchase: boolean;
    @Input() category: string;
    current: string;
    salesUrl: string = 'products';
    purchaseUrl: string = 'purchase';
    langParam: string = '/ja/';
    urlPath: any;
    constructor(private router: Router) {

    }

    ngOnInit(): void {
        let me = this;

        me.urlPath = me.router.url;

        me.langParam = location.pathname;

        me.salesUrl = me.urlPath.replace('/purchase/', '/products/')
            .replace('is_purchase=1', 'is_purchase=0');

        me.purchaseUrl = me.urlPath.replace('/products/', '/purchase/')
            .replace('is_purchase=0', 'is_purchase=1');
    }

}
