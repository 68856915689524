import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GamesService, SearchService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CardsetPickerComponent } from '../search-bar/cardset-picker/cardset-picker.component';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-cardset-list',
    templateUrl: './cardset-list.component.html',
    styleUrls: ['./cardset-list.component.scss']
})
export class CardsetListComponent implements OnInit {
    @Output() onSelectCardset: EventEmitter<any> = new EventEmitter();

    @Input() game: any;
    @Input() is_purchase: boolean;
    @Input() cardsets: any;
    @Input() selectedCardsets: any[] = [];
    @Input() selectedRarity: any[] = [];
    @Input() criteria: any;
    @Input() category: string;
    rarities: any = [];
    selectedRarities: any = [];
    filterConfigs: any;
    isLoading: boolean = false;
    currentCardset: number = 0;
    game_title: string;
    lastScrollTop: number = 0;
    is_box: boolean = false;
    constructor(
        public gamesService: GamesService,
        public searchService: SearchService,
        private route: ActivatedRoute,

        private router: Router,
        public dialog: MatDialog,
        //public loadingService: LoadingService,
    ) {

        let me = this;
        me.route.paramMap.subscribe(paramsMap => {
            me.game_title = paramsMap.get('title').replace('__', '/');

            if (me.game_title == 'MTG') {
                me.rarities = [
                    {
                        "id": 1421,
                        "name": "神話レア",
                        "ordering_id": 1384
                    },
                    {
                        "id": 1,
                        "name": "レア",
                        "ordering_id": 1484
                    },
                    {
                        "id": 2,
                        "name": "アンコモン",
                        "ordering_id": 1486
                    },
                    {
                        "id": 3,
                        "name": "コモン",
                        "ordering_id": 1488
                    }
                ]
            }
        })

    }

    ngOnInit(): void {
        let me = this;
        let selectedRarities = sessionStorage.getItem('selectedRarities');
        me.selectedRarities = selectedRarities ? JSON.parse(selectedRarities) : [];
        me.route.queryParams.subscribe(
            params => {

                me.is_box = params.is_box == 1 ? true : false;
            }
        );

    }

    ngAfterViewInit() {
        let me = this;

        setTimeout(function () {
            me.scrollToLastScrollTop();
            if (me.selectedCardsets && me.selectedCardsets.length > 0) {
                me.currentCardset = me.selectedCardsets[0];
                if (me.game_title != 'MTG') {
                    me.loadRarities(me.currentCardset);
                }
            }
        }, 100)
    }

    scrollToLastScrollTop() {
        let me = this;
        if (localStorage.getItem('cardsetlist-scrolltop')) {
            me.lastScrollTop = Number(localStorage.getItem('cardsetlist-scrolltop'));

            localStorage.removeItem('cardsetlist-scrolltop');
            setTimeout(function () {
                let cardsetlist = document.getElementById('cardset-list')
                let elm = cardsetlist.getElementsByClassName('mat-drawer-inner-container')[0];
                if (elm) {
                    elm.scroll(0, me.lastScrollTop);
                }
            }, 200);
        }

    }

    loadRarities(cardset) {
        let me = this;
        me.gamesService.loadRarities(cardset).subscribe((result: any) => {
            me.rarities = result.rarities;
            me.isLoading = false;

        });
    }

    openSubmenu(id) {
        if (id == this.currentCardset) {
            this.currentCardset = 0;
        } else {
            this.currentCardset = id;
        }
    }

    onMenuClosed() {
        this.rarities = [];
    }

    goToListPage($event, params) {
        let me = this;

        if (me.is_purchase) {
            params.is_purchase = 1;
        }

        params = new HttpParams().appendAll(params);
        let url = (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code + '/list?'+ params.toString()

        //me.showLoading($event);
        setTimeout(function () {
            return me.router.navigateByUrl(url);
            //me.router.navigate([url], { queryParams: params });
        }, 10);
    }

    showLoading($event) {
        let me = this;
        let elm = document.getElementById('cardset-list');

        if (elm.getElementsByClassName('mat-drawer-inner-container')[0]) {
            me.lastScrollTop = elm.getElementsByClassName('mat-drawer-inner-container')[0].scrollTop;
            localStorage.setItem('cardsetlist-scrolltop', String(me.lastScrollTop));
        }
        me.onSelectCardset.emit($event);
        let selectedRarities = sessionStorage.getItem('selectedRarities');
        me.selectedRarities = selectedRarities ? JSON.parse(selectedRarities) : [];

    }

    //    isSelected(id) {
    //        let me = this;
    //        if (me.selectedCardsets) {
    //            if (me.selectedCardsets.indexOf(id) > -1) {
    //                return true;
    //            }
    //        }
    //        return false;
    //    }
    //
    //    isSelectedRarity(rarity, cardset_id) {
    //        let me = this;
    //        if (me.selectedRarity && me.isSelected(cardset_id)) {
    //            if (me.selectedRarity.indexOf(rarity.id) > -1) {
    //                return true;
    //            }
    //        }
    //        return false;
    //    }

    openCardsetWindow() {
        let me = this;

        const dialogRef = this.dialog.open(CardsetPickerComponent, {
            panelClass: 'p-modal-confirm',
            disableClose: false,
            autoFocus: true,
            height: '80%',
            width: '600px',
            data:
            {
                'game': me.game,
                'cardsets': me.cardsets,
                'criteria': me.criteria,
                'selectedCardsets': me.selectedCardsets,
                'category': me.category,
                'is_purchase': me.is_purchase
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            //      me.createSwiper();
        });

    }
}
