import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-guide',
    templateUrl: './guide.component.html',
    styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

    title: string = 'ご利用案内ページ';
    is_purchase: boolean = false;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: 'ご利用案内ページ', url: 'guide/'}
    ];
    is_static: boolean = true;
    constructor() {}

    ngOnInit(): void {
        window.scroll(0, 0);
    }

}
