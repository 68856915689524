import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { TopComponent } from './products/top/top.component';
import { ProductsComponent } from './products/main/main.component';
import { GamesComponent } from './products/games/games.component';
import { FavoritesComponent } from './products/favorites/favorites.component';

import { PurchaseTopComponent } from './purchase/top/top.component';
import { PurchaseComponent } from './purchase/main/main.component';
import { PurchaseGamesComponent } from './purchase/games/games.component';
//import {FavoritesComponent} from './purchase/favorites/favorites.component';

import { ContactComponent } from './pages/contact/contact.component';
import { ShopComponent } from './pages/shop/shop.component';
import { AkihabaraComponent } from './pages/shop/akihabara/akihabara.component';
import { IkebukuroComponent } from './pages/shop/ikebukuro/ikebukuro.component';
import { NagoyaComponent } from './pages/shop/nagoya/nagoya.component';
import { NagoyaZeroComponent } from './pages/shop/nagoya-zero/nagoya-zero.component';
import { NambaComponent } from './pages/shop/namba/namba.component';
import { RecruitComponent } from './pages/shop/recruit/recruit.component';

import { GuideComponent } from './pages/guide/guide.component';
import { ShippingComponent } from './pages/informations/shipping/shipping.component';
import { TroubleMailComponent } from './pages/informations/trouble-mail/trouble-mail.component';

import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { CardViewerComponent } from './commons/card-viewer/card-viewer.component';

import { OrderguideComponent } from './pages/guide/orderguide/orderguide.component';
import { OrderconfirmationComponent } from './pages/guide/orderconfirmation/orderconfirmation.component';
import { CancelComponent } from './pages/guide/cancel/cancel.component';
import { PointbackComponent } from './pages/guide/pointback/pointback.component';
import { CardconditionsMtgComponent } from './pages/guide/cardconditions-mtg/cardconditions-mtg.component';
import { CardconditionsComponent } from './pages/guide/cardconditions/cardconditions.component';
import { NoticeSharedcardsComponent } from './pages/guide/notice-sharedcards/notice-sharedcards.component';
import { StockInfoComponent } from './pages/guide/stock-info/stock-info.component';
import { TermsComponent } from './pages/guide/terms/terms.component';
import { PrivacyComponent } from './pages/guide/privacy/privacy.component';
import { CompanyComponent } from './pages/guide/company/company.component';
import { ReservationsInfoComponent } from './pages/guide/reservations-info/reservations-info.component';
import { WebPurchaseComponent } from './pages/guide/web-purchase/web-purchase.component';
import { BulkPurchaseComponent } from './pages/guide/bulk-purchase/bulk-purchase.component';
import { ShopPurchaseComponent } from './pages/guide/shop-purchase/shop-purchase.component';
import { WhatIsBigwebmanComponent } from './pages/guide/what-is-bigwebman/what-is-bigwebman.component';
import { PaymentComponent } from './pages/guide/payment/payment.component';


const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'products/:title/cardViewer/:id', component: CardViewerComponent },
    { path: 'purchase/:title/cardViewer/:id', component: CardViewerComponent },
    { path: 'products', component: TopComponent, pathMatch: 'full' },
    { path: 'products/favorites', component: FavoritesComponent },
    { path: 'products/:title/favorites', component: GamesComponent },
    { path: 'products/:title/list', component: GamesComponent },
    { path: 'products/:title/single', redirectTo: 'products/:title' },
    { path: 'products/:title/:category', component: ProductsComponent },
    { path: 'products/:title', component: ProductsComponent },

    { path: 'purchase', component: PurchaseTopComponent, pathMatch: 'full' },
    { path: 'purchase/:title/list', component: PurchaseGamesComponent },
    { path: 'purchase/:title/:category', component: PurchaseComponent },
    { path: 'purchase/:title', component: PurchaseComponent },

    { path: 'contact', component: ContactComponent },
    { path: 'shop', component: ShopComponent },
    { path: 'shop/akihabara', component: AkihabaraComponent },
    { path: 'shop/ikebukuro', component: IkebukuroComponent },
    { path: 'shop/nagoya', component: NagoyaComponent },
    { path: 'shop/nagoya_zero', component: NagoyaZeroComponent },
    { path: 'shop/namba', component: NambaComponent },
    { path: 'shop/recruit', component: RecruitComponent },

    { path: 'guide', component: GuideComponent },
    { path: 'guide/cancel', component: CancelComponent },
    { path: 'guide/cardconditions', component: CardconditionsComponent },
    { path: 'guide/cardconditions-mtg', component: CardconditionsMtgComponent },
    { path: 'guide/company', component: CompanyComponent },
    { path: 'guide/notice-sharedcards', component: NoticeSharedcardsComponent },
    { path: 'guide/orderconfirmation', component: OrderconfirmationComponent },
    { path: 'guide/orderguide', component: OrderguideComponent },
    { path: 'guide/pointback', component: PointbackComponent },
    { path: 'guide/privacy', component: PrivacyComponent },
    { path: 'guide/reservations', component: GuideComponent },
    { path: 'guide/stock-info', component: StockInfoComponent },
    { path: 'guide/terms', component: TermsComponent },
    { path: 'guide/reservations-info', component: ReservationsInfoComponent },
    { path: 'guide/bulk-purchase', component: BulkPurchaseComponent },
    { path: 'guide/web-purchase', component: WebPurchaseComponent },
    { path: 'guide/shop-purchase', component: ShopPurchaseComponent },
    { path: 'guide/what-is-bigwebman', component: WhatIsBigwebmanComponent },
    { path: 'guide/payment', component: PaymentComponent },

    { path: 'informations/shipping', component: ShippingComponent },
    { path: 'informations/trouble-mail', component: TroubleMailComponent },

    { path: '', redirectTo: 'products', pathMatch: 'full' },

    { path: '**', component: PageNotFoundComponent },

];


@NgModule({
    imports: [RouterModule.forRoot(routes, {
        //useHash: true,
        relativeLinkResolution: 'legacy',

        //      同じURLでもngOnInitを呼び出す処理
        //onSameUrlNavigation: 'reload'
        //      #2: 修正した結果不要かも？

    })],
    exports: [RouterModule]
})

export class AppRoutingModule {
    carts: any;
}

