import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orderguide',
  templateUrl: './orderguide.component.html',
  styleUrls: ['./orderguide.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class OrderguideComponent implements OnInit {

  title: string = 'ご注文手順';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: 'ご注文手順', url: '/guide/orderguide'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
