import {Component, OnInit} from '@angular/core';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {LoginService, SettingsService} from '../_services';
import {Router} from '@angular/router';
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    localeId: string = 'ja';
    token: string;
    loginUser: any;
    visible_password: boolean = false;
    error_msg: string = "";
    urlPath: any;
    ja_host: string = "";
    en_host: string = "";
    title: string = 'ログインページ';
    is_purchase: boolean = false;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: 'ログインページ', url: 'login/'}
    ];

    constructor(
        private formBuilder: FormBuilder,
        public loginService: LoginService,
        public settingsService: SettingsService,
        private router: Router
    ) {
        this.localeId = this.settingsService.getLocale();
        this.ja_host = environment.ja_host;
        this.en_host = environment.en_host;

        this.loginForm = this.formBuilder.group({
            username: new FormControl(''),
            password: new FormControl(''),
            token: new FormControl('')
        });
    }

    ngOnInit(): void {
        let me = this;
        me.urlPath = me.router.url;
        // 開発用にローカルストレージをクリア
        localStorage.clear();
    }

    onLogin() {
        let me = this;

        me.loginService.login(me.loginForm.value).subscribe((result: any) => {
            if (result && result.success) {
                me.loginService.setLoginUser(result.user);
                me.loginService.setToken(result.token);
                me.router.navigate(['/'], {replaceUrl: true});
            } else {
                me.error_msg = '※ 認証に失敗しました。ID、パスワードを確認してください。';
            }
        },
            (err: any) => {
                me.error_msg = '※ 認証に失敗しました。IDとパスワードを確認してください。';
            });
    }


}
