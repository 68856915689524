// ng serve
//
// ng build
import {games} from './data/games';
import {cardsets} from './data/cardsets';
import {banners} from './data/banners';
import {filterConfigs} from './data/filterConfigs';
import {recommends} from './data/recommends';
import {colours} from './data/colours';

export const environment = {
    production: false,
    host:'https://site.bigweb.co.jp',
    ja_host: '',
    en_host: '',
    server: 'https://api-staging.bigweb.co.jp/',
    escape_url: 'http://mtg.bigweb.co.jp',
    login_url: 'https://mtg.bigweb.co.jp/users/login/from_bigwebcojp',
    image_url: 'https://image.bigweb.co.jp/shop/images',
    cart_url: 'https://payment.bigweb.co.jp/cart/cart.php?renewal=1',
    parchase_cart_url: 'https://payment.bigweb.co.jp/buycart/order.php?renewal=1',

    // const parameter
    games: games,
    cardsets: cardsets,
    colours: colours,
    banners: banners,
    filterConfigs: filterConfigs,
    recommends: recommends,

    // GA4
    gtagId: 'GTM-WCKB553',
};
