import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../../environments/environment";
import { LoginService, SettingsService } from '../_services';

import { MatDialog } from '@angular/material/dialog';

import { SideMenuComponent } from '../toolbar/side-menu/side-menu.component';
import { StoresService } from '../_services';
import { UriCheckerUtil } from '../utils/uri-checker-util';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    @Input() title: any;
    @Input() game: any;
    @Input() games: any;
    @Input() breadcrumbs: any[];
    @Input() is_purchase: boolean;
    @Input() is_static: boolean;
    salesUrl: string = 'top';
    purchaseUrl: string = 'purchase/top';
    urlPath: string = '';
    localeId: string = 'ja';
    ja_host: string = "";
    en_host: string = "";
    loginUser: any = false;
    token: string;
    @Input() has_notice: boolean;
    cartItems: any[] = [];
    cartPurchaseItems: any[] = [];
    cartCount: number = 0;
    purchaseCartCount: number = 0;

    is_homeview: boolean = false;
    is_gameview: boolean = false;
    is_itemview: boolean = false;

    private cartCountSubscription: Subscription;
    private purchase_cartCountSubscription: Subscription;

    constructor(
        private router: Router,
        public dialog: MatDialog,

        public loginService: LoginService,
        public settingsService: SettingsService,
        public storesService: StoresService,
    ) {
        this.localeId = this.settingsService.getLocale();

        this.ja_host = environment.ja_host;
        this.en_host = environment.en_host;
    }

    ngOnInit(): void {

        let me = this;
        let exp = new UriCheckerUtil();
        me.urlPath = me.router.url;

        if ( exp.isItemView(me.urlPath) ) {
            me.salesUrl = 'products/' + me.title;
            me.purchaseUrl = 'purchase/' + me.title;
            me.is_itemview = true;
        } else
        if ( exp.isGameTop(me.urlPath) ) {
            me.salesUrl = 'products/' + me.title;
            me.purchaseUrl = 'purchase/' + me.title;
            me.is_gameview = true;
        } else {
            me.salesUrl = '/';
            me.purchaseUrl = 'purchase';
            me.is_homeview = true;
        }

        if (me.loginService.loginUser) {
            me.loginUser = me.loginService.loginUser;
        } else if (me.loginService.token) {
            me.loginService.loadLoginUser().subscribe(loginToken => {
                if (loginToken) {
                    me.loginUser = loginToken.user;
                    me.loginService.setLoginUser(loginToken.user);
                } else {
                    me.loginUser = 'Denial';
                }
            });
        } else {
            me.loginUser = 'Denial';
        }


        me.cartCountSubscription = me.storesService.$cartCount.subscribe(
            cartCount => {
                me.cartCount = cartCount;
            }
        );
        me.purchase_cartCountSubscription = me.storesService.$purchase_cartCount.subscribe(
            cartCount => {
                me.purchaseCartCount = cartCount;
            }
        );



    }

    menuOpen() {
        let me = this;

        const dialogRef = this.dialog.open(SideMenuComponent, {
            height: '80%',
            width: '90%',
            disableClose: false
        });


    }

    ngAfterViewInit() {

    }

    openFavorite() {
        this.storesService.openFavorite();
    }

    openHistory() {
        this.storesService.openHistory();
    }

    openCart() {
        this.storesService.openCart();
    }

    openPurchaseCart() {
        this.storesService.openPurchaseCart();
    }

    removeCartItems() {
        this.cartItems = [];
    }

    removeCartPurchaseItems() {
        this.cartPurchaseItems = [];
    }
}
