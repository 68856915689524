<app-toolbar [title]="title" [is_purchase]="is_purchase" [game]="game">
</app-toolbar>
<app-menubar [game]="game"></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>
<div class="contents purchase-contents">
  <ng-container *ngIf="game">
    <div>
      <app-search-bar fxFlex class="bigweb-buying-theme" [is_purchase]="is_purchase" [game]="game" [criteria]="criteria" [cardsets]="cardsets"
        [selectedCardsets]="selectedCardsets" [filterTags]="filterTags" [category]="category"
        (removeTags)="onRemoveTags()">
      </app-search-bar>
    </div>
    <mat-drawer-container class="main-container">
      <mat-drawer mode="side" opened class="cardset-list" id="cardset-list">
        <app-category-tile
          *ngIf='game.type_genre_id!=TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
          [game]="game" [category]="category" [is_purchase]="is_purchase">
        </app-category-tile>
        <app-cardset-list [game]="game" [is_purchase]="is_purchase" [cardsets]="cardsets"
          [selectedCardsets]="selectedCardsets" [selectedRarity]="selectedRarity" [criteria]="criteria"
          [category]="category" *ngIf="category!='supply'" (onSelectCardset)="onSelectCardset($event)">
        </app-cardset-list>
        <app-supply-genre *ngIf="category==TypeConstant.STR_CHARACTER_SUPPLY" [game]="game" [supplytypes]="supplytypes"
          [supplytype_id]="supplytype_id" [is_purchase]="is_purchase" [groupType]="groupType">

        </app-supply-genre>
      </mat-drawer>
      <mat-drawer-content>
        <app-category class="category-display"
          *ngIf='game.type_genre_id!=TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
          [game]="game" [category]="category" [is_purchase]="is_purchase">
        </app-category>
        <div fxLayoutAlign="start center" class="swiper-container filter-criteria" *ngIf="filterTags">
          <div>
            <span class="filter-criteria-label filter-criteria-label-game">
              <span i18n>検索条件：</span>
            </span>
          </div>
          <div>
            <ng-container *ngFor="let tag of filterTags">
              <a mat-stroked-button color="primary" class="swiper-slide filter-criteria-label">
                {{tag.name}}
                <mat-icon (click)="removeTag(tag)">cancel</mat-icon>
              </a>
            </ng-container>
          </div>
        </div>
        <div fxLayoutAlign="end center" >
          <div class="result_count" *ngIf="pagenate.count>0">検索結果:{{pagenate.count}}件</div>
        </div>
        <div class="paging-not-found" *ngIf="items.length==0&&!isLoading">
          上記検索条件に一致する商品はありませんでした。
        </div>
        <mat-toolbar class="change-view">
          <mat-toolbar-row fxLayout="row">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="300px">
              <span class="filter-criteria-label">並び順:</span>
              <mat-form-field appearance="outline" class="sort-select-field">
                <mat-select [value]="sortBy" (selectionChange)="selectionSort($event)">
                  <mat-option value="id">
                    ID順
                  </mat-option>
                  <mat-option value="price.desc">
                    価格（高い順）
                  </mat-option>
                  <mat-option value="price">
                    価格（安い順）
                  </mat-option>
                  <mat-option value="syllabary">
                    五十音順
                  </mat-option>
                  <mat-option value="orderid">
                    管理番号順
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="category!='boxes'&&category!='supply'">
              <span class="filter-criteria-label">グループ表示:</span>
              <mat-button-toggle-group [value]="groupType" #seriesGroup="matButtonToggleGroup"
                (change)="toggleShowSeries(seriesGroup.value)">
                <mat-button-toggle value='none'>
                  しない
                </mat-button-toggle>
                <mat-button-toggle value='cardset'>
                  セット毎
                </mat-button-toggle>
                <mat-button-toggle value='rarity'>
                  レアリティ毎
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

          </mat-toolbar-row>
        </mat-toolbar>

        <div class="swiper-container product-list">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <app-grid-images [game]="game" [items]="items" [category]="category" [headerArrayList]="headerArrayList"
                [isHiddenSoldout]="inStock" [groupType]="groupType" [is_purchase]="is_purchase"
                (scrolledList)="onScrollList()">
              </app-grid-images>
            </div>
          </div>

          <div class="paging-loader" *ngIf="isLoading">
          </div>
          <div fxLayoutAlign='end' class="result_count" *ngIf="pagenate.count>0">
            検索結果:{{pagenate.count}}件</div>
          <div fxLayoutAlign='center' *ngIf="pagenate.pageCount>pagenate.page&&limitPage>=pagenate.page">
            <button (click)='onScrollList()'>
              もっと表示する
            </button>
          </div>
          <div fxLayoutAlign='center' *ngIf="limitPage<pagenate.page">
            <div class="limit-page-message">
              ※表示上限の{{limitPage*pagenate.limit}}件に達しました。
              検索条件を設定してください。
            </div>

          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>




  </ng-container>
</div>
