<section class="gray_bgmenu shopinfo">
  <div class="shopinfo_wrap f_wrap">
    <div class="f_item">
      <div class="shopinfoBanner">
        <a>
          <img src="/{{localeId}}/assets/images/footer_banner.png"
            alt="クレジットカード・代引き・決済対応。午前中までの注文で即日発送。ポイントをためてお得なショッピング。" class="mb15">
        </a>
        <a>
          <img src="/{{localeId}}/assets/images/doyou_banner.jpg" alt="カードゲーム通販ビッグウェブ土曜日発送始めます!!"
            title="カードゲーム通販ビッグウェブ 土曜日発送始めます!!">
        </a>
        <a>
          <img src="/{{localeId}}/assets/images/paidy.png" alt="Paidy後払い(コンビニ/銀行)"
            title="Paidy後払いなら、欲しいものを今すぐ買えて、支払いはあとで。事前登録やクレジットカードは必要なく、携帯電話番号とメールアドレスのみで決済いただけます。">
        </a>
        <a>
          <img src="/{{localeId}}/assets/images/3Dsecure-creditcard-logo-bggray.png" alt="3Dセキュア2.0対応カードブランド">
        </a>
        <!--<a>
          <img src="/{{localeId}}/assets/images/amazon-pay_banner_A.png" alt="Amazonギフト券がAmazonPayのお支払いでご利用いただけます">
        </a>-->
        <a href="https://apay-up-banner.com?merchantId=A1XUBY1CWFSY1H&banner=20_Amazon_Pay_BBP_240x76.png&locale=ja_JP&utm_source=A1XUBY1CWFSY1H" target="_blank">
          <img style="width: 260px;" src="https://apay-up-banner.com/banner/20_Amazon_Pay_BBP_240x76.png?merchantId=A1XUBY1CWFSY1H&locale=ja_JP&width=260&height=40&utm_source=A1XUBY1CWFSY1H">
        </a>
      </div>
    </div>
    <div class="f_item">
      <div class="shopinfoBlock delivery">
      <h5 class="shopinfo_title">■発送・お届けについて</h5>
      <p>宅配便は午前中にご注文の場合は当日発送いたしますので、<br>一部地域を除いては翌日～2日前後に到着予定となります。※土日祝除く</p>
      <p>ネコポス、ゆうパケットは通常発送日から2～4日でポストに投函されます<br>※交通状況や天候により配送に遅れが生じる場合がございます。ご了承下さい。</p>
      <p><a href="https://mtg.bigweb.co.jp/informations/orderconfirmation">&gt;発送・お届け詳細はこちら</a></p>
      </div>
      <div class="shopinfoBlock payment">
      <h5 class="shopinfo_title">■お支払いについて</h5>
      <p>代金引換 / クレジットカード払い / 銀行振込 / Paidy後払い(コンビニ/銀行) / AmazonPay</p>
      <p><a href="https://mtg.bigweb.co.jp/informations/payment">&gt;お支払いについて詳細はこちら</a></p>
      </div>
      </div>
    <div class="f_item">
      <div class="shopinfoBlock shopinfoCalender">
        <h5 class="shopinfo_title" i18n>■営業日カレンダー</h5>
        <p i18n>営業時間　月-土 10:00〜17:00(祝祭日を除く)</p>
        <iframe class="calender-iframe" src="https://info.bigweb.co.jp/ver2/calender_wide.php"></iframe>
      </div>
      <div class="shopinfoBlock shopinfoContact">
        <h5 class="shopinfo_title" i18n>■お問い合わせ</h5>
        <p>e-mail : <a href="mailto:support@big-web.tv">support@big-web.tv</a></p>
        <p>e-mail（買取）: <a href="mailto:kaitori@big-web.tv">kaitori@big-web.tv</a></p>
      </div>
    </div>
  </div>
</section>

<div class="footer_flex">
  <footer class="footer">
    <div class="scrollup_wrap">
      <div id="scrollup" class="hidden">
        <a href=""></a>
      </div>
    </div>
    <div class="footer_wrap clearfix">
      <div class="logo">
        <a href=""><img src="assets/images/logo.png"></a>
      </div>
      <div class="sitemap f_wrap">
        <div class="f_menu">
          <div class="sitemap_title"><span>HOME</span></div>
          <ul class="sitemap_menu">
            <li><a href="products"><i class="fa fa-chevron-circle-right" aria-hidden="true"></i>ショップ</a></li>
            <li><a href="purchase"><i class="fa fa-chevron-circle-right" aria-hidden="true"></i>買い取り</a></li>
            <li><a href="https://mtg.bigweb.co.jp/shop" target="mtg"><i class="fa fa-chevron-circle-right"
                  aria-hidden="true"></i>店舗情報</a></li>
          </ul>
        </div>
        <div class="f_menu">
          <div class="sitemap_title"><span>買い取り</span></div>
          <ul class="sitemap_menu">
            <li><a href="https://mtg.bigweb.co.jp/informations/bulk-purchase" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>一括買取手順</a>
            </li>
            <li><a href="https://mtg.bigweb.co.jp/informations/web-purchase" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>WEB買取手順</a>
            </li>
          </ul>
        </div>
        <div class="f_menu">
          <div class="sitemap_title"><span>店舗情報</span></div>
          <ul class="sitemap_menu">
            <li><a href="https://mtg.bigweb.co.jp/shop/akihabara" target="mtg"><i class="fa fa-chevron-circle-right"
                  aria-hidden="true"></i>東京秋葉原店</a></li>
            <li><a href="https://mtg.bigweb.co.jp/shop/ikebukuro" target="mtg"><i class="fa fa-chevron-circle-right"
                  aria-hidden="true"></i>池袋店</a></li>
            <li><a href="https://mtg.bigweb.co.jp/shop/nagoya" target="mtg"><i class="fa fa-chevron-circle-right"
                  aria-hidden="true"></i>名古屋店</a></li>
            <li><a href="https://mtg.bigweb.co.jp/shop/namba" target="mtg"><i class="fa fa-chevron-circle-right"
                  aria-hidden="true"></i>大阪なんば店</a></li>
            <li><a href="https://mtg.bigweb.co.jp/shop/recruit" target="mtg"><i class="fa fa-chevron-circle-right"
                  aria-hidden="true"></i>スタッフ募集ページ</a>
            </li>
          </ul>
        </div>
        <div class="f_menu">
          <div class="sitemap_title"><span>ご利用について</span></div>
          <ul class="sitemap_menu">
            <li><a href="https://mtg.bigweb.co.jp/informations/orderguide" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>ご注文手順</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/postage" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>送料・手数料</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/orderconfirmation" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>ご注文・配送状況の確認</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/reservations" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>予約商品の購入</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/cancel" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>キャンセルと返品・交換</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/pointback" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>ポイントバックについて</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/payment" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>お支払方法</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/cardconditions_mtg" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>MTGカードの状態について</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/cardconditions" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>国産カードの状態について</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/notice-sharedcards" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>店舗との共有商品について</a></li>
            <li><a href="https://mtg.bigweb.co.jp/informations/stock-info" target="mtg"><i
                  class="fa fa-chevron-circle-right" aria-hidden="true"></i>在庫について</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="f_wrap footer_wrap_bottom">
        <div class="f_link">
          <a href="https://mtg.bigweb.co.jp/informations/terms" target="mtg">利用規約</a>
        </div>
        <div class="f_link">
          <a href="https://mtg.bigweb.co.jp/informations/privacy" target="mtg">個人情報保護方針</a>
        </div>
        <div class="f_link">
          <a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp" target="mtg">お客様情報の登録と変更</a>
        </div>
        <div class="f_link">
          <a href="https://mtg.bigweb.co.jp/informations/company" target="mtg">特定商取法に基づく表示、会社概要</a>
        </div>
        <div class="f_link">
          <a href="https://mtg.bigweb.co.jp/informations/contact" target="mtg">お問い合わせ</a>
        </div>
      </div>
    </div>
  </footer>
</div>
