import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orderconfirmation',
  templateUrl: './orderconfirmation.component.html',
  styleUrls: ['./orderconfirmation.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class OrderconfirmationComponent implements OnInit {

  title: string = 'ご注文・発送状況の確認';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: 'ご注文・発送状況の確認', url: '/guide/orderconfirmation'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
