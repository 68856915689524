import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardconditions',
  templateUrl: './cardconditions.component.html',
  styleUrls: ['./cardconditions.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class CardconditionsComponent implements OnInit {

  title: string = '国産カードの状態について';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: '国産カードの状態について', url: '/guide/cardconditions'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
