import { Component, OnInit, Input } from '@angular/core';
import { GamesService, LoginService, SettingsService } from '../../_services';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
    @Input() is_purchase: boolean;
    image_url: string = environment.image_url;
    games: any;
    url: string;
    urlPath: any;
    localeId: string;
    loginUser: any;
    constructor(
        public dialogRef: MatDialogRef<SideMenuComponent>,

        public loginService: LoginService,
        public gamesService: GamesService,
        public settingsService: SettingsService,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private router: Router,
    ) {
        this.localeId = this.settingsService.getLocale();
        this.matIconRegistry.addSvgIcon(
            'highlight-off',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/highlight_off-24px.svg'));
    }

    ngOnInit(): void {
        let me = this;
        me.games = environment.games;

        me.urlPath = me.router.url;
        if (me.urlPath.match(/purchase/)) {
            me.is_purchase = true;
        } else {
            me.is_purchase = false;
        }

        if (me.is_purchase) {
            me.url = 'purchase';
        } else {
            me.url = 'products';
        }

        me.loginUser = me.loginService.getLoginUser();
    }


    logout() {
        if (confirm('ログアウトします。よろしいですか？')) {
            this.loginService.logout();
            this.closeDialog()
        }
    }

    menuOpened() {
        let me = this;
    }

    closeDialog() {
        let me = this;
        me.dialogRef.close();
    }
}
