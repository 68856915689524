<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="container company">
    <section class="contentBox">
      <h1 class="headingUnder01 company-title">特定商取法に基づく表示、会社概要</h1>
      <section class="guide-content companyTable">
        <dl><dt class="company-items">販売者氏名</dt><dd>株式会社ホビーズファクトリー</dd></dl>
        <dl><dt class="company-items">所在地</dt><dd>大阪市中央区博労町3-3-9 6F</dd></dl>
        <dl><dt class="company-items">設立年月日</dt><dd>1999年9月2日</dd></dl>
        <dl><dt class="company-items">資本金</dt><dd>1000万円</dd></dl>
        <dl><dt class="company-items">電話番号</dt><dd>06-6245-7767</dd></dl>
        <dl><dt class="company-items">免許･資格</dt><dd>古物商許可証 大阪府公安委員会 古物商第621151303693号</dd></dl>
        <dl><dt class="company-items">販売価格</dt><dd>各商品ごとに掲載</dd></dl>
        <dl><dt class="company-items">販売価格</dt><dd>各商品ごとに掲載</dd></dl>
        <dl><dt class="company-items">送料</dt><dd>全国一律150円～</dd></dl>
        <dl><dt class="company-items">代金の支払時期及び方法</dt><dd>代金引換の場合、商品到着時にお支払いください。振込の場合、一週間を期限とさせていただいております。</dd></dl>
        <dl><dt class="company-items">商品代金以外の必要料金</dt><dd>消費税、送料（上記送料による）</dd></dl>
        <dl><dt class="company-items">申込の有効期限</dt><dd>ご注文をされてから７日以内とさせて頂きます。</dd></dl>
        <dl><dt class="company-items">引き渡し時期</dt><dd>ご注文後、通常2日～6日程度かかります。</dd></dl>
        <dl><dt class="company-items">返品特約</dt><dd>商品違い、数量違い、不良品については、交換または代金を返済いたします。<br>お客様都合の返品はご容赦ください。</dd></dl>
        <dl><dt class="company-items">不良品の取り扱い</dt><dd>代替品の発送、もしくは全額返金</dd></dl>
        <dl><dt class="company-items">返品期限</dt><dd>商品到着日より７日間</dd></dl>
        <dl><dt class="company-items">メール</dt><dd><a href="mailto:support@big-web.tv">support@big-web.tv</a></dd></dl>
      </section>
    </section>
</div>
  