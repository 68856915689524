import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItemsService } from '../../../_services';
import { TypeConstant, TypeGenreConstant } from '../../../../appConstant';

@Component({
    selector: 'app-grid-images',
    templateUrl: './grid-images.component.html',
    styleUrls: ['./grid-images.component.scss']
})
export class GridImagesComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;
    TypeGenreConstant: typeof TypeGenreConstant = TypeGenreConstant;

    @Input() game: any;
    @Input() items: any;

    @Output() addToCartItems: EventEmitter<any> = new EventEmitter();
    @Output() scrolledList: EventEmitter<any> = new EventEmitter();

    @Input() is_purchase: boolean;
    @Input() headerArrayList: any;
    @Input() isHiddenSoldout: boolean;
    @Input() groupType: string;
    @Input() category: string = 'list';

    constructor(
        public itemsService: ItemsService
    ) {

    }

    ngOnInit(): void {
        let me = this;

        new Promise<void>((resolve) => {
            resolve();
        }).then(() => {
            this.createSwiper();
        });
    }
    onScroll() {
        let me = this;
        me.scrolledList.emit();
    }

    runAddToCartItems(items: any) {
        this.addToCartItems.emit(items);
    }

    createSwiper() {



    }
}
