import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { exit } from 'process';
import { StoresService, } from '../../../_services';

import { environment } from "../../../../environments/environment";

@Component({
    selector: 'app-cart-view',
    templateUrl: './cart-view.component.html',
    styleUrls: ['./cart-view.component.scss']
})
export class PurchaseCartViewComponent implements OnInit {

    cartItems: any;
    item: any;
    currentGameId: any;
    headerArrayList: any;

    constructor(
        public dialogRef: MatDialogRef<PurchaseCartViewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public storesService: StoresService,
    ) {

    }

    ngOnInit(): void {
        let me = this;

        me.cartItems = me.data.items;
        me.cartItems.sort(function (a, b) {
            if (!a || !b) return 1;
            if (a.game_id < b.game_id) return -1;
            if (a.game_id > b.game_id) return 1;
            return 0;
        });


        me.headerArrayList = this.createHeaderArrayList();
    }

    goRegister() {
        let me = this;
        let additem = me.cartItems.map(item => {
            if (item.quantity > 0) {
                return item.id
            }
            return;
        });
        let num = me.cartItems.map(item => {
            if (item.quantity > 0) {
                return item.quantity
            }
            return;
        });
        let params = 'additem[]=' + additem.join(',') + '&num[]=' + num.join(',');
        let url = environment.parchase_cart_url + '&' + params;

        me.clearCart();
        window.open(url, '_blank');
        window.location.reload();
    }

    closeDialog() {
        let me = this;

        me.dialogRef.close();
    }

    runAddToCartItems(item: any) {

    }

    getTotalAmount() {
        if (this.cartItems) {
            return this.cartItems.reduce((prev, current) => { return prev + (current.quantity * current.price) }, 0);
        } else {
            return 0;
        }

    }

    onRemoveItem(targetItem) {
        this.cartItems = this.cartItems.filter(item => {
            if (item.id != targetItem.id) return item;
        });
        this.storesService.setPurchaseCartItems(this.cartItems);
    }

    closeCart() {
        this.dialogRef.close();
    }

    clearCart() {
        for (var cartItem of this.cartItems) {
            cartItem.quantity = 0;
            cartItem.temp_quantity = 0;
        }
        this.storesService.setCartItems(this.cartItems);
        this.cartItems = [];
        this.storesService.onResetPurchaseCart();
        this.dialogRef.close([]);
    }

    resetCart() {
        if (confirm('カートから全ての商品を削除しますが、よろしいですか？')) {
            this.clearCart();
        }
    }

    onAddItem(item) {
        let me = this;
        this.storesService.updatePurchaseCartItem(this.cartItems);
    }

    createHeaderArrayList() {
        let me = this;
        var headerArrayList = [];

        me.cartItems = me.data.items;
        me.currentGameId = null;
        for (var cartItem of me.cartItems) {
            if (me.currentGameId !== cartItem['game_id']) {
                headerArrayList.push(cartItem['id']);

                me.currentGameId = cartItem['game_id'];
            }
        }
        return headerArrayList;
    }

}
