import {Component, OnInit, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {StoresService} from '../../_services';
import {CartViewComponent} from '../cart/cart-view/cart-view.component';
@Component({
    selector: 'app-fab-menu',
    templateUrl: './fab-menu.component.html',
    styleUrls: ['./fab-menu.component.scss'],
    animations: [
        trigger('showScrollTopButton', [
            state('true', style({
                opacity: '0.7'
            })),
            state('false', style({
                opacity: '0'
            })),
            transition('true <=> false', [
                animate('200ms')
            ])
        ]),
        trigger('showCart', [
            state('true', style({
                height: 'auto',
                opacity: '1'
            })),
            state('false', style({
                height: '0',
                opacity: '0'
            })),
            transition('true <=> false', [
                animate('200ms')
            ])
        ]),
    ],
})
export class FabMenuComponent implements OnInit {
    @Input() is_purchase: boolean;
    isShowScrollButton = false;
    isShowCart = false;
    scrollingtimeoutId;
    isScrolling = false;
    lastScrollTop = 0;
    cartCount: number = 0;
    tempCartCount: number = 0;
    is_expand: boolean = true;
    cartItems: any[] = [];
    private subscription: Subscription;
    private tempCartSubscription: Subscription;
    private cartOpenSubscriotion: Subscription;
    private cartItemSubscription: Subscription;
    @HostListener("window:scroll", []) onWindowScroll() {
        let me = this;
        me.isShowScrollButton = false;

        if (me.is_expand && (window.scrollY > 0 && this.lastScrollTop != window.scrollY)) {
            me.isScrolling = true;
            me.lastScrollTop = window.scrollY;
        }
        clearTimeout(me.scrollingtimeoutId);
        me.scrollingtimeoutId = setTimeout(function () {
            if (window.scrollY > 100) {
                me.isShowScrollButton = true;
            }
            me.isScrolling = false;
        }, 100);
    };


    constructor(
        public storesService: StoresService,
        public dialog: MatDialog
    ) {

    }

    ngOnInit() {

        let me = this;
        me.subscription = this.storesService.$cart.subscribe(
            items => {
                if (items) {
                    me.concatItems(items);
                } else {
                    me.removeCartItems();
                }
            }
        );

        me.tempCartSubscription = me.storesService.$tempCart.subscribe(
            items => {
                me.concatItems(items);
            }
        );

        me.cartOpenSubscriotion = me.storesService.$cartOpen.subscribe(
            items => {
                this.openCart();
            }
        );
        me.cartItemSubscription = me.storesService.$cartItem.subscribe(
            item => {
                me.concatItems(item);
            }
        );

    }

    ngOnDestroy() {
        let me = this;
        if (me.subscription) {
            me.subscription.unsubscribe();
        }
        if (me.tempCartSubscription) {
            me.tempCartSubscription.unsubscribe();
        }
        if (me.cartOpenSubscriotion) {
            me.cartOpenSubscriotion.unsubscribe();
        }
    }

    openCart() {
        let me = this;
        if (me.is_expand) {

            const dialogRef = this.dialog.open(CartViewComponent, {
                height: '90%',
                width: '90%',
                disableClose: false,
                data: {items: me.cartItems}
            });

            dialogRef.afterClosed().subscribe(items => {
                if (items) {
                    this.cartItems = items
                    me.concatItems(this.cartItems);
                }
            });
        } else {
            this.isShowCart = true;
        }
    }

    closeCart() {
        this.isShowCart = false;
    }


    expandCart(is_expand: boolean) {

        this.is_expand = !is_expand;
    }

    scrollTop() {
        window.scroll(0, 0);
    }


    ngAfterViewInit() {
        let me = this;
        let cartItems = sessionStorage.getItem('cartItems');
        if (cartItems) {
            me.cartItems = JSON.parse(cartItems);
            setTimeout(function () {
                me.storesService.addCartItems(me.cartItems);
            }, 100);

        } else {
            me.cartItems = [];
            me.cartCount = 0;
            sessionStorage.removeItem('cartItems');
        }
    }

    removeCartItems() {
        this.cartItems = [];
    }



    concatItems(items) {
        let me = this;
        //  追加されたitem.idを取得

        if (items) {
            let itemsId = items.map(item => {
                if (item) return item.id
            });
            //  該当のitemを一旦除外
            this.cartItems = this.cartItems.filter(item => {
                if (itemsId.indexOf(item.id) < 0 && item.quantity > 0) return item;
            });

            //  undefinedの場合itemsで上書き
            //  それ以外の場合、itemsとマージ
            if (this.cartItems[0] == undefined) {
                this.cartItems = items;
            } else {
                this.cartItems = this.cartItems.concat(items);
            }

            this.cartCount = this.cartItems.reduce((prev, current) => {
                if (current) {
                    return prev + current['quantity']
                }
            }, 0);
            this.storesService.setCartCount(this.cartCount);
            sessionStorage.setItem('cartItems', JSON.stringify(this.cartItems));
        }
    }




}
