<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="shop-purchase-body">
    <h1 class="title">BIG MAGIC 店舗買取の流れ</h1>
    <h2><img src="https://mtg.bigmagic.net/article/about/kaitoristep-top001.jpg" alt="BIG MAGIC 店舗買取の流れ" border="0" /></h2>
    <p>　</p>
    <hr>
    <h4><strong>1.お申込み</strong></h4>
    <hr>
    <p>・店舗内の買取カウンターにて受付をいたします。店舗スタッフへお申し付けください。<br><br>
        ・買取を希望されるお品物をご提示ください。<br><br>
        ・お品物の量によっては、営業時間の関係で受付をお断りする場合がございます。<br><br>
        ・店舗によってはその日の買取受付を停止、終了している場合がございます。予めお電話やTwitterでの告知などでご確認ください。</p>
    <br>
    <h5><strong>店舗一覧</strong></h5>
    <p>・BIG MAGIC秋葉原店→ <a href="https://mtg.bigmagic.net/akihabara.html"><strong>https://mtg.bigmagic.net/akihabara.html</strong></a><br><br>
        ・BIG MAGIC池袋店→ <a href="https://mtg.bigmagic.net/ikebukuro.html"><strong>https://mtg.bigmagic.net/ikebukuro.html</strong></a><br><br>
        ・BIG MAGIC名古屋店→ <a href="https://mtg.bigmagic.net/nagoya.html"><strong>https://mtg.bigmagic.net/nagoya.html</strong></a><br><br>
        ・BIG MAGIC名古屋ZERO店→ <a href="https://mtg.bigmagic.net/zero.html"><strong>https://mtg.bigmagic.net/zero.html</strong></a><br><br>
        ・BIG MAGICなんば店→ <a href="https://mtg.bigmagic.net/nanba.html"><strong>https://mtg.bigmagic.net/nanba.html</strong></a></p>
    <p>　</p>
    <hr>
    <h4><strong>2.ご本人確認</strong></h4>
    <hr>
    <p>・<a href="kaitoristep001">身分を証明するもの(免許証、各種保険証、パスポートなど住所の記載のあるもの)</a>をご提示ください。<br><br>
        ・18歳未満の方は、<a href="kaitoristep002">保護者様の承諾書</a>が別途必要となります。また承諾の確認の為、電話でのご確認もお願いします。<br><br>
        ・小学生以下のお客様は保護者の方の同伴が必要です。ご来店の際には保護者の方の身分証も合わせてご持参ください。</p>
    <p>　</p>
    <hr>
    <h4><strong>3.買取査定</strong></h4>
    <hr>
    <p>・買取査定終了まで店外でお待ちいただくことも可能です。受付の際にご連絡先とその旨を担当スタッフにお伝えください。査定終了後にご連絡させていただきます。<br><br>
        ・買取の量に応じて他のお客様の買取査定とご案内が前後する場合がございます。<br><br>
        ・買取価格はカードの状態・入荷状況・環境変更などに応じて随時変更が行われており、買取表などの価格から変更される場合がございます。<br><br>
        ・買取価格は各店舗や通販部での独自の価格となっております。</p>
    <p>　</p>
    <hr>
    <h4><strong>4.査定結果のご案内とお支払い</strong></h4>
    <hr>
    <p>・スタッフより査定結果をお伝えいたします。金額にご了承いただけましたら、買取票への署名をお願いいたします。</p>
    <br>
    <hr>
    <h4>◆使える身分証明書</h4>
    <hr>
    <h5>前提として「氏名・住所・年齢」</h5>
    <a id="kaitoristep001" name="kaitoristep001"></a>
    <p><strong>番号を控える身分証</strong><br>
        運転免許証<br>
        学生証（学校法人のもの）<br>
        各種福祉手帳<br>
        パスポート</p>

    <p><strong>その他、官公庁発行の身分証明証</strong><br>
        在留カード</p>

    <p><strong>カードをコピーする身分証</strong><br>
        住民基本台帳カード<br>
        マイナンバーカード（通知カードは不可）</p>
    <br>
    <hr>
    <a id="kaitoristep002" name="kaitoristep002"></a>
    <h4>◆買取承諾書 ダウンロード</h4>
    <hr>
    <div><a href="assets/images/purchase/purchase-file.pdf"><img src="https://mtg.bigmagic.net/article/about/kaitoristep-botan.png" alt="ダウンロード"></a></div>
    <p>クリックしてPDFファイルをダウンロード、印刷して必要事項をご記入ください。</p>
    <br>
    <hr>
    <h4>◆個人情報の取り扱いについての案内</h4>
    <hr>
    <p>～本承諾書に関する個人情報の取り扱いについて～<br><br>
        1.本承諾書は法令に基づいてお客様にご記入いただくものであり、ここに記載された内容をそれ以外の目的で使用することはありません。<br><br>
        2.ご記入いただいた個人情報は、厳正な管理の下で安全に蓄積・保管いたします。<br><br>
        3.ご記入いただいた個人情報は、法令に基づいて個人情報の提供を求められた場合を除き、第三者に提供することはありません。</p>
    <p>　</p>
    <p><a href="/shop"><strong>BIG MAGIC店舗一覧ページ</strong></a></p>
</div>