<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="col-xs-12 individual-shop-info">
    <div class="entry_article_wrap">
        <article class="entry_article">
            <div class="entry_txt">
                <div class="shop-content">
                    <h1 class="shop-title">BIG MAGIC なんば店</h1>
                    <div class="shop-content-label">
                        <h4><strong>店舗情報</strong></h4>
                    </div>
                    <h4><strong><a href="https://mtg.bigweb.co.jp/shop/recruit">採用情報</a> / <a href="bmnanba-kessai">お支払い一覧</a> / <a href="bmnanba-sch">店舗大会スケジュール</a></strong></h4>
                    <p>■住所<br>
                        〒556-0011 大阪府大阪市浪速区難波中2-4-15 興伸ビル1F/2F</p>
                    <p>■TEL<br>
                        06-6633-7338</p>
                    <p>■営業時間<br>
                        月～金 12:00～21:00 / 土日 11:00～21:00</p>
                    <p><strong><span class="shop-information">※新型コロナウイルスの影響により、現在BIG MAGIC各店の営業時間に変更がある場合がございます。詳細は各店にてお問い合わせ下さい。</span></strong></p>
                    <p><a href="https://twitter.com/BM_NAMBA">BIG MAGICなんば店 Twitter</a></p>
                    <p>■取り扱い商品<br>
                        1F：遊戯王・ポケモンカードゲーム・ヴァイスシュバルツ・デュエルマスターズ・キャラクターサプライ<br>
                        2F：MTG・Z/X・WIXOSS・バトルスピリッツ・デジモンカードゲーム・Reバース</p>
                    <p><a href="https://bigmagic.diarynote.jp/">BIG MAGICなんば店 DiaryNote</a></p>
                    <p><a href="https://twitter.com/BM_NAMBA">BIG MAGICなんば店 Twitter</a></p>
                    <p>　</p>
                    <div class="embed-container"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13126.724510891747!2d135.5042158!3d34.6627551!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc596ac3c1fbab639!2zQklHTUFHSUPjgarjgpPjgbDlupc!5e0!3m2!1sja!2sjp!4v1547888169437" width="600" height="450" frameborder="0" allowfullscreen=""></iframe></div><br>
                    <div id="bmtp-nanba">&nbsp;</div><p>&nbsp;</p>
                </div>
                <div align="center"><h4 class="bmtp-title"><strong>BMTP交換賞品一覧表</strong></h4><img class="shop-info-image" src="https://mtg.bigmagic.net/article/about/bmtp-nmb002.jpg" alt="BMTP なんば店交換賞品一覧表" border="0"></div>
                <div class="shop-content">
                    <p>内容は予告無く変更になる場合がございますのでご了承ください。</p>
                    <p>　</p>
                    <a id="bmnanba-kessai" name="bmnanba-kessai"></a>
                    <div class="shop-content-label">
                        <h4><strong>お支払い一覧</strong></h4>
                    </div>
                    <div align="center"><img class="shop-info-image" src="https://mtg.bigmagic.net/article/about/BIGMAGIC-kessai-nanba2.jpg" alt="なんば店お支払い一覧" border="0" ></div>
                    <p>　</p>
                    <a id="bmnanba-sch" name="bmnanba-sch"></a>
                    <div class="shop-content-label">
                        <h4><strong>店舗大会スケジュール</strong></h4>
                    </div>
                    <p><iframe width="100%" height="400" src="https://www.google.com/calendar/embed?mode=AGENDA&amp;showTitle=0&amp;src=bigmagic.mtg%40gmail.com&amp;ctz=Asia/Tokyo" frameborder="0" scrolling="no"></iframe></p>
                    <p>イベントをクリックすると、イベント内容をご覧頂けます。イベントの日時は予告なく変更する場合がございます。詳細はイベント開催店舗の方にお問い合わせ下さい。</p>
                    <p>シングルカード高価買取中!! / デュエルスペース完備!!</p>
                    <p>BIG MONDAY ポイント3倍DAY!!（毎週月曜日はポイント3倍の日）※一部対象外の商品がございます。</p>
                    <p>　</p>
                    <p><a href="https://mtg.bigweb.co.jp/shop">BIG MAGIC店舗一覧ページに戻る</a></p>
                    <p><a href="https://mtg.bigweb.co.jp/shop/recruit">BIG MAGIC スタッフ募集ページ</a></p>
                </div>
            </div>
        </article>
    </div>
</div>