<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under cardconditions">
    <h1 class="headingUnder01">カードの状態について</h1>
    <p>Bigwebで販売しているシングルカードの状態は、こちらの3種類です。</p>
    <section class="guide-content">
        <h2 class="headingUnder02">【ニアミント】</h2>
        <p>・パックから開封した物は基本的にこちらに分類されますが初期仕様を含む軽い傷、擦れ、汚れ、白欠け、爪跡などもこちらに分類されます。<br>
           ・特別な表記が無い場合当店のニアミントはNM+〜EXを含みます。
        </p>
        <figure>
            <figcaption>
                <ul class="cardimgkizu">
                    <li>
                        <a href="assets/images/card-conditions-mtg/001.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/001-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/002.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/002-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/003.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/003-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/004.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/004-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/005.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/005-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/006.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/006-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/007.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/007-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/024.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/024-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/030.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/030-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/031.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/031-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/032.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/032-small.jpg" alt="">
                        </a>
                    </li>
                </ul>
            </figcaption>
        </figure>
        <p class="small">※画像はあくまで参考です。カードの傷の具合や多さなどによって、状態表記を変更する場合があります。</p>
    </section>
    <section class="guide-content">
        <h2 class="headingUnder02">【プレイド】</h2>
        <p>・目立った傷、擦れ、汚れ、へこみ、白欠け、爪跡などがある状態。<br>
           ・不透明なスリーブに入れればゲームでの使用には差支え無い状態。
        </p>
        <figure>
            <figcaption>
                <ul class="cardimgkizu">
                    <li>
                        <a href="assets/images/card-conditions-mtg/008.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/008-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/009.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/009-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/010.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/010-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/011.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/011-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/012.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/012-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/013.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/013-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/014.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/014-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/015.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/015-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/016.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/016-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/017.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/017-small.jpg" alt="">
                        </a>
                    </li>
                </ul>
            </figcaption>
        </figure>
    </section>
    <section class="guide-content">
        <h2 class="headingUnder02">【ヘビープレイド】</h2>
        <p>傷、汚れが目立つ状態。折れやインクドなどもこちらに含まれます。</p>
        <figure>
            <figcaption>
                <ul class="cardimgkizu">
                    <li>
                        <a href="assets/images/card-conditions-mtg/018.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/018-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/019.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/019-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/020.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/020-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/021.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/021-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/022.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/022-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/023.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/023-small.jpg" alt="">
                        </a>
                    </li>
                </ul>
            </figcaption>
        </figure>
    </section>
    <hr>
    <section class="guide-content">
        <h2 class="headingUnder02">【状態の補足】</h2>
        <p>・+：表記している状態表現よりも状態が良いが、ランク1つ上の状態に至るほどではない。（ランク1つ上の状態の「−」よりは劣る）<br>
           ・−：表記している状態表現よりも状態が悪いが、ランク1つ下の状態に至るほどではない。（ランク1つ下の状態の「+」よりは勝る）<br>
           ・インクド：ペンやマジック等で修正した跡があります。<br>
           ・サインド：アーティストやプレイヤー等のサインが書いてあります。<br>
           ・スタンプド:リミテッドの競技イベントなどでカードに押されるスタンプの跡があります
        </p>
    </section>
    <hr>
    <section class="guide-content">
        <h2 class="headingUnder02">【センタリングについて】</h2>
        <p>・センタリングは状態のランクには加味されません。<br>
           ・特に表記の無い場合写真のようなルール上問題無いレベルの印刷ズレが含まれる場合があります。<br>
        </p>
        <figure>
            <figcaption>
                <ul class="cardimgkizu">
                    <li>
                        <a href="assets/images/card-conditions-mtg/025.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/025-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/026.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/026-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/027.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/027-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/028.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/028-small.jpg" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="assets/images/card-conditions-mtg/029.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                            <img src="assets/images/card-conditions-mtg/029-small.jpg" alt="">
                        </a>
                    </li>
                </ul>
            </figcaption>
        </figure>
    </section>
</section>