
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ItemsService, StoresService, SettingsService } from '../../_services';

@Component({
    selector: 'app-image-view',
    templateUrl: './image-view.component.html',
    styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {
    is_purchase: boolean
    item: any;
    localeId: string;

    @Input() category: string = 'list';
    path_str: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ImageViewComponent>,
        public itemsService: ItemsService,
        public storesService: StoresService,
        public settingsService: SettingsService,
        private router: Router,
    ) {
        this.localeId = this.settingsService.getLocale();
    }

    ngOnInit(): void {
        let me = this;
        me.item = me.data.item;
        me.is_purchase = me.data.is_purchase;

        let regx = /(jp(e)?g|png|gif)$/i;
        me.item.webp = me.item.image.replace(regx, 'webp');
        // シングルカードのみ対応
        me.item.is_single = false;
        if (!me.item.is_supply && !me.item.is_box) {
            // Lに差し替え (404は.htaccessで転送)
            me.item.imageL = me.item.image.replace(/(.+)(?!_L)\.(jpe?g|gif|png)/, '$1_L.$2');
            me.item.webpL = me.item.imageL.replace(regx, 'webp');
            me.item.is_single = true;
        }
    }

    closeDialog() {
        let me = this;
        me.dialogRef.close();
    }
}
