import { Component, OnInit } from '@angular/core';
import {SettingsService} from '../../../_services';

@Component({
  selector: 'app-what-is-bigwebman',
  templateUrl: './what-is-bigwebman.component.html',
  styleUrls: ['./what-is-bigwebman.component.scss']
})
export class WhatIsBigwebmanComponent implements OnInit {

    localeId: string;
    title: string = 'Bigwebマン配達員・マスコット「Bigwebマン」とは？';
    is_purchase: boolean = false;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: '「Bigwebマン」とは？', url: '/guide/what-is-bigwebman'}
    ];
    constructor(
        public settingsService: SettingsService,
    ) {
        let me = this;
        this.localeId = this.settingsService.getLocale();
    }
    ngOnInit(): void {
        window.scroll(0, 0);
    }

}
