<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="entry_article_wrap requruit-info">
    <article class="entry_article">
        <div class="entry_txt">
            <table border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td valign="middle" height="30">
                            <div><img src="https://mtg.bigmagic.net/web-image/koumoku-recruit.jpg" alt="採用情報" width="1000" height="30"></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table border="0" cellspacing="5" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p class="recruit_text">BIGMAGIC各店舗・オンラインショップBigwebのスタッフ採用情報ページです。 業務内容や待遇等は各店舗により異なりますので、求人募集状況は下記よりご確認、または各店舗にお問い合わせ下さい。</p>
                                            <p class="recruit_text"><span class="shop-information"><strong>au携帯をお使いのお客様へ 送信先のメールアドレスを【指定受信リスト(なりすまし・転送メール許可】に登録してからご応募下さい。 こちらからの返信メールが届かない恐れがございます。</strong></span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table border="0" cellspacing="25" cellpadding="0" id="recruit_list">
                                                <tbody>
                                                    <tr class="recruit_shop_box">
                                                        <td>
                                                            <div><img src="https://mtg.bigmagic.net/recruit/img/recruit-tenpo006.jpg" alt="BIGMAGIC東京秋葉原店" width="450" height="60"></div>
                                                            <iframe width="450" height="300" src="https://mtg.bigmagic.net/recruit/akihabara_recruit.html" frameborder="0"></iframe>
                                                        </td>
                                                        <td>
                                                            <div><img src="https://mtg.bigmagic.net/recruit/img/recruit-tenpo005.jpg" alt="BIGMAGIC東京池袋店" width="450" height="60"></div>
                                                            <iframe width="450" height="300" src="https://mtg.bigmagic.net/recruit/ikebukuro_recruit.html" frameborder="0"></iframe>
                                                        </td>
                                                    </tr>
                                                    <tr class="recruit_shop_box">
                                                        <td>
                                                            <div><img src="https://mtg.bigmagic.net/recruit/img/recruit-tenpo001.jpg" alt="BIGMAGIC名古屋店" width="450" height="60"></div>
                                                            <iframe width="450" height="300" src="https://mtg.bigmagic.net/recruit/nagoya_recruit.html" frameborder="0"></iframe>
                                                        </td>
                                                        <td>
                                                            <div><img src="https://mtg.bigmagic.net/recruit/img/recruit-tenpo003.jpg" alt="BIGMAGIC名古屋ZERO店" width="450" height="60"></div>
                                                            <iframe width="450" height="300" src="https://mtg.bigmagic.net/recruit/zero_recruit.html" frameborder="0"></iframe>
                                                        </td>
                                                    </tr>
                                                    <tr class="recruit_shop_box">
                                                        <td>
                                                            <div><img src="https://mtg.bigmagic.net/recruit/img/recruit-tenpo004.jpg" alt="BIGMAGIC大阪なんば店" width="450" height="60"></div>
                                                            <iframe width="450" height="300" src="https://mtg.bigmagic.net/recruit/nanba_recruit.html" frameborder="0"></iframe>
                                                        </td>
                                                        <td>
                                                            <div><img src="https://mtg.bigmagic.net/recruit/img/recruit-tenpo007.jpg" alt="オンラインショップBigweb" width="450" height="60"></div>
                                                            <iframe width="450" height="300" src="https://mtg.bigmagic.net/recruit/bigweb_recruit.html" frameborder="0"></iframe>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div align="left">
                                <div><img src="https://mtg.bigmagic.net/web-image/line.jpg" alt="" width="1000" height="30"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table border="0" width="1000" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div align="left">
                                                <p><a href="https://mtg.bigweb.co.jp/shop">店舗トップへ戻る</a></p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </article>
</div>