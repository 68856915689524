import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pointback',
  templateUrl: './pointback.component.html',
  styleUrls: ['./pointback.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class PointbackComponent implements OnInit {

  title: string = 'ポイントバックについて';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: 'ポイントバックについて', url: '/guide/pointback'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
