import {Component, OnInit, Input} from '@angular/core';
import {GamesService, SettingsService} from '../../_services';
import {environment} from "../../../environments/environment";
@Component({
    selector: 'app-top',
    templateUrl: './top.component.html',
    styleUrls: ['./top.component.scss']
})
export class PurchaseTopComponent implements OnInit {
    title: any = 'カードゲームの高価買取なら【BIGWEB】';
    games: any = environment.games;
    escape_url: string = environment.escape_url;
    image_url: string = environment.image_url;
    is_purchase: boolean = true;
    localeId: string;
    slides: any = [];
    has_notice: boolean = true;
    constructor(
        public settingsService: SettingsService,
        public gamesService: GamesService
    ) {
        let me = this;
        me.games = gamesService.getBuyingGames();
        this.localeId = this.settingsService.getLocale();
        let description = "カードゲームの通販なら、日本最大級の品揃えのカードショップ【BIGWEB】におまかせください。"
        + "数量限定の最新弾シングルや、海外版シングル、海外版パックなど、あなたの欲しいカードが見つかります。";
        me.settingsService.setTitle(me.title, description);
    }

    ngOnInit(): void {
        let me = this;
        window.scroll(0, 0);
        me.setSlides();

    }

    setSlides() {
        let me = this;
        //let slide = localStorage.getItem('Slides');
        //if (slide) {
        //    me.slides = JSON.parse(slide);
        //} else {
            me.gamesService.loadSlides().subscribe(slides => {
                // me.slides = slides;
                for(let slide of slides) {
                    me.slides.push(slide);
                }
        //        localStorage.setItem('Slides', JSON.stringify(me.slides));
            });
        //}
    }
}
