import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ItemsService, StoresService, SettingsService } from '../../_services';

@Component({
    selector: 'app-item-view',
    templateUrl: './item-view.component.html',
    styleUrls: ['./item-view.component.scss']
})
export class ItemViewComponent implements OnInit {
    is_purchase: boolean
    item: any;
    tempCount: number = 0;
    localeId :string;

    @Input() category: string = 'list';
    path_str: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ItemViewComponent>,
        public itemsService: ItemsService,
        public storesService: StoresService,
        public settingsService: SettingsService,
        private router: Router,
    ) {
        this.localeId = this.settingsService.getLocale();
    }

    ngOnInit(): void {
        let me = this;
        me.item = me.data.item;
        me.is_purchase = me.data.is_purchase;
        me.tempCount = me.data.tempCount;
        me.addHistory(me.item);
    }

    openItemViewModal(is_purchase: boolean) {
        let me = this;
        me.dialogRef.close();
        me.is_purchase = is_purchase;
        //me.loadingService.loading();
        me.itemsService.getById(me.item.id, is_purchase).subscribe((result: any) => {
            me.item = result.items
            me.itemsService.openItemView(me.item, is_purchase);
            //me.loadingService.hideLoading();
        })

    }

    addHistory(item) {
        this.storesService.addHistory({ item: item, action: 'add' });
    }

    closeDialog() {
        let me = this;
        me.dialogRef.close();
    }

    searchSameProducts() {
        let me = this;

        //me.loadingService.loading();
        me.dialogRef.close();
        setTimeout(function () {
            me.router.navigate(['/'
                + (me.is_purchase ? 'purchase' : 'products')
                + '/' + me.item.game.code
                + '/cardViewer/'
                + me.item.id
            ]);
        }, 10);
    }

}
