<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="col-xs-12 individual-shop-info">
    <div class="entry_article_wrap">
        <article class="entry_article">
            <div class="entry_txt">
                <div class="shop-content">
                    <h1 class="shop-title">BIG MAGIC 名古屋ZERO店</h1>
                    <div class="shop-content-label">
                        <h4><strong>店舗情報</strong></h4>
                    </div>
                    <h4><strong><a href="https://mtg.bigweb.co.jp/shop/recruit">採用情報</a> / <a href="bmzero-kessai">お支払い一覧</a> / <a href="bmzero-sch">店舗大会スケジュール</a></strong></h4>
                    <p>■住所<br>
                        〒460-0011 愛知県名古屋市中区大須4-1-3 名商ビル2F・3F</p>
                    <p>■TEL<br>
                        052-265-6899</p>
                    <p>■営業時間<br>
                        <s>月～金 13:00～21:00</s> / <s>土・日 10:00～21:00</s><br>
                        平日・祝 15:00～20:00 / 土日 12:00～20:00に短縮営業中</p>
                    <p><strong><span class="shop-information">※新型コロナウイルスの影響により、現在BIG MAGIC各店の営業時間に変更がある場合がございます。詳細は各店にてお問い合わせ下さい。</span></strong></p>
                    <p><a href="https://twitter.com/bigmagiczero">BIG MAGIC名古屋ZERO店 Twitter</a></p>
                    <p>■取り扱い商品<br>
                        遊戯王・ヴァイスシュヴァルツ・ポケモンカードゲーム・WIXOSS・デジモンカードゲーム（デジカ）・ヴァンガード（パックのみ）</p>
                    <p><a href="https://twitter.com/bigmagiczero">BIG MAGIC名古屋ZERO店 Twitter</a></p>
                    <p>　</p>
                    <div class="embed-container"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1630.897500479253!2d136.906861!3d35.161733!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600370cc3c144f75%3A0x17c02bb4721d085d!2z5pel5pysLCDjgJI0NjAtMDAxMSDmhJvnn6XnnIzlkI3lj6TlsYvluILkuK3ljLrlpKfpoIjvvJTkuIHnm67vvJHiiJLvvJMg5ZCN5ZWG44OT44Or!5e0!3m2!1sja!2sus!4v1479116731316" width="600" height="450" frameborder="0" allowfullscreen=""></iframe></div><br>
                </div>
                <div class="shop-content">
                    <p>　</p>
                    <a id="bmzero-kessai" name="bmzero-kessai"></a>
                    <div class="shop-content-label">
                        <h4><strong>お支払い一覧</strong></h4>
                    </div>
                    <div align="center"><img class="shop-info-image" src="https://mtg.bigmagic.net/article/about/BIGMAGIC-kessai-zero.jpg" alt="ZERO店お支払い一覧" border="0"></div>
                    <p>　</p>
                    <div class="shop-content-label">
                        <a id="bmzero-sch" name="bmzero-sch"></a>
                        <h4><strong>店舗大会スケジュール</strong></h4>
                    </div>
                    <p><iframe width="100%" height="400" src="https://www.google.com/calendar/embed?mode=AGENDA&amp;showTitle=0&amp;src=bigmagiczero%40gmail.com&amp;ctz=Asia/Tokyo" frameborder="0" scrolling="no"></iframe></p>
                    <p>イベントをクリックすると、イベント内容をご覧頂けます。イベントの日時は予告なく変更する場合がございます。詳細はイベント開催店舗の方にお問い合わせ下さい。</p>
                    <p>シングルカード高価買取中!! / デュエルスペース完備!!</p>
                    <p>　</p>
                    <p><a href="https://mtg.bigweb.co.jp/shop">BIG MAGIC店舗一覧ページに戻る</a></p>
                    <p><a href="https://mtg.bigweb.co.jp/shop/recruit">BIG MAGICスタッフ募集ページ</a></p>
                </div></div>
        </article></div>

</div>