<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="col-xs-12 individual-shop-info">
    <div class="entry_article_wrap">
        <article class="entry_article">
            <div class="entry_txt">
                <div class="shop-content">
                    <h1 class="shop-title">BIG MAGIC 名古屋店</h1>
                    <div class="shop-content-label">
                        <h4><strong>店舗情報</strong></h4>
                    </div>
                    <h4><strong><a href="https://mtg.bigweb.co.jp/shop/recruit">採用情報</a> / <a href="bmnagoya-sch">店舗大会スケジュール</a></strong></h4>
                    <p>■住所 <br>
                        〒460-0011 愛知県名古屋市中区大須4-1-3 名商ビル2F</p>
                    <p>■TEL<br>
                        052-238-6811</p>
                    <p>■営業時間<br>
                        <s>月～金 13:00～21:00（火曜日定休）/土・日 10:00～21:00</s><br>
                        平日 16:00～20:00（火曜日定休） / 土日祝 13:00～18:00に短縮営業中</p>
                    <p><strong><span class="shop-information">※新型コロナウイルスの影響により、現在BIG MAGIC各店の営業時間に変更がある場合がございます。詳細は各店にてお問い合わせ下さい。</span></strong></p>
                    <p><a href="https://twitter.com/BIGMAGICnagoya">BIG MAGIC名古屋店 Twitter</a></p>
                    <p>■取り扱い商品<br>
                        MTG</p>
                    <p><a href="https://twitter.com/BIGMAGICnagoya">BIG MAGIC名古屋店 Twitter</a></p>
                    <p>　</p>
                    <div class="embed-container"><iframe class="shop-google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13047.171181361618!2d136.906843!3d35.161788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcc3c32c2ffc8a16d!2z5ZCN5Y-k5bGL44Kr44O844OJ44K344On44OD44OXIOODiOODrOOCq-iyqeWjsiDjgJDvvKLvvKnvvKfvvK3vvKHvvKfvvKnvvKPlkI3lj6TlsYvlupfjgJE!5e0!3m2!1sja!2sjp!4v1547887954010" frameborder="0"  allowfullscreen=""></iframe></div><br>
                    <div id="bmtp-nagoya">&nbsp;</div><p>&nbsp;</p>
                </div>
                <div align="center"><h4 class="bmtp-title"><strong>BMTP交換賞品一覧表</strong></h4><img src="https://mtg.bigmagic.net/article/about/bmtpnagoya001.jpg" alt="BMTP 名古屋店交換賞品一覧表" border="0" class="shop-info-image"><br><br><img src="https://mtg.bigmagic.net/article/about/bmtpnagoya004.jpg" alt="BMTP 名古屋店交換賞品一覧表" border="0"  class="shop-info-image"></div>
                <div class="bm-point-info">
                    <p>内容は予告無く変更になる場合がございますのでご了承ください。</p>
                    <p>　</p>
                    <div class="shop-content-label">
                        <a id="bmnagoya-sch" name="bmnagoya-sch"></a>
                        <h4><strong>店舗大会スケジュール</strong></h4>
                    </div>
                    <p><iframe width="100%" height="400" src="https://www.google.com/calendar/embed?mode=AGENDA&amp;showTitle=0&amp;src=big.magic.nagoya%40gmail.com&amp;ctz=Asia/Tokyo" frameborder="0" scrolling="no"></iframe></p>
                    <p>イベントをクリックすると、イベント内容をご覧頂けます。イベントの日時は予告なく変更する場合がございます。 詳細はイベント開催店舗の方にお問い合わせ下さい。</p>
                    <p>シングルカード高価買取中!! / デュエルスペース完備!!</p>
                    <p>　</p>
                    <p><a href="https://mtg.bigweb.co.jp/shop">BIG MAGIC店舗一覧ページに戻る</a></p>
                    <p><a href="https://mtg.bigweb.co.jp/shop/recruit">BIG MAGICスタッフ募集ページ</a></p>
                </div>
            </div>
        </article>
    </div>
</div>