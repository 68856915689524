import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardconditions-mtg',
  templateUrl: './cardconditions-mtg.component.html',
  styleUrls: ['./cardconditions-mtg.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class CardconditionsMtgComponent implements OnInit {

  title: string = 'MTGカードの状態について';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: 'MTGカードの状態について', url: '/guide/cardconditions-mtg'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
