
<div class="modal-contents">
    <div class="cart-title" fxLayout="row" >
        <span fxFlex i18n> <mat-icon >favorite</mat-icon>お気に入り</span>
        <div fxFlex="10" fxLayoutAlign="end">
            <button  mat-mini-fab  color="warn" class="modal-close-button"  (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row wrap" >
        <ng-container *ngFor="let item of items">
            <div fxFlex="158px" >
                <app-grid-itembox [item]="item" (addToCartItems)="runAddToCartItems(item)"></app-grid-itembox>
            </div>
        </ng-container>
    </div>

</div>

