import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ikebukuro',
    templateUrl: './ikebukuro.component.html',
    styleUrls: ['./ikebukuro.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class IkebukuroComponent implements OnInit {

    title: string = 'BIG MAGIC 池袋店';
    is_purchase: boolean = false;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: '店舗ページ', url: 'shop/'},
        {title: '池袋店', url: 'shop/ikebukuro'},
    ];

    constructor() {}

    ngOnInit(): void {
        window.scroll(0, 0);
    }


}
