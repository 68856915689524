<div class="toolbar" [ngClass]="is_static?'is_static':'is_kinetic'">

  <mat-toolbar>
    <mat-toolbar-row class="top_header" [class.top_header_purchace]="is_purchase" fxLayout="row"
      fxLayoutAlign="start center" color="accent">
      <div class="game-title" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">

        <ng-container *ngIf="is_purchase">
          <ng-container *ngIf="is_itemview; else h1Logo">
            <a href="purchase">
              <img class="logo-img" src="assets/images/logo.png" alt="{{game.title}}の高価買取なら|BIGWEB" />
            </a>
          </ng-container>
          <ng-template #h1Logo>
            <h1>
              <a href="purchase">
                <ng-container *ngIf="is_homeview">
                  <img class="logo-img" src="assets/images/logo.png"
                    alt="BIGWEBは日本最大級の激安カードゲーム通販専門店です。数多くのTCGタイトルを取り扱い、豊富な在庫を取り揃えております。東京、名古屋、大阪にも店舗があります。" />
                </ng-container>
                <ng-container *ngIf="is_gameview">
                  <img class="logo-img" src="assets/images/logo.png" alt="{{game.title}}の通販専門店|BIGWEB" />
                </ng-container>
              </a>
            </h1>
          </ng-template>
          <a href="purchase">
            <img class="bigwebman-img" src="assets/images/bigwebman_catch.png"
              alt="BIGWEB ビッグウェブ-BIGMAGIC通販部 日本最大級の品揃えのカードショップ！" />
          </a>
        </ng-container>

        <ng-container *ngIf="!is_purchase">
          <ng-container *ngIf="is_itemview; else h1Logo">
            <a href="products">
              <img class="logo-img" src="assets/images/logo.png" alt="{{game.title}}の通販専門店|BIGWEB" />
            </a>
          </ng-container>
          <ng-template #h1Logo>
            <h1>
              <a href="products">
                <ng-container *ngIf="is_homeview">
                  <img class="logo-img" src="assets/images/logo.png" alt="BIGWEBは日本最大級の激安カードゲーム通販専門店です。" />
                </ng-container>
                <ng-container *ngIf="is_gameview">
                  <img class="logo-img" src="assets/images/logo.png" alt="{{game.title}}の通販専門店|BIGWEB" />
                </ng-container>
              </a>
            </h1>
          </ng-template>
          <a href="products">
            <img class="bigwebman-img" src="assets/images/bigwebman_catch.png"
              alt="BIGWEB ビッグウェブ-BIGMAGIC通販部 日本最大級の品揃えのカードショップ！" />
          </a>
        </ng-container>
      </div>

      <div class="top_header_right" fxLayout="column" fxFlex="50">
        <div fxLayout="row" fxFlex fxLayoutAlign="end" fxFill>
          <div fxFlex fxLayoutAlign="end">
            <div class="toolbar-banner">
              <a href="https://apay-up-banner.com?merchantId=A1XUBY1CWFSY1H&banner=09_Amazon_Pay_BBP_320x50.png&locale=ja_JP&utm_source=A1XUBY1CWFSY1H"
                target="_blank">
                <img
                  src="https://apay-up-banner.com/banner/09_Amazon_Pay_BBP_320x50.png?merchantId=A1XUBY1CWFSY1H&locale=ja_JP&width=320&height=50&utm_source=A1XUBY1CWFSY1H"></a>
              <!-- <img style="width: 320px; vertical-align: bottom; margin-right: 0.5rem;" src="assets/images/amazon-pay_banner_A.png"
                alt="Amazonギフト券がAmazonPayのお支払いでご利用いただけます" title="Amazonギフト券がAmazonPayのお支払いでご利用いただけます" /> -->
            </div>
            <div class="toolbar-menu">
              <button mat-icon-button class="login-button" (click)="openCart()">
                <mat-icon *ngIf="cartCount==0">shopping_cart</mat-icon>
                <mat-icon *ngIf="cartCount>0" matBadge="{{cartCount}}" matBadgeColor="accent">shopping_cart</mat-icon>
                <div class="login-button-label"><span i18n>カート</span></div>
              </button>
              <button mat-icon-button class="login-button" (click)="openPurchaseCart()">
                <i class="fas fa-box-open fa-lg"></i>
                <mat-icon class="purchase-cart-btn" *ngIf="purchaseCartCount>0" matBadge="{{purchaseCartCount}}"
                  matBadgeColor="purchase"></mat-icon>
                <div class="login-button-label"><span i18n>買取カート</span></div>
              </button>
              <a mat-icon-button class="login-button" href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp"
                *ngIf="loginUser=='Denial'" target="bigweb">
                <mat-icon>person</mat-icon>
                <div class="login-button-label"><span i18n>マイページ</span></div>
              </a>
              <a mat-icon-button class="login-button" href="https://mtg.bigweb.co.jp/mypage" *ngIf="loginUser!='Denial'"
                target="bigweb">
                <mat-icon>person</mat-icon>
                <div class="login-button-label"><span i18n>マイページ</span></div>
              </a>
              <a mat-icon-button class="login-button" (click)="openHistory()" *ngIf="loginUser!='Denial'">
                <mat-icon>history</mat-icon>
                <div class="login-button-label"><span i18n>履歴</span></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar-row>

  </mat-toolbar>

</div>

<app-fab-menu [is_purchase]="is_purchase">
</app-fab-menu>
<app-fab-menu-purchase [is_purchase]="is_purchase">
</app-fab-menu-purchase>