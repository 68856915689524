<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="col-xs-12 individual-shop-info">
    <div class="entry_article_wrap">
        <article class="entry_article">
            <div class="entry_txt">
                <div class="shop-content">
                    <h1 class="shop-title">BIG MAGIC 池袋店</h1>
                    <div class="shop-content-label">
                        <h4><strong>店舗情報</strong></h4>
                    </div>
                    <h4><strong><a href="https://mtg.bigweb.co.jp/shop/recruit">採用情報</a> / <a href="bmikebukuro-kessai">お支払い一覧</a> / <a href="bmikebukuro-sch">店舗大会スケジュール</a></strong></h4>
                    <p>■住所<br>
                        〒170-0013 東京都豊島区東池袋1-22-13 近代BLD11号館6F</p>
                    <p>■TEL<br>
                        03-5944-8023 ※お電話での販売・買取価格のお問合せはご遠慮下さい。</p>
                    <p>■営業時間<br>
                        <s>平日 12:00～21:00</s> ※金曜は12:00～22:00まで / <s>土日祝 11:00～21:00</s><br>
                        月～木 12:00～20:00 / 土日祝 11:00～20:00に短縮営業中</p>
                    <p><strong><span  class="shop-information">※新型コロナウイルスの影響により、現在BIG MAGIC各店の営業時間に変更がある場合がございます。詳細は各店にてお問い合わせ下さい。</span></strong></p>
                    <p><a href="https://twitter.com/BM_ikebukuro">BIG MAGIC池袋店 Twitter</a></p>
                    <p>■取り扱い商品<br>
                        MTG・遊戯王・ヴァイスシュヴァルツ・ポケモンカードゲーム</p>
                    <p><a href="https://bigmagicikebukuro.diarynote.jp/">BIG MAGIC池袋店 DiaryNote</a></p>
                    <p><a href="https://twitter.com/BM_ikebukuro">BIG MAGIC池袋店 Twitter</a></p>
                    <p>　</p>
                    <div class="embed-container"><iframe class="shop-google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12955.419810920508!2d139.7160213!3d35.7297856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x41139cc9db11d6a6!2zQklHIE1BR0lDIOaxoOiii-W6lw!5e0!3m2!1sja!2sjp!4v1547887903259"frameborder="0" allowfullscreen=""></iframe></div><br>
                    <div id="bmtp-ikebkuro">&nbsp;</div><p>&nbsp;</p>
                </div>
                <div align="center"><h4 class="bmtp-title"><strong>BMTP交換賞品一覧表</strong></h4><img src="https://mtg.bigmagic.net/article/about/bmtpikebukuro004.jpg" alt="BMTP 池袋店交換賞品一覧表" border="0"  class="shop-info-image"><br><br><img src="https://mtg.bigmagic.net/article/about/bmtpikebukuro07.jpg" alt="BMTP 池袋店交換可能サプライ一覧表" border="0" class="shop-info-image"></div>
                <div class="bm-point-info">
                    <p>ポイントの獲得条件など詳細は<a href="https://bigmagicikebukuro.diarynote.jp/201812311509309530/">こちら</a></p>
                    <p>内容は予告無く変更になる場合がございますのでご了承ください。</p>
                    <p>　</p>
                    <a id="bmikebukuro-kessai" name="bmikebukuro-kessai"></a>
                    <div class="shop-content-label">
                        <h4><strong>お支払い一覧</strong></h4>
                    </div>
                    <div align="center"><img src="https://mtg.bigmagic.net/article/about/BIGMAGIC-kessai-ikebukuro.jpg" alt="池袋店お支払い一覧" border="0" class="shop-info-image"></div>
                    <p>　</p>
                    <a id="bmikebukuro-sch" name="bmikebukuro-sch"></a>
                    <div class="shop-content-label">
                        <h4><strong>店舗大会スケジュール</strong></h4>
                    </div>
                    <p><iframe width="100%" height="400" src="https://www.google.com/calendar/embed?mode=AGENDA&amp;showTitle=0&amp;src=ikebukuro.taikai%40gmail.com&amp;ctz=Asia/Tokyo" frameborder="0" scrolling="no"></iframe></p>
                    <p>イベントをクリックすると、イベント内容をご覧頂けます。イベントの日時は予告なく変更する場合がございます。詳細はイベント開催店舗の方にお問い合わせ下さい。</p>
                    <p>シングルカード高価買取中!! / デュエルスペース完備!!</p>
                    <p>BIG MONDAY ポイント2倍DAY!!（毎週月曜日はポイント2倍の日）※一部対象外の商品がございます。</p>
                    <p>　</p>
                    <p><a href="https://mtg.bigweb.co.jp/shop">BIG MAGIC店舗一覧ページに戻る</a></p>
                    <p><a href="https://mtg.bigweb.co.jp/shop/recruit">BIG MAGICスタッフ募集ページ</a></p>
                </div>
            </div>
        </article>
    </div>
</div>