import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class PrivacyComponent implements OnInit {

  title: string = '個人情報保護方針';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: '個人情報保護方針', url: '/guide/privacy'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
