import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from "../../environments/environment";

import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    token: string = '';
    body: any = new HttpParams();
    options: any;
    loginUser: any;
    constructor(
        public http: HttpClient,
        private router: Router,
    ) {
        let me = this;
        me.token = sessionStorage.getItem('token');
        if (me.token) {
            me.setToken(me.token);
        }
    }

    setToken(token) {
        let me = this;
        me.token = token;
        sessionStorage.setItem('token', token);
        me.options = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token
            })
        };
    }

    login(user) {
        //  POSTはCSRFでエラーがでるのでひとまずgetで

        let url = environment.server + 'login';

        let params = '?username=' + user['username'] + '&password=' + user['password'];
        let result: any = this.http.get(url + params, this.options);
        return result;
    }

    logout() {
        let me = this;
        me.token = null;
        me.loginUser = false;
        sessionStorage.removeItem('token');
        me.router.navigate(['/'], {replaceUrl: true});
    }

    setLoginUser(user) {
        this.loginUser = user;
    }
    getLoginUser() {
        return this.loginUser;
    }

    loadLoginUser() {
        let url = environment.server + 'Users/getLoginUserByToken';
        let result: any = this.http.get(url, this.options);
        return result;
    }

    //  TODO
    //    login(user) {

    //        let me = this;
    //        let url = environment.server + 'login';
    //        let body = new FormData();
    //        let headers = {headers: new HttpHeaders()};
    //
    //        body.append('username', user['username']);
    //        body.append('password', user['password']);
    //        body.append('scope', '*');
    //
    //        return me.http.post(url, body, headers);
    //    }


}
