<app-toolbar [title]="title" [is_purchase]="is_purchase" [game]="game">
</app-toolbar>
<app-menubar [game]="game"></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>
<div class="row user-main-notice-row"
  style="background-color: antiquewhite; display: flex; justify-content: center; border-bottom: 2px solid #1823b1;">
  <div class="col-xs-12">

    <div class="user-main-notice" style="padding: 5px 20px">
      <span class="notice-message" style="display: block; line-height: 2rem; text-align: center;">
        <a href="https://mtg.bigweb.co.jp/informations/orderconfirmation" target="mtg" style="color: orangered;">
          【 能登半島地震の影響による配送遅延について 】
        </a><br>
      </span>
    </div>

  </div>
</div>

<ng-container *ngIf="game">
  <div>
    <app-search-bar [is_purchase]="is_purchase" [game]="game" [criteria]="criteria" [cardsets]="cardsets"
      [category]="category">
    </app-search-bar>
  </div>

  <mat-sidenav-container class="main-container" *ngIf="recommends">
    <!-- sidenav -->
    <mat-sidenav mode="side" opened class="cardset-list" id="cardset-list">
      <app-category-tile
        *ngIf='game.type_genre_id!==TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
        [game]="game" [category]="category" [has_reservation]="has_reservation" [is_purchase]="is_purchase">
      </app-category-tile>
      <app-cardset-list *ngIf="category!==TypeConstant.STR_SUPPLY" [game]="game" [is_purchase]="is_purchase"
        [cardsets]="cardsets" [criteria]="criteria" [category]="category" (onSelectCardset)="onSelectCardset($event)">
      </app-cardset-list>

      <ng-container *ngIf="category===TypeConstant.STR_SUPPLY && !is_purchase">
        <app-supply-genre [game]="game" [supplytypes]="supplytypes" [supplytype_id]="game.id"
          [is_purchase]="is_purchase" [groupType]="groupType">
        </app-supply-genre>
      </ng-container>
    </mat-sidenav>
    <!-- sidenav -->

    <!-- content -->
    <mat-sidenav-content>

      <app-top-slider [slides]="slides" [category]="category">
      </app-top-slider>

      <app-category class="category-display"
        *ngIf='game.type_genre_id!==TypeGenreConstant.SUPPLY && game.type_genre_id!==TypeGenreConstant.CHARACTER_SUPPLY'
        [game]="game" [category]="category" [is_purchase]="is_purchase">
      </app-category>

      <ng-container *ngIf="category=='boxes' && boxItems.length>0">
        <h2 class="feature-title">
          <span>すべての箱・パック（在庫有り）</span>
          <a href="products/{{game.code}}/list?is_box=1" class="feature-title-more">もっと見る</a>
        </h2>
        <!-- Swiper START -->
        <div class="swiper-container recommend-items">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <!-- メイン表示部分 -->
          <div class="swiper-wrapper">
            <!-- 各スライド -->
            <ng-container *ngFor="let item of boxItems">
              <div class="swiper-slide recommeend-item">
                <app-grid-itembox [item]="item" [is_reservation]="is_reservation"
                  (addToCartItems)="runAddToCartItems(item)">
                </app-grid-itembox>
              </div>

            </ng-container>
          </div>
        </div>
        <!-- Swiper END -->
      </ng-container>

      <ng-container *ngFor="let recommend of recommends">
        <ng-container *ngIf="
                              category==null&&recommend.is_single||
                              category=='boxes'&&recommend.is_box||
                              category=='supply'&&recommend.is_supply||
                              category=='reservation'&&recommend.is_reservation
                              ">
          <ng-container *ngIf="recommend.products.length>0">
            <h2 class="feature-title">
              <span [ngClass]="{'recommend-reservation':recommend.is_reservation}">{{recommend.name}}</span>
              <a href="products/{{game.code}}/list?recommend_id={{recommend.id}}" class="feature-title-more">もっと見る</a>
            </h2>
            <!-- Swiper START -->
            <div class="swiper-container recommend-items">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
              <!-- メイン表示部分 -->
              <div class="swiper-wrapper">
                <!-- 各スライド -->
                <ng-container *ngFor="let item of recommend.products">
                  <div class="swiper-slide swiper-lazy-loading recommeend-item">
                    <app-grid-itembox [item]="item" [is_reservation]="is_reservation"
                      (addToCartItems)="runAddToCartItems(item)">
                    </app-grid-itembox>
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- Swiper END -->
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="notice">
        {{notice}}
      </ng-container>

    </mat-sidenav-content>
    <!-- content -->
  </mat-sidenav-container>
</ng-container>