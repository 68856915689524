<app-toolbar [title]="title" [is_purchase]="is_purchase" [game]="game">
</app-toolbar>
<app-menubar [game]="game"></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div>
  <app-search-bar [is_purchase]="is_purchase" [game]="game" [criteria]="criteria" [cardsets]="cardsets"
    [selectedCardsets]="selectedCardsets" [filterTags]="filterTags" [category]="category" (removeTags)="onRemoveTags()">
  </app-search-bar>
</div>
<mat-drawer-container class="main-container">

  <mat-drawer mode="side" opened class="cardset-list" id="cardset-list">
    <app-category-tile
      *ngIf='game && game.type_genre_id!=TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
      [game]="game" [has_reservation]="has_reservation" [category]="category" [is_purchase]="is_purchase">
    </app-category-tile>

    <ng-container *ngIf="game.id !== TypeConstant.SUPPLY && game.id !== TypeConstant.CHARACTER_SUPPLY else supplyGenre">
      <app-cardset-list [game]="game" [is_purchase]="is_purchase" [cardsets]="cardsets"
        [selectedCardsets]="selectedCardsets" [selectedRarity]="selectedRarity" [criteria]="criteria"
        [category]="category" (onSelectCardset)="onSelectCardset($event)">
      </app-cardset-list>
    </ng-container>
    <ng-template #supplyGenre>
      <app-supply-genre [game]="game" [supplytypes]="supplytypes" [supplytype_id]="supplytype_id"
        [subtype_id]="subtype_id" [selectedCardsets]="selectedCardsets" [is_purchase]="is_purchase" [groupType]="groupType">
      </app-supply-genre>
    </ng-template>

  </mat-drawer>
  <mat-drawer-content>
    <app-category class="category-display"
      *ngIf='game&&game.type_genre_id!=TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
      [game]="game" [category]="category" [is_purchase]="is_purchase">
    </app-category>
    <div fxLayoutAlign="start center" class="swiper-container filter-criteria" *ngIf="filterTags">
      <div>
        <span class="filter-criteria-label filter-criteria-label-game">
          <span i18n>検索条件: {{game.name}} </span>
        </span>
        <!--<span class="filter-criteria-label" *ngIf="sub_title">
          {{sub_title}}
        </span>-->
      </div>
      <div>
        <ng-container *ngFor="let tag of filterTags">
          <a mat-stroked-button color="primary" class="swiper-slide filter-criteria-label">
            {{tag.name}}
            <mat-icon (click)="removeTag(tag)">cancel</mat-icon>
          </a>
        </ng-container>
        <a mat-stroked-button color="primary" class="swiper-slide filter-criteria-label" *ngIf="inStock">
          在庫有り
          <mat-icon (click)="switchShowSoldout(false)">cancel</mat-icon>
        </a>
        <a mat-stroked-button color="primary" class="swiper-slide filter-criteria-label" *ngIf="isBargain">
          特価品のみ
          <mat-icon (click)="toggleBargain(false)">cancel</mat-icon>
        </a>
      </div>
    </div>
    <div fxLayoutAlign="end center">
      <div class="result_count" *ngIf="pagenate.count>0">検索結果:{{pagenate.count}}件</div>
    </div>
    <div class="paging-not-found" *ngIf="items.length==0&&!isLoading">
      <ng-container *ngIf="pagenate.count>0">
        <ng-container *ngIf="inStock">
          上記検索条件に一致する商品は、現在売り切れです。
          <div>
            <a mat-raised-button color="primary" class="" (click)="switchShowSoldout(false)">
              検索条件の在庫を「在庫有り」から「全て」に変更して売り切れ商品も表示する。
            </a>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="pagenate.count==0">
        <ng-container *ngIf="!isBargain">
          上記検索条件に一致する商品はありませんでした。
        </ng-container>
        <ng-container *ngIf="isBargain">
          上記検索条件に一致する特価品はありませんでした。
          <div>
            <a mat-raised-button color="primary" class="" (click)="toggleBargain(false)">
              検索条件を「特価品のみ」から「全て」に変更して商品を検索する。
            </a>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <mat-toolbar class="change-view">
      <mat-toolbar-row class="tool_container">
        <div class="tool_box">

          <ng-container *ngIf="(game.id === TypeConstant.SUPPLY || game.id === TypeConstant.CHARACTER_SUPPLY)">
            <div>
              <span class="filter-criteria-label">カテゴリ:</span>
              <mat-form-field appearance="outline" class="sort-select-field">
                <mat-select [value]="selectedCardsets[0]" (selectionChange)="selectSupplyType($event)"
                  placeholder="カテゴリ">
                  <mat-option *ngFor="let cardset of cardsets" [value]="cardset.id">
                    {{cardset.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <div *ngIf="game.id != TypeConstant.TCGJP">
            <span class="filter-criteria-label" *ngIf="category!='supply'">{{colourLabel}}:</span>
            <span class="filter-criteria-label" *ngIf="category=='supply'">メーカー:</span>
            <mat-form-field appearance="outline" class="sort-select-field">
              <mat-select [value]="selectedColour" (selectionChange)="selectColour($event)">
                <mat-option *ngFor="let color of colourOptions" [value]="color.value">
                  {{color.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <span class="filter-criteria-label">並び順:</span>
            <mat-form-field appearance="outline" class="sort-select-field">
              <mat-select [value]="sortBy" (selectionChange)="selectionSort($event)">
                <mat-option value="id">
                  ID順
                </mat-option>
                <mat-option value="price.desc">
                  価格（高い順）
                </mat-option>
                <mat-option value="price">
                  価格（安い順）
                </mat-option>
                <mat-option value="syllabary">
                  五十音順
                </mat-option>
                <mat-option value="orderid">
                  管理番号順
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="tool_box">
          <div>
            <span class="filter-criteria-label">在庫:</span>
            <mat-button-toggle-group [value]="inStock" #soldoutGroup="matButtonToggleGroup"
              (change)="switchShowSoldout(soldoutGroup.value)">

              <mat-button-toggle [value]=false>
                全て
              </mat-button-toggle>
              <mat-button-toggle [value]=true>
                在庫有り
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div>
            <span class="filter-criteria-label">グループ表示:</span>
            <mat-button-toggle-group [value]="groupType" #seriesGroup="matButtonToggleGroup"
              (change)="toggleShowSeries(seriesGroup.value)">
              <ng-container *ngIf="game.id==TypeConstant.SUPPLY else defaultGroup">
                <mat-button-toggle value='rarity'>
                  しない
                </mat-button-toggle>
              </ng-container>
              <ng-template #defaultGroup>
                <mat-button-toggle value='none'>
                  しない
                </mat-button-toggle>
              </ng-template>
              <mat-button-toggle value='cardset' *ngIf="category!=TypeConstant.STR_CHARACTER_SUPPLY">
                セット毎
              </mat-button-toggle>

              <ng-container
                *ngIf="category=='list' && (game.id!=TypeConstant.SUPPLY && game.id!=TypeConstant.CHARACTER_SUPPLY) else groupOthers">
                <mat-button-toggle value='rarity'>レアリティ毎</mat-button-toggle>
              </ng-container>
              <ng-template #groupOthers>
                <ng-container *ngIf="game.id==TypeConstant.CHARACTER_SUPPLY">
                  <mat-button-toggle value='rarity'>キャラクター毎</mat-button-toggle>
                </ng-container>
              </ng-template>

            </mat-button-toggle-group>
          </div>
          <div>
            <span class="filter-criteria-label">特価品:</span>
            <mat-button-toggle-group [value]="isBargain" #bargainGroup="matButtonToggleGroup"
              (change)="toggleBargain(bargainGroup.value)">

              <mat-button-toggle [value]=false>
                全て
              </mat-button-toggle>
              <mat-button-toggle [value]=true>
                特価品のみ
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

      </mat-toolbar-row>
    </mat-toolbar>

    <div class="swiper-container product-list">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <app-grid-images [game]="game" [items]="items" [category]="category" [headerArrayList]="headerArrayList"
            [isHiddenSoldout]="inStock" [groupType]="groupType" (scrolledList)="onScrollList()">
          </app-grid-images>
        </div>


      </div>

      <div class="paging-loader" *ngIf="isLoading">
      </div>
      <div class="result_count" *ngIf="pagenate.count>0">
        検索結果:{{pagenate.count}}件</div>
      <div *ngIf="pagenate.pageCount>pagenate.page&&limitPage>=pagenate.page">
        <button (click)='onScrollList()'>
          もっと表示する
        </button>
      </div>
      <div *ngIf="limitPage<pagenate.page">
        <div class="limit-page-message">
          ※表示上限の{{limitPage*pagenate.limit}}件に達しました。
          検索条件を設定してください。
        </div>

      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>