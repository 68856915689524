import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Swiper } from 'swiper';

@Component({
    selector: 'app-card-itembox',
    templateUrl: './itembox.component.html',
    styleUrls: ['./itembox.component.scss'],
})
export class CardItemboxComponent implements OnInit {
    @Input() game: any;
    @Input() current_item: any;
    @Input() items: any;
    @Input() pagenate: any;
    @Output() addToCartItems: EventEmitter<any> = new EventEmitter();
    @Output() scrolledList: EventEmitter<any> = new EventEmitter();
    @Output() onSelectCurrentItem: EventEmitter<any> = new EventEmitter();
    @Output() loadSameCardPage: EventEmitter<any> = new EventEmitter();

    @Input() is_purchase: boolean;
    @Input() is_reservation: boolean;
    @Input() is_cardview: boolean;

    constructor(

    ) {

    }

    ngOnInit(): void {
        let me = this;
        new Promise<void>((resolve) => {
            resolve();
        }).then(() => {
            this.createSwiper();
        });

    }

    onScroll() {
        let me = this;
        me.scrolledList.emit();
    }

    selectCurrentItem(item) {
        this.onSelectCurrentItem.emit(item);
    }

    runAddToCartItems(items: any) {
        this.addToCartItems.emit(items);
    }

    loadPage(page) {
        this.loadSameCardPage.emit(page);
    }

    createSwiper() {

    }
}
