<div class="container modal-container" fxLayout='row wrap'>
  <div class="image-view-detail" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start" (click)="closeDialog()">
    <ng-container *ngIf="item.is_single else imageNormal">
      <picture>
        <!-- <source type="image/webp" srcset="{{item.webpL}}" /> -->
        <source type="image/jpeg" srcset="{{item.imageL}}" onerror="fallback()"/>
        <img src="{{item.imageL}}" alt="{{item.name}}" />
      </picture>
    </ng-container>
    <ng-template #imageNormal>
      <picture>
        <!-- <source type="image/webp" srcset="{{item.webp}}" /> -->
        <source type="image/jpeg" srcset="{{item.image}}" />
        <img src="{{item.image}}" alt="{{item.name}}" />
      </picture>
    </ng-template>
  </div>
</div>