import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TypeConstant, TypeGenreConstant } from '../../../appConstant';

import { GamesService, ItemsService, StoresService, SettingsService } from '../../_services';

@Component({
    selector: 'app-card-viewer',
    templateUrl: './card-viewer.component.html',
    styleUrls: ['./card-viewer.component.scss']
})

export class CardViewerComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;
    TypeGenreConstant: typeof TypeGenreConstant = TypeGenreConstant;

    game: any;
    is_purchase: boolean;
    title: string = ''
    current_item: any;
    is_static: boolean = true;
    localeId: string;

    breadcrumbs: any[] = [];
    product = {};
    name: string = '';
    id: string;
    cardsets: any;
    criteria: any = {};
    category: string = '';
    supplytypes: any = [];
    supplytype_id: any;
    subtype_id: any;
    items: any[] = [];
    has_reservation: boolean = false;
    pagenate: any;
    page: number = 1;
    private cartSubscription: Subscription;
    private tempCartSubscription: Subscription;
    private favoriteSubscription: Subscription;

    private cartItemSubscription: Subscription;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public itemsService: ItemsService,
        public storesService: StoresService,
        public settingsService: SettingsService,
        //public loadingService: LoadingService,
        public gamesService: GamesService,
    ) {
        let me = this;
        let path = this.router.url.split('/');
        me.is_purchase = path[1] == 'purchase' ? true : false;

        this.localeId = this.settingsService.getLocale();
    }

    ngOnInit(): void {
        let me = this;
        window.scroll(0, 0);

        me.route.paramMap.subscribe(paramsMap => {
            me.name = paramsMap.get('name');
            me.id = paramsMap.get('id');
            me.title = paramsMap.get('title');

            me.game = me.gamesService.getConfigs(me.title);
            if (me.game) {
                me.setGameTitle();
                me.setCardset();
            } else {
                me.gamesService.loadData().subscribe(games => {
                    me.gamesService.games = games;
                    localStorage.setItem('Games', JSON.stringify(games));
                    me.game = games.find(game => { return game.name == me.title });
                    me.setGameTitle();
                });

            }
        });

        me.cartSubscription = me.storesService.$cart.subscribe(
            items => {
                if (me.items) {
                    if (items) {
                        me.onChangeItem(items);
                    } else {
                        me.removeCartItems();
                    }
                }
            }
        );

        me.tempCartSubscription = me.storesService.$tempCart.subscribe(
            items => {
                if (me.items) {
                    if (items) {
                        me.onChangeTempItem(items);

                    }
                }
            }
        );

        me.favoriteSubscription = me.storesService.$favorite.subscribe(
            favorites => {
                if (me.items) {
                    if (favorites) {
                        me.onChangeFavorites(favorites);
                    } else {
                        //    me.removeCartItems();
                    }
                }
            }
        );
        me.cartItemSubscription = me.storesService.$cartItem.subscribe(
            item => {
                me.onChangeItem(item);
            }
        );
    }


    refreshItems() {
        let me = this;
        //me.loadingService.hideLoading();
        if (me.is_purchase) {
            setTimeout(function () {
                me.storesService.refreshPurchaseItems();
            }, 100);
        } else {
            setTimeout(function () {
                me.storesService.refreshItems();
            }, 100);
        }
    }

    selectCurrentItem(item) {
        this.current_item = item;
    }

    addHistory(item) {
        this.storesService.addHistory({ item: item, action: 'add' });
    }

    setGameTitle() {
        let me = this;

        me.itemsService.getById(me.id, me.is_purchase).subscribe((result: any) => {
            me.current_item = result.items;
            if (me.current_item.supplytype_id > 0) {
                me.supplytype_id = me.current_item.supplytype_id;
                me.subtype_id = me.current_item.colour;
            }

            // シングルカードのみ対応
            me.current_item.is_single = false;
            if (!me.current_item.is_supply && !me.current_item.is_box) {
                // Lに差し替え (404は.htaccessで転送)
                me.current_item.imageL = me.current_item.image.replace(/(.+)(?!_L)\.(jpe?g|gif|png)/, '$1_L.$2');
                me.current_item.is_single = true;
            }

            let title = me.current_item.name + ' / ' + me.current_item.fname
             + ' '
             + me.current_item.cardset.web
             + '|'
             + me.current_item.game.web + ' ' + (me.is_purchase ? '買い取り' : '')
             + '|'
             + 'BIGWEB';

            let description = '';
            if(me.game.meta_description != null) {
                description = me.game.meta_description;
            } else {
                description = me.game.title
                + "の通販なら、日本最大級の品揃えのカードショップ【BIGWEB】におまかせください。"
                + "数量限定の最新弾シングルや、海外版シングル、海外版パックなど、あなたの欲しいカードが見つかります。";
            }

            me.settingsService.setTitle(title, description);

            if (!me.game) { me.game = me.current_item.game }
            if (me.game.type_genre_id == TypeGenreConstant.SUPPLY || me.game.type_genre_id == TypeGenreConstant.CHARACTER_SUPPLY || me.current_item.shape > 2) {
                me.category = TypeConstant.STR_SUPPLY;
                me.setSupplytypes();
            } else if (me.current_item.shape == 2) {
                me.category = 'boxes';
            }
            me.addHistory(me.current_item);
            me.breadcrumbs = me.settingsService.getBreadcrumbsByGame(me.game, me.is_purchase);

            if (!me.current_item.is_supply) {
                me.breadcrumbs.push(
                    {
                        title: me.current_item.cardset.slip,
                        url: "/" + this.localeId + '/' + (me.is_purchase ? 'purchase' : 'products') + "/" + me.game.code + "/list?cardsets=" + me.current_item.cardset.id + '&is_box=' + (me.category == 'boxes' ? 1 : 0) + (me.is_purchase ? '&is_purchase=1' : '')
                    }
                )
            } else {
                me.breadcrumbs.push(
                    {
                        title: me.current_item.cardset.slip,
                        url: "/" + this.localeId + '/' + (me.is_purchase ? 'purchase' : 'products') + "/" + me.game.code + "/list?series=" + me.current_item.cardset.id + "&is_supply=1&game_id=" + me.game.id
                    }
                )
            }
            me.breadcrumbs.push(
                {
                    title: me.current_item.name + (me.is_purchase ? '【買取】' : ''),
                    url: "/" + this.localeId + '/' + (me.is_purchase ? 'purchase' : 'products') + "/" + me.game.code + "/cardViewer/" + me.current_item.id
                }
            )
            me.loadSameCards(me.current_item.name, 1);

            me.setCardset();
        })


    }

    setSupplytypes() {
        let me = this;
        if (me.current_item.game.type_genre_id == TypeGenreConstant.CHARACTER_SUPPLY) {
            me.supplytypes = me.gamesService.getCharacterSupplyCardSets();
        } else {
            me.supplytypes = me.gamesService.getSupplyCardSets();
        }
    }

    setCardset() {
        let me = this;
        if (me.game) {
            me.criteria.game_id = me.game.id;
            //me.gamesService.getCardSets(me.game.id).subscribe(cardsets => {
            //    me.cardsets = cardsets[0].cardsets;
            //    me.has_reservation = me.cardsets.find(item => item.is_reservation == true) ? true : false;
            //});
            me.cardsets = me.gamesService.getCardSets(me.game.id);
            me.has_reservation = me.cardsets.find(item => item.is_reservation == true) ? true : false;
        }
    }

    onLoadSamecardPage(page) {
        let me = this;
        me.loadSameCards(me.current_item.name, page);
    }

    loadSameCards(name, page) {
        let me = this;
        me.itemsService.loadSameCards(name, me.is_purchase, page, me.game.id).subscribe((result: any) => {
            if (result.items) {
                me.sortActiveItem(result.items);
                me.pagenate = result.pagenate;
            }
        })

    }

    sortActiveItem(sameNameCards) {
        let me = this;
        if (!sameNameCards.some(item => { return item.id == me.current_item.id; })) {
            let tempItem = JSON.parse(JSON.stringify(me.current_item));
            sameNameCards.unshift(tempItem);
        }

        me.items = sameNameCards;
        me.current_item = me.items.find(data => {
            return me.current_item.id == data.id
        });
        me.refreshItems();
    }

    runAddToCartItems(item: any) {
        this.storesService.addCartItems(item);
    }

    onChangeFavorites(favorites) {


    }
    onChangeTempItem(targetItem: any) {
        let me = this;
        if (me.current_item) {
            if (me.current_item.id == targetItem.id) {
                me.current_item = targetItem;
            }
            for (let y = 0; y < me.current_item.relatedItems.length; y++) {
                if (me.current_item.relatedItems[y].id == targetItem.id) {
                    me.current_item.relatedItems[y] = targetItem;
                }
            }
        }
        for (let y = 0; y < me.items.length; y++) {
            if (me.items[y].id == targetItem.id) {
                me.items[y] = targetItem;
            }
        }
    }

    onChangeItem(targetItems) {

        let me = this;
        //  追加されたitem.idを取得
        let itemsId = targetItems.map(item => { if (item) return item.id });
        if (me.current_item) {
            if (itemsId.indexOf(me.current_item.relatedItems[0].id) > -1) {
                let tage = targetItems.filter(tItem => { if (tItem && tItem.id == me.current_item.relatedItems[0].id) return tItem });
                if (tage.length > 0) {
                    me.current_item.relatedItems[0].quantity = tage[0].quantity;
                    me.current_item.relatedItems[0].temp_quantity = me.current_item.relatedItems[0].quantity;
                }
            }
        }
        me.items = me.items.map(item => {
            item.relatedItems.map(relatedItem => {
                if (itemsId.indexOf(relatedItem.id) > -1) {
                    let tage = targetItems.filter(tItem => { if (tItem && tItem.id == relatedItem.id) return tItem });
                    if (tage.length > 0) {
                        relatedItem.quantity = tage[0].quantity;
                        relatedItem.temp_quantity = relatedItem.quantity;
                    }
                }
                return relatedItem;
            })
            return item;
        });
    }

    onSelectCardset(param) {
        this.items = [];
        //this.loadingService.loading();
    }

    removeCartItems() {
        let me = this;
        me.items = me.items.map(item => {
            item.relatedItems.map(relatedItem => {
                relatedItem.quantity = 0;
                relatedItem.temp_quantity = 0;
                return relatedItem;
            })
            return item;
        });
    }
}
