<app-toolbar [title]="title" [is_purchase]="is_purchase" [game]="game">
</app-toolbar>
<app-menubar [game]="game"></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>
<div class="row user-main-notice-row" style="background-color: antiquewhite; display: flex; justify-content: center; border-bottom: 2px solid #1823b1;">
  <div class="col-xs-12">

    <div class="user-main-notice" style="padding: 5px 20px">
      <span class="notice-message" style="display: block; line-height: 2rem; text-align: center;">
        <a href="https://mtg.bigweb.co.jp/informations/orderconfirmation" target="mtg" style="color: orangered;">
          【 能登半島地震の影響による配送遅延について 】
        </a><br>
      </span>
    </div>

  </div>
</div>
<!-- <div class="contents purchase-contents"> -->
<ng-container *ngIf="game">
  <div>
    <app-search-bar class="bigweb-buying-theme" [is_purchase]="is_purchase" [game]="game" [criteria]="criteria"
      [cardsets]="cardsets" [category]="category">
    </app-search-bar>
  </div>

  <mat-drawer-container class="main-container　purchase-contents" *ngIf="recommends">
    <mat-drawer mode="side" opened class="cardset-list" id="cardset-list">
      <app-category-tile
        *ngIf='game.type_genre_id!=TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
        [game]="game" [category]="category" [is_purchase]="is_purchase">
      </app-category-tile>
      <app-cardset-list *ngIf="category!='supply'" [game]="game" [is_purchase]="is_purchase" [cardsets]="cardsets"
        [criteria]="criteria" [category]="category" (onSelectCardset)="onSelectCardset($event)">
      </app-cardset-list>
      <app-supply-genre *ngIf="category==TypeConstant.STR_SUPPLY" [game]="game" [supplytypes]="supplytypes"
        [supplytype_id]="supplytype_id" [is_purchase]="is_purchase">
      </app-supply-genre>
    </mat-drawer>
    <mat-drawer-content>

      <!--
      <app-top-slider [slides]="slides" [category]="category" [is_purchase]="is_purchase">
      </app-top-slider>
      -->
      <app-explain-flows [bulk_purchase_id]="bulk_purchase_id"></app-explain-flows>
      <app-category class="category-display"
        *ngIf='game.type_genre_id!=TypeGenreConstant.SUPPLY && game.type_genre_id!=TypeGenreConstant.CHARACTER_SUPPLY'
        [game]="game" [category]="category" [is_purchase]="is_purchase">
      </app-category>

      <ng-container *ngIf="category=='boxes'&&boxItems.length>0">
        <h2 class="feature-title">
          <span>すべての箱・パック（在庫有り）</span>
          <a href="purchase/{{game.code}}/list?is_box=1&is_purchase=1" class="feature-title-more">もっと見る</a>
        </h2>
        <!-- Swiper START -->
        <div class="swiper-container recommend-items">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <!-- メイン表示部分 -->
          <div class="swiper-wrapper">
            <!-- 各スライド -->
            <ng-container *ngFor="let item of boxItems">
              <div class="swiper-slide recommeend-item">
                <app-grid-itembox [item]="item" [is_reservation]="is_reservation" [is_purchase]="is_purchase"
                  (addToCartItems)="runAddToCartItems(item)">
                </app-grid-itembox>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- Swiper END -->
      </ng-container>

      <ng-container *ngFor="let recommend of recommends">
        <ng-container *ngIf="recommend.is_buying">
          <h2 class="feature-title">
            <span [ngClass]="{'recommend-reservation':recommend.is_reservation}">{{recommend.name}}</span>
            <ng-container *ngIf="recommends.id != 0 && !recommend.series_id else latestSeries">
              <a href="purchase/{{game.code}}/list?recommend_id={{recommend.id}}&is_purchase=1"
                class="feature-title-more">もっと見る</a>
            </ng-container>
            <ng-template #latestSeries>
              <a href="purchase/{{game.code}}/list?cardsets={{recommend.series_id}}&is_purchase=1"
                class="feature-title-more">もっと見る</a>
            </ng-template>
          </h2>
          <!-- Swiper START -->
          <div class="swiper-container recommend-items">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <!-- メイン表示部分 -->
            <div class="swiper-wrapper">
              <!-- 各スライド -->
              <ng-container *ngFor="let item of recommend.products">
                <div class="swiper-slide recommeend-item">
                  <app-grid-itembox [item]="item" [is_reservation]="is_reservation" [is_purchase]="is_purchase"
                    (addToCartItems)="runAddToCartItems(item)">
                  </app-grid-itembox>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- Swiper END -->
        </ng-container>
      </ng-container>

      <ng-container *ngIf="notice">
        {{notice}}
      </ng-container>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-container>

<!-- </div> -->
