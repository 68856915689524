<ng-container *ngIf="game">

    <div class="item-category" *ngIf="!is_purchase">
        <div class="category-case">
            <button *ngIf="game.id!=9 && game.id!=10" class="reserved-product" (click)="goToCategoryTop('reservation')"
                mat-stroked-button [ngClass]="{'active-reservation':category=='reservation'}">
                <i class=" mat-icon icon-cat icon-pre"></i>予約商品
            </button>
            <button (click)="goToCategoryTop('single')" mat-stroked-button
                *ngIf="category!=null&&category!=''&&category!='list'">
                <i class=" mat-icon icon-cat icon-single"></i>シングル
            </button>
            <button mat-stroked-button *ngIf="category==null||category==''||category=='list'" class="active">
                <i class=" mat-icon icon-cat icon-single"></i>シングル
            </button>
        </div>
        <div class="category-case">
            <button (click)="goToCategoryTop('boxes')" mat-stroked-button *ngIf="category!='boxes'">
                <i class=" mat-icon icon-cat icon-boxes"></i>箱/パック
            </button>
            <button mat-stroked-button class="active" *ngIf="category=='boxes'">
                <i class=" mat-icon icon-cat icon-boxes"></i>箱/パック
            </button>
            <button (click)="goToSupply()" mat-stroked-button *ngIf="category!='supply'">
                <mat-icon class="icon-supply">casino</mat-icon>サプライ
            </button>
            <button mat-stroked-button class="active" *ngIf="category=='supply'">
                <mat-icon class="icon-supply">casino</mat-icon>サプライ
            </button>
        </div>
    </div>
    <div class="item-category.purchase" *ngIf="is_purchase">
        <div class="category-case-buying">
            <button (click)="goToCategoryTop('')" mat-stroked-button
                *ngIf="category!=null&&category!=''&&category!='list'">
                <i class=" mat-icon icon-cat icon-single buying"></i>シングル
            </button>
            <button mat-stroked-button *ngIf="category==null||category==''||category=='list'" class="active">
                <i class=" mat-icon icon-cat icon-single buying"></i>シングル
            </button>
            <button (click)="goToCategoryTop('boxes')" mat-stroked-button [ngClass]="{active:category=='boxes'}">
                <i class=" mat-icon icon-cat icon-boxes buying"></i>箱/パック
            </button>
        </div>
    </div>

</ng-container>