import { Component, OnInit ,Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-explain-flows',
  templateUrl: './explain-flows.component.html',
  styleUrls: ['./explain-flows.component.scss']
})
export class ExplainFlowsComponent implements OnInit {
  @Input() bulk_purchase_id: any;

  cart_url: string;

  constructor() { }

  ngOnInit(): void {
    let me = this;
    me.cart_url = environment.parchase_cart_url;
  }

}
