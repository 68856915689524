<!-- game supply -->
<ng-container
  *ngIf="(game.type_genre_id!==TypeGenreConstant.SUPPLY && game.type_genre_id!==TypeGenreConstant.CHARACTER_SUPPLY) else supplyGenre;">
  <div>
    【{{game.title}}】
  </div>
  <div fxLayout="row wrap">
    <a mat-stroked-button fxFlex="100" fxLayoutAlign="start" [ngClass]="{'active-supply-genre':is_list&&!supplytype_id}"
      href="/{{localeId}}/products/{{game.code}}/list?is_supply=1" fxLayout="row">
      <span fxFlex="90">サプライ : {{game.title}}</span>
    </a>
  </div>
  <!-- supply -->
  <div>
    <a mat-button href="/{{localeId}}/products/{{TypeConstant.STR_SUPPLY}}/" class="cardset-list-item">
      汎用サプライ <i class="material-icons">chevron_right</i>
    </a>
  </div>
  <div>
    <a mat-button href="/{{localeId}}/products/character-supply/" class="cardset-list-item">
      キャラクターサプライ <i class="material-icons">chevron_right</i>
    </a>
  </div>
  <!-- supply -->
</ng-container>
<!-- game supply -->

<ng-template #supplyGenre>
  <div class="supply-genre-menu-box" *ngIf="game.type_genre_id==TypeGenreConstant.SUPPLY">
    <a href="/{{localeId}}/products/{{TypeConstant.STR_SUPPLY}}/"><img src="{{image_url}}//top_63.png" /></a>
  </div>
  <div class="supply-genre-menu-box" *ngIf="game.type_genre_id==TypeGenreConstant.CHARACTER_SUPPLY">
    <a href="/{{localeId}}/products/character-supply/"><img src="{{image_url}}/top_173.png" /></a>
  </div>

  <ng-container *ngIf="supplytypes.length>0">
    <div *ngFor="let supplytype of supplytypes" fxLayout="row wrap">

      <ng-container *ngIf="selectedCardsets.indexOf(supplytype.id) < 0 else currentSupplytype">
        <a mat-stroked-button fxFlex="100" fxLayoutAlign="start"
          [ngClass]="{'active-supply-genre':supplytype.id==supplytype_id}"
          href="/{{localeId}}/products/{{game.code}}/list?is_supply=1&game_id={{supplytype.type}}&cardsets={{supplytype.id}}&groupType={{groupType}}"
          fxLayout="row">
          <span fxFlex="90">{{supplytype.name}}</span>
        </a>
      </ng-container>

      <ng-template #currentSupplytype>
        <a mat-raised-button fxFlex="100" fxLayoutAlign="start" color="warn"
          [ngClass]="{'active-supply-genre':supplytype.id==supplytype_id}"
          href="/{{localeId}}/products/{{game.code}}/list?is_supply=1&game_id={{supplytype.type}}&cardsets={{supplytype.id}}&groupType={{groupType}}"
          fxLayout="row">
          <span fxFlex="90">{{supplytype.name}}</span>
        </a>
        <ng-container *ngIf="supplytype.supply_subtypes && supplytype.supply_subtypes.length > 0">
          <ng-container *ngFor="let supply_subtype of supplytype.supply_subtypes">
            <a mat-button fxFlex="100" fxLayoutAlign="start"
              [ngClass]="{'active-subtype':supply_subtype.value==subtype_id}"
              href="/{{localeId}}/products/{{game.code}}/list?is_supply=1&game_id={{supplytype.type}}&cardsets={{supplytype.id}}&colour={{supply_subtype.value}}&groupType={{groupType}}"
              class="cardset-list-item">{{supply_subtype.name}}
            </a>
          </ng-container>
        </ng-container>
      </ng-template>

    </div>
  </ng-container>
  <ng-container *ngIf="game.type_genre_id==TypeGenreConstant.SUPPLY">
    <div><br /></div>
    <div>
      <a mat-button href="/{{localeId}}/products/character-supply/" class="cardset-list-item">
        キャラクターサプライ <i class="material-icons">chevron_right</i>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="game.type_genre_id==TypeGenreConstant.CHARACTER_SUPPLY">
    <div><br /></div>
    <div>
      <a mat-button href="/{{localeId}}/products/supply/" class="cardset-list-item">
        汎用サプライ <i class="material-icons">chevron_right</i>
      </a>
    </div>
  </ng-container>

</ng-template>