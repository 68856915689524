/**
 * UriCheckerUtil
 */
export class UriCheckerUtil {

    isItemView(url: string): boolean {
        let expression = new RegExp('/(products|purchase)/(.)+/cardViewer/', 'g');
        if (expression.test(url)) {
            return true;
        }
        return false;
    }

    isBuyingGameTop(url: string): boolean {
        let expression = new RegExp('/purchase/(?!top)(.)+', 'g');
        if (expression.test(url)) {
            return true;
        }
        return false;
    }

    isProductGameTop(url: string): boolean {
        let expression = new RegExp('/products/(?!top)(.)+', 'g');
        if (expression.test(url)) {
            return true;
        }
        return false;
    }

    isGameTop(url: string): boolean {
        let expression = new RegExp('/(products|purchase)/(?!top)(.)+', 'g');
        if (expression.test(url)) {
            return true;
        }
        return false;
    }

}