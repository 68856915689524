<ul class="breadcrumb-inner {{is_purchase?'breadcrumb-purchase':''}}"
    itemscope itemtype="https://schema.org/BreadcrumbList">
    <ng-container *ngFor="let breadcrumb of breadcrumbs;let i=index">
        <ng-container  *ngIf="breadcrumb.url">
            <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                <a href="{{breadcrumb.url}}" itemprop="item"><span itemprop="name">{{breadcrumb.title}}</span></a> <span *ngIf="i<breadcrumbs.length-1" class="greater">></span>
                <meta itemprop="position" content="{{1+i}}" />
            </li>
        </ng-container>
        <ng-container  *ngIf="!breadcrumb.url">
            <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                <a itemprop="item"><span itemprop="name">{{breadcrumb.title}}</span></a> <span *ngIf="i<breadcrumbs.length-1" class="greater">></span>
                <meta itemprop="position" content="{{1+i}}" />
            </li>
        </ng-container>
    </ng-container>
</ul>
