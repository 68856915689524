import { Component, OnInit, Input } from '@angular/core';
import { environment } from "../../../environments/environment";


@Component({
    selector: 'app-top-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class TopSliderComponent implements OnInit {
    @Input() slides = [];
    @Input() category: string;
    @Input() is_purchase: boolean;
    server: string = environment.server;

    slideConfig = {
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 4,
        speed: 2400,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2400,
        touchRatio: 5,
        //variableWidth: true,
        responsive: [{
            breakpoint: 1980,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 4
            }
        }, {
            breakpoint: 1600,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 3
            }
        }, {
            breakpoint: 1280,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3
            }
        }, {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    constructor() { }

    ngOnInit(): void {
        let me = this;
    }


}
