<div [ngClass]="is_purchase?'item-category purchase':'item-category'" *ngIf="game">

    <a *ngIf="!is_purchase && (game.id!=9 && game.id!=10)" class="reservation-tab" href="products/{{game.code}}/reservation" mat-stroked-button
        [ngClass]="{active:category=='reservation'}">
        予約商品
    </a>
    <a href="{{is_purchase?'purchase':'products'}}/{{game.code}}" mat-stroked-button
        [ngClass]="{active:category==null||category==''||category=='list'}">
        シングルカード
    </a>
    <a href="{{is_purchase?'purchase':'products'}}/{{game.code}}/boxes" mat-stroked-button color="primary"
        [ngClass]="{active:category=='boxes'}">
        箱/パック
    </a>
    <a href="{{is_purchase?'purchase':'products'}}/{{game.code}}/list?is_supply=1" mat-stroked-button color="primary"
        [ngClass]="{active:category=='supply'}" *ngIf="!is_purchase">
        サプライ
    </a>
    <div class="product-buttons mat-product-accent is_purchase" *ngIf="is_purchase">
        <a mat-button class="sales-button" *ngIf="is_purchase" href="{{langParam}}/{{!game?'products':salesUrl}}">
            <div>
                <span i18n>購入はこちら></span>
            </div>
        </a>
    </div>
</div>