import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    title: string = 'お問合わせページ';
    is_purchase: boolean = false;
    is_static: boolean = true;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: 'お問合わせページ', url: 'contact/'}
    ];

    constructor() {}

    ngOnInit(): void {
        window.scroll(0, 0);
    }

}
