import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeConstant } from '../../appConstant';

@Injectable({
    providedIn: 'root'
})
export class GamesService {
    games = environment.games;
    token: string;
    body: any = new HttpParams();
    options: any;
    category: string = 'list';
    dataFolder = './assets/data/';

    gameIds = [];

    constructor(
        public http: HttpClient,
        private router: Router
    ) {
        let me = this;
        me.options = {
            headers: new HttpHeaders({
                //'Authorization': 'Bearer ' + this.token
                'Content-Type': 'application/json'
            })
        };
    }

    getData() {
        let me = this;
        return me.games;
    }

    getBuyingGames() {
        let games = environment.games.filter(item => {
            return item.is_buying_hide == false
        });
        return games;
    }

    loadData() {
        let me = this;
        let http_params: any = {};
        let url = environment.server + 'games';
        let result: any = this.http.get(url, http_params);
        //let result: any = this.http.get(me.dataFolder + me.gamesJson);
        return result;
    }

    loadSlides() {
        let me = this;
        let http_params: any = {};
        //let url = environment.server + 'games/getSlides';
        let url = environment.server + 'generates/get-sliders/top'
        let result: any = this.http.get(url, http_params);
        return result;
    }

    loadRarities(cardset_id) {
        let me = this;
        let http_params: any = {};
        let url = environment.server + 'Cardsets/loadRarities/' + cardset_id;
        let result: any = this.http.get(url, http_params);
        return result;
    }

    getCardSets(game_id) {
        let game = environment.cardsets.find(item => {
            return item.id == game_id;
        });
        return game.cardsets;
        //let me = this;
        //let http_params: any = {};
        //let url = environment.server + 'generates/get-series/' + game_id;
        //let result: any = this.http.get(url, http_params);
        //return result;
    }

    getColours(game_id) {
        //let game = environment.colours.find(item => {
        //    return item.id == game_id;
        //});
        //return game.colour;
        let me = this;
        let http_params: any = {};
        let url = environment.server + 'generates/get-colours/' + game_id;
        let result: any = this.http.get(url, http_params);
        return result;
    }

    getSupplyCardSets() {
        let game = environment.cardsets.find(item => {
            return item.id == TypeConstant.SUPPLY;
            //return item.name == 'supplies';
        });
        return game.cardsets;
    }

    getCharacterSupplyCardSets() {
        let game = environment.cardsets.find(item => {
            return item.id == TypeConstant.CHARACTER_SUPPLY;
            //return item.name == 'character_supply';
        });
        return game.cardsets;
    }

    getSupplyColours() {
        let game = environment.cardsets.find(item => {
            return item.id == TypeConstant.SUPPLY;
            //return item.name == 'supplies';
        });
        let search_filters = game.search_filters.find(item => {
            if(item.name == 'colour' && item.value_field === 'id') {
                return item;
            }
        });
        return search_filters.search_filter_options;
        //return game.colour;
    }

    getCharacterSupplyColours() {
        let game = environment.cardsets.find(item => {
            return item.id == TypeConstant.CHARACTER_SUPPLY;
            //return item.name == 'character_supply';
        });
        let search_filters = game.search_filters.find(item => {
            if(item.name == 'colour' && item.value_field === 'id') {
                return item;
            }
        });
        return search_filters.search_filter_options;
        //return game.colour;
    }

    getRarities(game_id) {
        let rarities = localStorage.getItem('rarities_' + game_id);
        if (rarities) {
            return JSON.parse(rarities);
        }
    }

    loadSupplyCardSets(game_id: number = 0) {
        let me = this;
        let url;
        url = environment.server + 'Cardsets/getSupply' + (game_id > 0 ? '?game_id=' + game_id
            : '');
        let result: any = me.http.get(url);
        return result;
    }

    getConfigs(code: string, ...options: { [x: string]: any; is_buying?: boolean; }[]) {
        let game = environment.cardsets.find(item => {
            return item.code == code;
        });
        if (options.length > 0 && options[0]['is_buying']) {
            game.cardsets = game.cardsets.filter(item => {
                if (item.is_reservation) {
                    return false;
                }
                return true;
            });
        }

        return game;
    }

    getBanners(id) {
        //let banners = environment.banners.find(item => {
        //    return item.id == id;
        //});
        //return banners.sliders;
        let me = this;
        let http_params: any = {};
        let url = environment.server + 'generates/get-sliders/' + id;
        let result: any = this.http.get(url, http_params);
        return result;
    }

    getRecommends(id) {
        let recommends = environment.recommends.find(item => {
            return item.id == id;
        });
        return recommends.recommends;
    }

    getBulkPurchaseId(game_id) {
        let game = environment.games.find(item => {
            return item.id == game_id;
        });
        // products: 一括買取
        if (game.products.length === 0) {
            return;
        }
        return game.products[0].id;
    }

    loadConfigs(name: string) {
        let me = this;
        let http_params: any = {};
        let url

        url = environment.server + 'Games/GetByName/?name=' + name;

        let result: any = me.http.get(url, http_params);
        return result;

    }

    getCategory() {
        let me = this;
        return me.category;
    }

}
