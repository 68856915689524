<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>
<table width="600" border="0" cellspacing="0" cellpadding="0" class="bigwebman_box">
  <tbody>
    <tr>
        <td style="text-align: center">
            <div style="padding: 16px; border-radius: 10px; background-color: #ffffff; margin-top: 20px; margin-bottom: 20px;">
                      <img src="/{{localeId}}/assets/images/what-is-bigwebman.jpg" width="574" height="810" alt="Bigwebmanマン"/>
                <div style="padding: 16px; border: solid 3px #BDBDBD; border-radius: 10px; background-color: #ffffff; margin-top: 10px; margin-bottom: 10px;">
                    <span style="font-size: small;">
                        カードゲーム通版「Bigweb」ではBigwebマン
                        <strong><font color="#FF0000">オリジナルサプライを発売中です!!</font></strong>
                    </span>
                    <br>
                    <br>
                    <a href="/products/supplies/list?is_supply=1&supplytype=1&subtype=1438">
                        <img src="/{{localeId}}/assets/images/bigwebman-ori-supply.jpg" alt="Bigwebmanマン オリジナルサプライ"/>
                    </a>
                </div>
            </div>
        </td>
    </tr>
  </tbody>
</table>