<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<section class="contentBox p_under orderconfirmation">
    <h1 class="headingUnder01">ご注文手順</h1>
    <p>ご注文開始からご注文完了までをご案内致します。 </p>
    <div class="stepBox">
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP1: 商品を選ぶ</h2>
            <p>商品検索や各商品画面からご希望の商品をお選びください。</p>
            <p class="attention small">※MTGの商品ページの備考欄に記載されている記号は当店がカードの管理記号として用いているものであり、商品の状態等とは関係ございません。</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP2: かごに入れる</h2>
            <p>商品が決まりましたら、数量を選んでして【レジに進む】ボタンを押してください。</p>
            <p class="attention small">※カゴに入れた時点では在庫は確保されておりません。 先にご精算完了されたご注文が優先となりますので、 途中で在庫が完売となる場合がございます、ご了承ください。</p>
            <p class="attention small">※プルダウンで表示された個数がお客様の制限数となります。数回に分けて同じ商品をご購入されますと、キャンセルさせて頂く場合がございますのでご注意ください。<br>但し商品により1回分のご注文分しかお受け出来ない物もございます。その際は複数回ご注文を頂いても、1回分のご注文のみをお受けするか、全てキャンセルさせて頂きます。ご了承下さい。</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP3: かごの中を確認する</h2>
            <p class="attention">精算に進む前にかごの中身を確認します。商品名・個数・数量・価格・発送方法・お支払い方法　などをもう一度ご確認ください。</p>
            <p>数量、金額をご確認のうえ、【この注文で購入手続きをする】ボタンを押してください。</p>
            <p>商品を複数ご注文される場合にはそのまま次の商品をお選びください。</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP4: 会員登録（既にご登録のお客様はログインしてください）</h2>
            <p>【会員登録する】ボタンから「お客様情報」「お届け先」等を入力して会員登録して頂きます。</p>
            <p>登録後IDとパスワードでログインしてください。</p>
            <p class="small">※一度お客様情報をご登録頂きますと、次回以降のご注文手続きが簡単になります。</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP5: キャッシュバックPOINT 精算の確認</h2>
            <p><span class="attention">ポイントを使う場合、「POINTを使用する」にチェックをいれていただき、ご利用になるポイントをご入力下さい。</span><br>
                今回ご購入のポイントは次回以降ご利用できます</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP6: お届先の選択</h2>
            <p>商品のお届け先を選択してください。<br>
                ご登録の住所へお届けの場合は、【会員登録と同じ】にチェックを、別の住所にお届けの場合は【別の住所に配送する】にチェックして配送ご希望の住所をご入力下さい。</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP7: お届け日時の選択</h2>
            <p>商品のお届け時間を選択してください。<br>
                <span class="attention small">※「ゆうパケット」「ネコポス」の配送日時指定は出来かねます。</span></p>
            <p>到着日時を指定しない場合は、【到着日時を指定しない】にチェックを、到着日時を指定する場合は【到着日時を指定する】にチェックして右のプルダウンからご希望に配達日時を選択していください。</p>
            <p class="attention small">※交通事情等の事情により配達時間が前後する可能性がございますのでご了承ください。</p>
            <p class="attention small">※下のカレンダーで営業日(発送可能日)をご確認の上、ご指定願いします。発送日より3日以降での到着日指定が可能です。<br> (一部の遠方の場合は4～5日の場合がございます)<br>尚、午後以降のご注文は翌日以降の発送となりますのでご注意ください。</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP8: ご注文確定</h2>
            <p>内容をご確認のうえ【注文を確定する】ボタンを押してください。<br>このボタンでご注文が発生します。</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP9: ご注文完了</h2>
            <p>ご注文完了となります。</p>
            <p>
                お支払い方法が「振込」の場合は、指定の口座までお支払いお願いいたします。<br>
                振込口座は【ご注文確認】メール、もしくは<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【注文履歴】</a>に記載されています。
            </p>
            <p class="attention">ご購入から5日間経過してもご連絡またはお振込がない場合、 キャンセル扱いとなります。<br>
                ※予約注文は発売日になってもご連絡またはお振込がない場合、 キャンセル扱いとなります。</p>
            <p>ご入金確認後に発送させていただきます。<br>
                <span class="small">※振込手数料はお客様ご負担となります。</span></p>
            <p>ご注文のお名前とお振込みされましたお名前に相違がございます場合は、お手数ですがご連絡お願いいたします。<br>
                営業日の15時以降にご入金された分は翌営業日の発送となります。<br>（ご予約商品が含まれる場合は、入荷後の発送となります。）</p>
        </section>
        <section class="guide-content step">
            <h2 class="headingUnder02">STEP10: BIG-WEBより【ご注文確認メール】でご連絡</h2>
            <p>
                ご注文完了後、BIG-WEBより【ご注文確認】の自動メールをお送りします。<br />
                また、ご注文の確認は<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【注文履歴】</a>からでもご確認いただけます。uuuuuuuuuuuuuu
            </p>
            <p class="attention small">※在庫確認の上、品切れの商品がございましたら営業時間中に別途ご連絡致します</p>
        </section>
    </div>

    <section class="guide-content qanda">
        <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
        <ul class="qaList">
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">欲しい商品が売り切れ表示で「購入」ボタンがクリックできない</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div><div class="qText">
                            申し訳ございません。在庫がない等の理由により、注文いただくことができない状態です。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">注文できたか確認したい</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            ご注文を承りましたら【ご注文確認】メールをお送りします。<br />
                            また、ご注文の確認は<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【注文履歴】</a>からでもご確認いただけます。
                            ご注文内容をお知らせしていますのでご確認ください。<br />
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">注文したのに【ご注文確認】【発送完了】メールが届かない</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            Eメールが届かない場合は、ご注文を承っていないかEメールアドレスに誤りがある可能性があります。ご登録いただいたEメールアドレスに間違いがありますと大切なご案内がお届けできません。<br />
                            <br />
                            <a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【会員情報の確認】</a>で登録メールアドレスをご確認ください。誤りがございましたら、再度正しいメールアドレスを登録してください。<br />
                            アドレスに誤りがない場合や【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
                            <br />
                            ご注文の確認は<a href="https://mtg.bigweb.co.jp/users/login/from_bigwebcojp">マイページ【注文履歴】</a>からでもご確認いただけます。<br />
                            <br />
                            注文完了等のメールが届かない場合、迷惑メールボックスに送られている場合がございます。 <br />
                            迷惑メールボックスをご確認頂いてもメールが届いていない場合、お手数ですが、登録を別のアドレスにご変更ください。<br />
                            メールアドレス変更後【ご注文確認】メールの再送信をご希望の方は、Eメール <a href="<?php echo $this->Url->build(['prefix' => false, 'controller' => 'informations', 'action' => 'contact']); ?>">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00/月～土・祝日除く）までご連絡ください。<br />
                            <br />
                            ※現在、発生しているメールの不具合に関する情報は<a href="/informations/trouble-mail">こちら</a>です。<br />
                            <br />
                            大変ご迷惑をおかけしますが、宜しくお願いいたします。<br />
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">注文後にキャンセルはできるの？</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            お客様が、【注文を確定する】ボタンを押した時点で、ご注文が完了したものとさせていただいております。但し、出荷作業が始まっていないご注文については、キャンセルをすることができます。くわしくは
                            Eメール <a href="mailto:support@big-web.tv">support@big-web.tv</a> または、BIG-通販06-6245-7767（10:00～17:00）にお問い合わせください。
                        </div>
                    </dd>
                </dl>
            </li>
        </ul>
    </section>

</section><!--  /.contentBox-->
