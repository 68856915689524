import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { ItemsService, GamesService, StoresService, SettingsService } from '../_services';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SideMenuComponent } from '../toolbar/side-menu/side-menu.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { CardsetPickerComponent } from './cardset-picker/cardset-picker.component';
import { TypeConstant, TypeGenreConstant } from '../../appConstant';
import { HttpParams } from '@angular/common/http';
import { BrowserStandardEncoder } from '../utils/browser-standard-encoder';
import { environment } from "../../environments/environment";

export interface productNamesWithGroup {
    shape: string;
    names: string[];
}

export const _filter = (opt: string[], value: string): string[] => {
    const filterValue = value;
    return opt.filter(item => item.indexOf(filterValue) === 0);
};

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})

export class SearchBarComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;
    TypeGenreConstant: typeof TypeGenreConstant = TypeGenreConstant;

    @Input() game: any;
    @Input() is_purchase: boolean;
    @Input() criteria: any = {
        searchword: null,
    };
    @Input() category: string = 'list';

    @Input() selectedCardsets: any = [];
    @Input() cardsets;
    @Input() filterTags: any;
    @Input() keyword: any;

    @Output() removeTags: EventEmitter<any> = new EventEmitter();

    private fiiterSubscription: Subscription;
    public modal: any = null;

    searchForm: FormGroup;
    searchOptions: Observable<productNamesWithGroup[]>;
    searchword: FormControl = new FormControl('');
    searchFormFields: any = {
        searchword: this.searchword,
    };

    productNames: productNamesWithGroup[] = [];

    image_url: string = environment.image_url;
    is_menuOpen: boolean;
    urlPath: any;
    langParam: string = '/ja/';
    salesUrl: any;
    purchaseUrl: any;
    is_scrolled: boolean;
    cartCount: number = 0;
    purchaseCartCount: number = 0;

    localeId: string;

    swiper: any;
    private cartCountSubscription: Subscription;
    private purchase_cartCountSubscription: Subscription;

    @HostListener("window:scroll", []) onWindowScroll() {
        let me = this;
        if (window.scrollY > 280) {
            me.is_scrolled = true;
        } else {
            me.is_scrolled = false;
        }
    };

    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private router: Router,
        private activeRouter: ActivatedRoute,
        public gamesService: GamesService,
        public storesService: StoresService,
        public menu: MatMenuModule,
        public itemsService: ItemsService,
        public settingsService: SettingsService
    ) {
        let me = this;
        me.localeId = this.settingsService.getLocale();
    }

    ngOnInit(): void {
        let me = this;

        me.fiiterSubscription = me.storesService.$filters.subscribe(
            items => {
                me.clearKeyword();
            }
        );

        me.cartCountSubscription = me.storesService.$cartCount.subscribe(
            cartCount => {
                me.cartCount = cartCount;
            }
        );
        me.purchase_cartCountSubscription = me.storesService.$purchase_cartCount.subscribe(
            cartCount => {
                me.purchaseCartCount = cartCount;
            }
        );

        if (me.game && (!me.cardsets && me.game.id)) {
            me.cardsets = me.gamesService.getCardSets(me.game.id);
        }

        me.initSearchForm();

        me.urlPath = me.router.url;

        me.langParam = me.localeId;

        me.salesUrl = '/products/' + me.game.code;
        me.purchaseUrl = '/purchase/' + me.game.code;

        if (me.game.type_genre_id == TypeGenreConstant.SUPPLY || me.game.type_genre_id == TypeGenreConstant.CHARACTER_SUPPLY) {
            me.category = TypeConstant.STR_SUPPLY;
        }

    }

    interval: any;
    timer = 0;

    startInterval(value) {
        let me = this;
        me.timer = 0;
        if (me.interval) {
            clearInterval(me.interval);
        }
        me.interval = setInterval(function () {
            if (me.timer > 4) {
                clearInterval(me.interval);
                me.getProductName(value);
            } else {
                me.timer++
            }
        }, 100);
    }

    getProductName(value) {
        let me = this;
        const game_id = me.game ? me.game.id : 0;
        me.itemsService.getProductNames(value, game_id).subscribe((data: any) => {
            me.productNames = data;
            me.searchOptions = me.searchword.valueChanges.pipe(
                startWith(''),
                map(value => me._filterGroup(value))
            );
        });
    }

    initSearchForm() {
        let me = this;
        me.searchForm = me.formBuilder.group(me.searchFormFields);
        me.searchForm.patchValue(me.criteria);
        me.searchword.valueChanges.subscribe(value => {
            if (value) {
                me.startInterval(value);
            } else {
                me.productNames = null;
            }
        });
    }

    private _filterGroup(value: string): productNamesWithGroup[] {
        let me = this;

        if (value && me.productNames && me.productNames.length > 0) {
            return me.productNames
                .map(group => ({ shape: group.shape, names: _filter(group.names, value) }))
                .filter(group => group.names.length > 0);
        }

        return me.productNames;
    }

    removeSelectedCardset(cardsetId) {

    }

    mb_strlen(str) {
        let len = 0;
        for (let i = 0; i < str.length; i++) {
            (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
        }
        return len;
    }

    onEnter($event, trigger) {
        let me = this;

        document.getElementById("search_word-input").blur();
        if (me.game) {
            //me.loadingService.loading();
            setTimeout(function () {

                let values = {}
                values['name'] = $event.target.value;
                values['is_box'] = me.category == 'boxes' ? 1 : 0;
                values['is_supply'] = me.category == 'supply' ? 1 : 0;
                values['is_purchase'] = me.is_purchase ? 1 : 0;

                let params = new HttpParams().appendAll(values);
                me.router.navigateByUrl('/' + (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code + '/list?' + params.toString());
            }, 10);
        }
        //} else {
        //    alert('半角は２文字以上で検索してください')
        //}
    }

    onSubmitSearch() {
        let me = this;
        document.getElementById("search_word-input").blur();

        let values = {}
        values['name'] = me.searchword.value;
        values['is_box'] = me.category == 'boxes' ? 1 : 0;
        values['is_supply'] = me.category == 'supply' ? 1 : 0;
        values['is_purchase'] = me.is_purchase ? 1 : 0;

        let params = new HttpParams({ encoder: new BrowserStandardEncoder() }).appendAll(values);
        me.router.navigateByUrl('/' + (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code + '/list?' + params.toString());
    }

    clearKeyword() {
        let me = this;
        me.searchForm.patchValue({ 'searchword': null });
    }

    onSelectKeyword(val) {
        let me = this;
        let category = 'list';
        //me.loadingService.loading();
        if (val.group.label == '箱・パック') {
            category = 'boxes';
        } else if (val.group.label == 'サプライ') {
            category = 'supply';
        }
        me.category = category;
        document.getElementById("search_word-input").blur();
        setTimeout(function () {
            me.router.navigate([
                (me.is_purchase ? '/purchase/' : '/products/')
                + me.game.code + '/list'],
                {
                    queryParams: {
                        name: val.value,
                        is_box: me.category == 'boxes' ? 1 : 0,
                        is_supply: me.category == 'supply' ? 1 : 0,
                        is_purchase: me.is_purchase ? 1 : 0
                    }
                }
            );
        }, 10);
    }

    scrollTop() {
        window.scroll(0, 0);
    }

    menuOpen() {
        let me = this;

        const dialogRef = this.dialog.open(SideMenuComponent, {
            height: '80%',
            width: '90%',
            disableClose: false,
            data: null,
        });

        dialogRef.afterClosed().subscribe(
            this.modal = null
        );
    }

    openCardsetWindow() {
        let me = this;

        const dialogRef = this.dialog.open(CardsetPickerComponent, {
            panelClass: 'p-modal-confirm',
            disableClose: false,
            autoFocus: true,
            //            hasBackdrop: false,
            height: '80%',
            width: '600px',
            data:
            {
                'game': me.game,
                'criteria': me.criteria,
                'cardsets': me.cardsets,
                'selectedCardsets': me.selectedCardsets,
                'category': me.category,
                'is_purchase': me.is_purchase
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            // me.createSwiper();
        });

    }

    openSearchWindow() {
        let me = this;
        const dialogRef = this.dialog.open(SearchFormComponent, {
            panelClass: 'p-modal-confirm',
            disableClose: false,
            autoFocus: true,
            // hasBackdrop: false,
            width: '600px',
            data: { 'game': me.game, 'criteria': me.criteria, 'cardsets': me.cardsets, 'selectedCardsets': me.selectedCardsets, 'is_purchase': me.is_purchase }
        });

        dialogRef.afterClosed().subscribe(result => {
            // me.createSwiper();
        });
    }

    getFilterCount() {
        let me = this;
        let length = 0;

        if (me.filterTags) {
            if (me.filterTags.length > 0) {
                if (me.selectedCardsets.length > 0) {
                    if (me.filterTags.length - me.selectedCardsets.length > 0) {
                        length =  me.filterTags.length - me.selectedCardsets.length;
                    }
                } else {
                    length = me.filterTags.length
                }
            }
        }

        /**
         * @todo ピックアップのダミータグ分を減算
         */
        if('recommend_id' in me.criteria) {
            length -= 1;
        }

        return (length > 0) ? length : null;
    }

    openCart() {
        this.storesService.openCart();
    }

    openPurchaseCart() {
        this.storesService.openPurchaseCart();
    }


}
