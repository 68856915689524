<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <ng-container *ngIf="slide  && slide.link">
            <a href="{{ slide.link}}">
                <ng-container *ngIf="slide.image_path.substring(0,4)=='http'">
                    <img src="{{ slide.image_path }}" alt="" width="100%">
                </ng-container>
                <ng-container *ngIf="slide.image_path.substring(0,4)!='http'">
                    <img src="{{server}}img/{{ slide.image_path }}" alt="" width="100%">
                </ng-container>
            </a>
        </ng-container>
    </div>
</ngx-slick-carousel>