import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-purchase',
  templateUrl: './bulk-purchase.component.html',
  styleUrls: ['./bulk-purchase.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class BulkPurchaseComponent implements OnInit {

  title: string = '一括買取について';
  is_purchase: boolean = false;
  is_static: boolean = true;
  breadcrumbs: any[] = [
      {title: 'Bigweb', url: '/'},
      {title: '一括買取について', url: '/guide/bulk-purchase'}
  ];

  constructor() {}

  ngOnInit(): void {
      window.scroll(0, 0);
  }
}
