<app-toolbar  [title]="title" [is_purchase]="is_purchase" [is_static]="is_static">
</app-toolbar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>


<section class="contentBox p_under postage">
    <h1 class="headingUnder01">送料・手数料</h1>
    <section class="content">
        <h2 class="headingUnder02">ゆうパケット（代金引換を除く）</h2>
        <p class="attention">※ ゆうパケットは到着日時指定はできません。差出日の翌日〜4日後のお届けになります。（一部の地域を除きます）</p>
        <p class="attention">※ 郵便事故（紛失・漏れなど）の補償がないものとなります。</p>
        <table>
            <thead class="displayPc">
                <tr>
                    <th></th>
                    <td>シングル10000円<span class="gth">以上</span></td>
                    <td>シングル7500円<span class="gth">以上</span></td>
                    <td>シングル5000円<span class="gth">以上</span></td>
                    <td>シングル5000円<span class="lth">未満</span></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>シングル</th>
                    <td>
                        <div class="displaySp thSp">シングル10000円<span class="gth">以上</span></div>
                        0円</td>
                    <td>
                        <div class="displaySp thSp">シングル7500円<span class="gth">以上</span></div>
                        50円</td>
                    <td>
                        <div class="displaySp thSp">シングル5000円<span class="gth">以上</span></div>
                        100円</td>
                    <td>
                        <div class="displaySp thSp">シングル5000円<span class="lth">未満</span></div>
                        200円</td>
                </tr>
            </tbody>
        </table>
    </section>
    <section class="content">
        <h2 class="headingUnder02">ネコポス（代金引換を除く）</h2>
        <p class="attention">※ ネコポスは到着日時指定はできません。差出日の翌日〜2日後のお届けになります。（一部の地域を除きます）</p>
        <p class="attention">※ 郵便事故（紛失・漏れなど）の補償がないものとなります。</p>
        <table>
            <thead class="displayPc">
                <tr>
                    <th></th>
                    <td>シングル7500円<span class="gth">以上</span></td>
                    <td>シングル5000円<span class="gth">以上</span></td>
                    <td>シングル5000円<span class="lth">未満</span></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>シングル</th>
                    <td>
                        <div class="displaySp thSp">シングル7500円<span class="gth">以上</span></div>
                        120円</td>
                    <td>
                        <div class="displaySp thSp">シングル5000円<span class="gth">以上</span></div>
                        170円</td>
                    <td>
                        <div class="displaySp thSp">シングル5000円<span class="lth">未満</span></div>
                        270円</td>
                </tr>
            </tbody>
        </table>
    </section>
    <!--    <section class="content">
            <h2 class="headingUnder02">佐川急便</h2>
            <table>
                <thead class="displayPc">
                    <tr>
                        <th></th>
                        <td>シングル7500円<span class="gth">以上</span></td>
                        <td>シングル5000円<span class="gth">以上</span></td>
                        <td>シングル5000円<span class="lth">未満</span></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="green1">BOXとシングル or<br class="displayPc">シングル</th>
                        <td>
                            <div class="displaySp thSp">シングル7500円<span class="gth">以上</div>
                            450円</td>
                        <td>
                            <div class="displaySp thSp">シングル5000円<span class="gth">以上</span></div>
                            500円</td>
                        <td>
                            <div class="displaySp thSp">シングル5000円<span class="lth">未満</span></div>
                            600円</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        <th class="blue1">BOXのみ</th>
                        <td>600円</td>
                    </tr>
                </tbody>
            </table>
        </section>
        <section class="content">
            <h2 class="headingUnder02">クロネコヤマト宅急便</h2>
            <table>
                <thead class="displayPc">
                    <tr>
                        <th></th>
                        <td>シングル7500円<span class="gth">以上</span></td>
                        <td>シングル5000円<span class="gth">以上</span></td>
                        <td>シングル5000円<span class="lth">未満</span></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="green1">BOXとシングル or<br class="displayPc">シングル</th>
                        <td>
                            <div class="displaySp thSp">シングル7500円<span class="gth">以上</div>
                            700円</td>
                        <td>
                            <div class="displaySp thSp">シングル5000円<span class="gth">以上</span></div>
                            750円</td>
                        <td>
                            <div class="displaySp thSp">シングル5000円<span class="lth">未満</span></div>
                            850円</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        <th class="blue1">BOXのみ</th>
                        <td>850円</td>
                    </tr>
                </tbody>
            </table>
        </section>-->

    <section class="content">
        <h2 class="headingUnder02">代金引換</h2>
        <p>1回のご注文に付き、代金引換手数料が別途250円必要になります。</p>
        <p>現金でのお支払いとなります。</p>
        <p>クレジットカード等でのお支払いはできません。</p>
    </section>
    <hr>
    <section class="content">
        <p class="attention"><strong>※クレジットカード、Paidy後払い(コンビニ/銀行)の場合、Bigwebポイントは付きません</strong></p>
        <p class="attention">クレジットカード、Paidy後払い(コンビニ/銀行)で2件以上ご注文頂き、同梱でお送りする場合、送料1回分で発送いたしますが、その際の多く頂いた送料分は、次回以降のお買い物でご利用いただけますBigwebポイントにてお返しいたします。<br>
            尚、ポイントでお返しする際、メール等でのご連絡はいたしませんのでご了承ください。</p>
    </section>
    <section class="content qanda">
        <h2 class="headingUnder02">よくあるご質問（Q＆A)</h2>
        <ul class="qaList">
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">海外配送はできるの？</div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div><div class="qText">お届け先は日本国内と限定させていただいております。あらかじめご了承ください。</div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">
                            いつ商品は届きますか？
                        </div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            午前中にご注文の場合は当日発送いたしますので、佐川宅配便・ヤマト宅急便・ゆうパックに関しては一部地域を除いては翌日～2日前後に到着予定となります。<br>ゆうパケット・ネコポスに関しては当ページの上記をご覧ください。
                        </div>
                    </dd>
                </dl>
            </li>
            <li>
                <dl class="question">
                    <dt>
                        <div class="qTitle">Q</div>
                        <div class="qText">
                            日時の指定はできますか？
                        </div>
                    </dt>
                    <dd>
                        <div class="qTitle">A</div>
                        <div class="qText">
                            はい、佐川宅配便・ヤマト宅急便に関しては可能です（<a href="/informations/sagawazikan">一部地域</a>を除く）。ただし、翌日の指定はできない場合もございます。<br>（交通事情等により、ご希望通りに配達できない場合もございます。）ゆうパケット・ネコポスに関しては日時の指定は出来ません。
                        </div>
                    </dd>
                </dl>
            </li>
        </ul>
    </section>
</section>