<div class="item-box viewer-item-box" fxLayout="row wrap" *ngIf="current_item">

  <ng-container *ngIf="current_item.cardset; else emptyCardset">
    <div fxFlex="100">
      <h1 class="card-item-title-large">{{current_item.name}}</h1> / {{current_item.fname}}
      {{current_item.cardset.slip}}
    </div>
  </ng-container>
  <ng-template #emptyCardset>
    <div fxFlex="100">
      <h1 class="card-item-title-large">{{current_item.name}}</h1> / {{current_item.fname}}
    </div>
  </ng-template>

  <div class="item-box-detail" fxFlex="580px" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-detail-box style="width: 100%; margin: 10px;" [item]="current_item" [is_reservation]="is_reservation"
      [is_purchase]="is_purchase" [is_cardview]="true" (addToCartItems)="runAddToCartItems(items)">
    </app-detail-box>
  </div>

  <div class="item-box-detail" fxFlex fxLayout="row wrap">
    <div class="card-item-image card-item-box-pl" *ngIf="items.length>0">
      <app-sameitem-list [game]="game" [is_purchase]="is_purchase" [current_item]="current_item" [items]="items"
        [pagenate]="pagenate" (onSelectCurrentItem)="selectCurrentItem($event)" (loadSameCardPage)="loadPage($event)">
      </app-sameitem-list>
    </div>
  </div>

  <div class="item-box" fxFlex="100" class="same-name-cards-wrap">
    <div>
      <div *ngIf="items>0">「{{current_item.name}}」を含む同名商品</div>
      <div class="paging-loader" *ngIf="!current_item || !items">
      </div>
      <div fxLayout="row wrap">
        <ng-container *ngFor="let item of items">
          <div class="same-item-box" fxFlex="158px">
            <app-grid-itembox [item]="item" (addToCartItems)="runAddToCartItems(item)" [is_cardview]="true"
              [is_purchase]="is_purchase" (onSelectCurrentItem)="selectCurrentItem($event)">
            </app-grid-itembox>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>