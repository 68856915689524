<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class="container content_wrap p_under orderconfirmation">
    <section class="contentBox">
      <h1 class="headingUnder01">一括買取 買取手順</h1>
      <p>リスト買取お申込みの手間を省き、買取希望カードまとめて送って頂けます。 </p>
      <div class="stepBox">
        <section class="content step">
          <h2 class="headingUnder02">STEP1: 「一括買取」をカゴに入れる</h2>
            <p>買取ページのリスト最上段にある「一括買取」をカゴの中に入れて下さい。</p>
            <p class="attention small">※買取価格はWEBリスト買取・ダウンロードリスト買取とは異なる場合があります。<br>
              ※WEBリスト買取・ダウンロードリスト買取の価格での買取ご希望の場合は、各リスト買取の方からお申込みお願い致します <br>
              ※一括買取の場合、まとめての査定となります。個別の買取価格はご提示しておりませんので、予めご了承ください。</p>
        </section>
        <section class="content step">
          <h2 class="headingUnder02">STEP2: 買取依頼ボタンをクリック</h2>
          <p>カゴの中の確認ページにて、「この内容で買取依頼する」ボタンをクリックして下さい。</p>
          <h3 class="headingUnder03">●18才未満の方は、「親権者同意書」が必要となります。</h3>
          <ul class="textList">
            <li>18才未満の方は、「親権者同意書」が必要となりますので､必ず保護者の方に署名捺印して頂き、買取カードを送付の際､ご同封下さい。</li>
            <li>PDFファイルをご利用頂けない場合や､プリントアウトが不可能な場合は、親権者の方の直筆にて、お取引にご同意して頂く旨を記載して頂き､署名捺印して頂いた紙面をご同封下さい。</li>
            <li>買取カードを送付の際、「親権者同意書」をご同封し忘れた場合は、お手数ですが､別途、当店に送付願います。</li>
          </ul>
            <br>
              <p>&gt; <a href="assets/images/purchase/purchase-file.pdf" target="_blank">親権者同意書ダウンロード（PDF）</a></p>
        </section>
        <section class="content step">
          <h2 class="headingUnder02">STEP3: 当店より発送案内メール送付</h2>
          <p>当店より発送案内のメールを送らせていただきます。カードの送り先、買取注意事項などをご確認下さい。</p>
          <p class="attention small">※メールが届かない場合、迷惑メールのフォルダに送られている場合がございますので、ご確認下さい。<br>確認できない場合はご連絡下さい。（電話：06-6245-7767[月-土 10:00-17:00]）
  </p>
        </section>
        <section class="content step">
          <h2 class="headingUnder02">STEP4: 買取カードを発送して下さい。</h2>
          <p>メールをご確認後、記載された送り先まで、カードをお送り下さい。</p>
          <p class="attention small">※買取カードの送料はお客様のご負担となります。</p>
        </section>
        <section class="content step">
          <h2 class="headingUnder02">STEP5: 買取カード到着後、査定いたします。</h2>
          <p>お客様のカード到着より1日～3日以内に当店から査定価格の総額をお知らせいたします。</p>
          <p class="attention small">※土日祝は荷物の受取がございません。<br>
            ※買取依頼が混雑した場合、査定するカードが多い場合は査定価格の連絡が遅れる事もございます。</p>
        </section>
        <section class="content step">
          <h2 class="headingUnder02">STEP6: 買取金額にご了承いただけましたら、買取完了です。</h2>
          <p>代金をお客様の口座に送金いたします。</p>
        </section>
        <section class="content step">
          <h2 class="headingUnder02">返品について</h2>
          <p>
          買取金額にご納得いただけなかった場合、カードを返品いたします。<br>
          カードは着払いで返送いたしますので、送料はお客様のご負担となります。</p>
          <h3 class="headingUnder03">-買取する商品について-</h3>
          <p>当店では買取できないカードゲームもございます。詳しくは電話またはメールにてお問い合わせ下さい。</p>
  
          <p>電話お問い合わせ窓口：[<font color="#993333">06-6245-7767 </font>（月-土 10:00〜17:00）]<br>
          メールお問い合わせ窓口：[ <a href="mailto:kaitori@big-web.tv">kaitori@big-web.tv</a> ]</p>
          <p class="attention small">※当店の買取希望枚数に達した場合、 買取を打ち切らせていただくことがあります。 </p>
  
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
                <td>
                    <a href="assets/images/purchase/purchase_damage-1.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-1.jpg" alt="MTG 欠け・よれ・めくれサンプル">
                    </a>
                </td>
                <td>
                    <a href="assets/images/purchase/purchase_damage-2.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-2.jpg" alt="遊戯王 欠け・よれ・めくれサンプル">
                    </a>
                </td>
                <td>
                    <a href="assets/images/purchase/purchase_damage-3.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-3.jpg" alt="アーケード 欠け・よれ・めくれサンプル">
                    </a>
                </td>
            </tr>
            <tr>
                <td>
                    <a href="assets/images/purchase/purchase_damage-4.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-4.jpg" alt="MTG すりきずサンプル">
                    </a>
                </td>
                <td>
                    <a href="assets/images/purchase/purchase_damage-5.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-5.jpg" alt="遊戯王 すりきずサンプル">
                    </a>
                </td>
                <td>
                    <a href="assets/images/purchase/purchase_damage-6.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-6.jpg" alt="アーケード すりきずサンプル">
                    </a>
                </td>
            </tr>
            <tr>
                <td>
                    <a href="assets/images/purchase/purchase_damage-7.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-7.jpg" alt="ヴァイスシュヴァルツ 欠け・よれ・めくれサンプル">
                    </a>
                </td>
                <td>
                    <a href="assets/images/purchase/purchase_damage-8.jpg" target="_blank" data-lightbox="nm-images" data-title="">
                        <img src="assets/images/purchase/purchase_damage-8.jpg" alt="ヴァイスシュヴァルツ すりきずサンプル">
                    </a>
                </td>
            </tr>
          </table>
          <br>
          <p class="attention small">
          <strong>※上のカード画像は一例です。上の画像以外の傷・汚れ等も査定の対象となる場合がございます。</strong><br>
          ※カードの折れ、さらに大きな傷・汚れ・欠けのある場合、買取出来ない場合がございます。<br>
          ※英語・日本語以外の言語のカードの買取は、英語・日本語版カードの買取価格より安くなります。</p>
          </section>
        </div>
  
    </section><!--  /.contentBox-->
  
  </div><!--  /.container-->
  
