import { Component, ViewEncapsulation } from '@angular/core';
import { SettingsService } from './_services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    title: any = 'カードゲームの通販専門店【BIGWEB】';
    deviceInfo = null;
    host = environment.host;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public settingsService: SettingsService,
        private deviceService: DeviceDetectorService,
        private router: Router,

    ) {
        let me = this;
        //me.settingsService.setTitle(me.title);
        this.settingsService.setTimestamp();

        me.deviceChecker();
    }

    deviceChecker() {
        let me = this;
        this.deviceInfo = this.deviceService.getDeviceInfo();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();

        if (isMobile) {
            //this.document.location.href = me.host;
        }
    }
}
