import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-shipping',
    templateUrl: './shipping.component.html',
    styleUrls: ['./shipping.component.scss','./../../../../assets/css/bigmagic.scss']
})
export class ShippingComponent implements OnInit {
    title: string = '送料・手数料のご案内';
    is_purchase: boolean = false;
    breadcrumbs: any[] = [
        {title: 'Bigweb', url: '/'},
        {title: '送料・手数料', url: 'informations/postage/'}
    ];
    is_static: boolean = true;
    constructor() {}

    ngOnInit(): void {
        this.scrollTop();
    }

    scrollTop() {
        window.scroll(0, 0);
    }
}
