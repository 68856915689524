<div class="container modal-container" fxLayout='row wrap' [ngClass]="{'purchase-contents':is_purchase}">
  <div class="close-btn-container" fxFlex='100'>
    <button mat-mini-fab color="warn" class="modal-close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="item-box-detail" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-image-box *ngIf="item.id" [item]="item" [dialogRef]="dialogRef" [is_purchase]="is_purchase" [is_modal_open]=true></app-image-box>
    <div *ngIf="!item.id" style="width:400px; min-height:450px; background-color: lightgray;"></div>
  </div>
  <!--
<div  fxFlex="100"  class="item-view-footer">
        <div fxFlex="50">
            <button (click)="openItemViewModal(true)" mat-flat-button class="mat-purchase" *ngIf='!is_purchase' >
                <i class="material-icons">search</i><span i18n>買取</span>
            </button>
            <button (click)="openItemViewModal(false)" mat-stroked-button class="mat-primary is-purchase" *ngIf='is_purchase'>
                <i class="material-icons">search</i><span i18n>販売</span>
            </button>
        </div>
        <div fxFlex="100" fxFlexOffset="10px">
            <button (click)="searchSameProducts()"  mat-flat-button class=" mat-primary" >
                <i class="material-icons">search</i><span i18n>同名検索</span>
            </button>
        </div>
    </div>
-->

</div>
