import { Component, OnInit, Inject } from '@angular/core';
import { SearchService } from '../../_services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { TypeConstant } from '../../../appConstant';

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;

    isDirty: boolean = false;
    game: any;
    searchForm: FormGroup;
    searchFormFields: any = {};

    filterConfigs: any;
    criteria: any;
    cardsetGroups: any;
    cardsetOptions: any;
    selectedCardsets: any;
    isMultipleSelections: boolean = true;
    is_purchase: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SearchFormComponent>,
        public searchService: SearchService,
        private formBuilder: FormBuilder,
        //public loadingService: LoadingService,
        private router: Router,
        private activeRouter: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        let me = this;
        let loop = false;

        me.game = me.data.game;

        me.cardsetGroups = [];
        me.data.cardsets.forEach((element) => {
            var regexp = /^--(.+)/;
            if (regexp.test(element.name) === false) {
                if (loop) {
                    // グループ継続
                    me.cardsetGroups[me.cardsetGroups.length - 1].options.push({
                        id: element.id,
                        name: element.name
                    });
                } else {
                    // グループなし
                    loop = false;
                    me.cardsetGroups.push({
                        name: element.name,
                        grouping: false, // group disabled
                        options: [{
                            id: element.id,
                            name: element.name
                        }]
                    });
                }
            } else {
                // グループ開始
                loop = true;
                // 初期化
                me.cardsetGroups.push({
                    name: element.name,
                    grouping: true, // group enabled
                    options: []
                });
            }
        });
        //me.cardsetOptions = me.data.cardsets;

        // 常時 true に固定
        // me.isMultipleSelections = localStorage.getItem('isMultipleSelections') ? true : false;
        me.selectedCardsets = me.data.selectedCardsets;
        me.criteria = me.data.criteria;
        if (me.criteria.name) {
            me.criteria.q = me.criteria.name;
        }
        me.is_purchase = me.data.is_purchase;
        if (me.criteria.cardsets) {

            if (me.isMultipleSelections) {
                if (typeof me.criteria.cardsets === 'number') {
                    me.criteria.cardsets = [Number(me.criteria.cardsets)];
                }
            } else {
                if (Array.isArray(me.criteria.cardsets)) {
                    me.criteria.cardsets = me.criteria.cardsets[0];
                }
            }
        }

        me.searchService.getFilterConfigs(me.game.id).subscribe((result: any) => {
            me.filterConfigs = result[0].search_filters;
            me.initSearchFormFields();
        });
    }

    initSearchFormFields() {
        let me = this;

        for (let conf in me.filterConfigs) {
            me.searchFormFields[me.filterConfigs[conf].name] = new FormControl();
        }
        me.searchFormFields.cardsets = new FormControl();
        me.searchForm = me.formBuilder.group(me.searchFormFields);

        /**
         * @todo cardsetsがJSON形式の場合に変換して配列型に戻す
         */
        for (const [key, value] of Object.entries(me.criteria)) {
            if (key === 'cardsets') {
                me.criteria.cardsets = this.searchService.ConvertJsonStringToArray(value);
            }
        }

        me.searchForm.patchValue(me.criteria);
    }

    resetFilter() {
        let me = this;
        me.searchForm.reset();
    }

    onChangeSelectFilter(field) {
        let me = this;
        let val = me.searchForm.get(field).value;
        me.criteria[field] = val;
        me.isDirty = true;
    }

    clearFilter(field: string) {
        let me = this;
        let f: any = {};
        f[field] = null;
        me.criteria[field] = null
        me.searchForm.patchValue(f);
        me.isDirty = true;
    }

    submitFilter() {
        let me = this;
        let values = me.searchForm.value;

        if (me.isDirty) {
            me.isDirty = false;
        }
        me.dialogRef.close();

        me.criteria.page = 1;

        for (let label in values) {
            if (values[label]) {
                Object.keys(values).map(function (key) {
                    if (values[key] == null) {
                        delete values[key];
                    }
                    /**
                     * @todo 条件を指定して検索した場合はピックアップを外す
                     */
                    if (key === 'recommend_id') {
                        delete me.criteria[key];
                    }
                });

                let criteria;
                let cardsets = me.searchForm.value.cardsets;

                delete values.cardsets;
                if (cardsets) {
                    values = Object.assign(values, { "cardsets": JSON.stringify(cardsets) });
                }
                criteria = Object.assign(me.criteria, values);

                let params = new HttpParams().appendAll(criteria);
                return me.router.navigateByUrl('/'
                    + (me.is_purchase ? 'purchase' : 'products')
                    + '/' + me.game.code
                    + '/list?'
                    + params.toString());
            }
        }

        me.router.navigateByUrl('/' + (me.is_purchase ? 'purchase' : 'products') + '/' + me.game.code);
    }

    clearKeyword() {
        let me = this;
        me.searchForm.patchValue({ 'searchword': null });
    }

    closeDialog() {
        let me = this;
        me.dialogRef.close();
    }
}
