<app-toolbar  [title]="title" [is_purchase]="is_purchase"  >
</app-toolbar>
<app-menubar></app-menubar>
<app-breadcrumb [breadcrumbs]="breadcrumbs" [is_purchase]="is_purchase" class="breadcrumb"></app-breadcrumb>

<div class='shop-contents'>
    <h1 >
        <strong i18n>
            BIG MAGIC全店舗＆カードゲーム通販 BIGWEB
        </strong>
    </h1>
    <h2>
        <strong i18n>
            東京
        </strong>
    </h2>
    <div fxLayout="row">
        <div fxFlex="50">
            <a href="shop/akihabara">
                <img mat-card-image src="./assets/images/shops/bm_akihabara.png" class="shop-img" >
            </a>
        </div>
        <div fxFlex="50">
            <a href="shop/ikebukuro">
                <img mat-card-image src="./assets/images/shops/bm_ikebukuro.png" class="shop-img">
            </a>
        </div>
    </div>

    <h2>
        <strong i18n>
            名古屋
        </strong>
    </h2>
    <div fxLayout="row">
        <div fxFlex="50">
            <a href="shop/nagoya">
                <img mat-card-image src="./assets/images/shops/bm_nagoya.png" class="shop-img">
            </a>
        </div>
        <div fxFlex="50">
            <a href="shop/nagoya_zero">
                <img mat-card-image src="./assets/images/shops/bm_zero.png" class="shop-img">
            </a>
        </div>
    </div>

    <h2>
        <strong i18n>
            大阪
        </strong>
    </h2>
    <div fxLayout="row">
        <div fxFlex="50">
            <a href="shop/namba">
                <img mat-card-image src="./assets/images/shops/bm_namba.png" class="shop-img">
            </a>
        </div>
        <div fxFlex="50">

        </div>
    </div>
    <h2>
        <strong i18n>
            ネットショップ
        </strong>
    </h2>
    <div fxLayout="row">
        <div fxFlex="50">
            <a href="">
                <img mat-card-image src="./assets/images/shops/bm_web.png" class="shop-img">
            </a>
        </div>
        <div fxFlex="50">

        </div>
    </div>

    <h2>
        <strong i18n>
            採用情報
        </strong>
    </h2>
    <div fxLayout="row">
        <div fxFlex="100">
            <a href="shop/recruit">
                <img mat-card-image src="./assets/images/shops/bm_recruit.png" class="shop-img">
            </a>
        </div>
    </div>
</div>

