import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ItemsService, StoresService, SettingsService} from '../../../../_services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';


@Component({
    selector: 'app-row-cart-item',
    templateUrl: './row-cart-item.component.html',
    styleUrls: ['./row-cart-item.component.scss']
})
export class CartRowItemComponent implements OnInit {
    @Output() removeItem: EventEmitter<any> = new EventEmitter();
    @Output() closeDialog: EventEmitter<any> = new EventEmitter();
    @Output() addItem: EventEmitter<any> = new EventEmitter();
    @Input() item: any;
    @Input() is_purchase: boolean;

    localeId :string;

    constructor(
        private sanitizer: DomSanitizer,
        public itemsService: ItemsService,
        public storesService: StoresService,
        public settingsService: SettingsService,
        public dialog: MatDialog
    ) {
        this.localeId = this.settingsService.getLocale();
    }

    ngOnInit(
    ): void {
        let me = this;
        me.item.trust_comment = me.sanitizer.bypassSecurityTrustHtml(me.item.comment);
    }

    openModalItem(item) {
        let me = this;
        this.itemsService.openItemView(item, me.is_purchase);
    }

    showOverlay(item) {
        item.is_open = true;
    }

    hideOverlay(item) {
        item.is_open = false;
    }

    toggleOverlay(item) {
        item.is_open = !item.is_open ? true : false;
    }

    toggleFavorite(item) {
        item.is_favorite = !item.is_favorite ? true : false;

    }

    addCount(item, num) {
        let me = this;
        if (num > 0) {
            if (item.stock_count > item.quantity) {
                item.quantity = num + item.quantity;
                item.temp_quantity = item.quantity;
            }
        } else {
            if (item.quantity > 0) {
                item.quantity = num + item.quantity;
                item.temp_quantity = item.quantity;
            }
        }


        me.addItem.emit(item);
    }

    onRemoveItem(item) {

        this.removeItem.emit(item);
    }

    addToCart() {
        let me = this;
        let tempCarts = me.item;

        me.storesService.addCartItems(tempCarts);

    }

    selectionChange($event, item) {

        if ($event.value === -99) {
            if (confirm('この商品をカートから削除してもよろしいですか？')) {
                item.quantity = 0;
                item.temp_quantity = item.quantity;
                this.removeItem.emit(item);
            } else {
                item.quantity = 0;
                item.temp_quantity = item.quantity;

            }
        } else if ($event.value === 0) {
            item.quantity = 0;
            item.temp_quantity = 0;
        } else {
            item.quantity = $event.value;
            item.temp_quantity = item.quantity;
        }
    }

    checkStock($event, item) {
        if (item.stock_count < parseInt($event.target.value)) {
            item.quantity = item.stock_count;
            $event.target.value = item.stock_count;
            item.temp_quantity = item.quantity;
        } else if (parseInt($event.target.value) < 0 || $event.target.value == "") {
            item.quantity = 0;
            $event.target.value = 0;
            item.temp_quantity = 0;
        } else {
            item.quantity = parseInt($event.target.value);
            item.temp_quantity = item.quantity;
        }
    }

    getStockOptions(stock: number) {
        let options: number[] = [];
        let max_stock = stock > 10 ? 11 : stock + 1;
        for (let i = 1; i < max_stock; i++) {
            options.push(i);
        }
        return options;
    }

}
