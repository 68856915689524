<div class="modal-contents">
    <div class="cart-title history-cart-title" fxLayout="row" >
        <span fxFlex i18n> <mat-icon >history</mat-icon>履歴</span>
        <div fxFlex="10" fxLayoutAlign="end">
            <button  mat-mini-fab  color="warn" class="modal-close-button"  (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>


    <div fxLayout="row wrap" >
        <ng-container *ngFor="let item of items">
            <div fxFlex="158px" style="position: relative">
                <div class="modal-close-button-wrap">
                    <button  mat-mini-fab  color="primary" class="modal-close-button hisotory-modal-close-button"  (click)="removeHistory(item)">
                        <mat-icon color="accent">delete</mat-icon>
                    </button>
                </div>
                <app-grid-itembox [item]="item" (addToCartItems)="runAddToCartItems(item)"></app-grid-itembox>

            </div>
        </ng-container>
    </div>

</div>

