import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GamesService, ItemsService, StoresService, SearchService, SettingsService, LoadingService } from '../../_services';
import { Subscription } from 'rxjs';
import { Swiper } from 'swiper';
import { MatDialog } from '@angular/material/dialog';
import { TypeConstant, TypeGenreConstant } from '../../../appConstant';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-games',
    templateUrl: './games.component.html',
    styleUrls: ['./games.component.scss']
})

export class PurchaseGamesComponent implements OnInit {

    TypeConstant: typeof TypeConstant = TypeConstant;
    TypeGenreConstant: typeof TypeGenreConstant = TypeGenreConstant;

    breadcrumbs: any[];
    is_purchase: boolean = true;

    viewtypes: string[] = ["grid-images", "row", "1-image"];
    selectedViewtype: string = "grid-images";
    inStock: boolean = false;
    isBargain: boolean = false;
    groupType: string = 'none';
    has_reservation: boolean = false;
    @Output() addToCartItems: EventEmitter<any> = new EventEmitter();
    private cartSubscription: Subscription;
    private tempCartSubscription: Subscription;
    private favoriteSubscription: Subscription;

    private cartItemSubscription: Subscription;
    title: string = '';
    game_title: string = '';
    sub_title: string = '';
    searchword: string = '';
    items: any[] = [];
    tempItems: any[];
    game: any;
    swiper: any;
    queryParams: any;
    keyword: any;
    criteria: any = {
        searchword: null
    };
    sortBy: any = 'orderid';
    swiperTop = 57;
    rowRatio = 1.78;
    oneImageRatio = 2;
    scrollTo = 0;
    cardsets: any;
    selectedCardsets: any = [];
    selectedRarity: any;
    headerArrayList: any;

    pagenate: any = {
        "count": 0,
        "current": 0,
        "perPage": 50,
        "page": 1,
        "requestedPage": 1,
        "pageCount": 1,
        "start": 0,
        "end": 0,
        "prevPage": false,
        "nextPage": false,
        "sort": null,
        "direction": null,
        "sortDefault": false,
        "directionDefault": false,
        "completeSort": [],
        "limit": 50,
        "scope": null,
        "finder": "all"
    };

    limitPage = 10;//ページ数の暫定的制限
    favorites: any;
    filterTags: any;
    cardsetTags: any;
    filterConfigs: any;
    lastScrollTop: number = 0;
    isLoading: boolean;
    category: string = '';
    supplytypes: any = [];
    supplytype_id: number;
    subtype_id: number;
    constructor(

        private router: Router,
        private activeRouter: ActivatedRoute,
        public dialog: MatDialog,
        public gamesService: GamesService,
        public itemsService: ItemsService,
        public storesService: StoresService,
        public searchService: SearchService,
        public settingsService: SettingsService,
        //public loadingService: LoadingService,
    ) {
        let me = this;
        me.selectedViewtype = sessionStorage.getItem('viewType');
        if (!me.selectedViewtype) me.selectedViewtype = "grid-images";
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnInit() {
        let me = this;
        window.scroll(0, 0);
        me.criteria = {};
        me.isLoading = true;

        me.activeRouter.queryParams.subscribe(
            params => {
                me.setFilterParams(params);
            }
        );

        me.cartSubscription = me.storesService.$purchase_cart.subscribe(
            items => {
                if (me.items) {
                    if (items) {
                        me.onChangeItem(items);
                    } else {
                        me.removeCartItems();
                    }
                }
            }
        );

        me.tempCartSubscription = me.storesService.$purchase_tempCart.subscribe(
            items => {
                if (me.items) {
                    if (items) {
                        me.onChangeTempItem(items);
                    }
                }
            }
        );

        me.cartItemSubscription = me.storesService.$purchase_cartItem.subscribe(
            item => {
                me.onChangeItem(item);
            }
        );

        me.activeRouter.paramMap.subscribe(paramsMap => {
            me.game_title = paramsMap.get('title');
            me.game = me.gamesService.getConfigs(me.game_title);

            if (me.game) {
                me.setGameTitle();
                me.initialize();
            } else {
                me.gamesService.loadConfigs(me.game_title).subscribe((result: any) => {
                    if (result) {
                        me.game = result;
                        localStorage.setItem('gameConfig/' + me.game_title, JSON.stringify(me.game));
                        me.setGameTitle();
                        me.initialize();
                        setTimeout(function () {
                            me.loadItemsWithQueryParams();
                        }, 100);

                    }
                });
            }
        });
    }

    ngAfterViewInit() {
        let me = this;
        //setTimeout(function () {
        //    me.loadingService.hideLoading();
        //}, 100);
    }

    async initialize() {
        let me = this;
        await me.loadFilterTags();
    }

    async loadFilterTags() {
        let me = this;

        me.searchService.getFilterConfigs(me.game.id).subscribe((result: any) => {
            me.filterConfigs = result[0].search_filters;
            me.setFilterTags();
            me.setSearchParams();

            if (me.game) {
                me.loadItemsWithQueryParams();
            }

            if (me.filterTags && me.filterTags.length > 0) {
                setTimeout(function () {
                    me.createSwiper();
                }, 100);

            }
        });
    }

    setGameTitle() {
        let me = this;
        me.criteria.game_id = me.game.id;
        me.breadcrumbs = me.settingsService.getBreadcrumbsByGame(me.game, me.is_purchase);

        me.breadcrumbs.push({ title: '一覧' })

        if (me.category == TypeConstant.STR_SUPPLY) {
            me.setSupplytypes();
        } else {
            me.cardsets = me.gamesService.getCardSets(me.game.id);
        }
    }

    setSupplytypes() {
        let me = this;
        let supplytypes = localStorage.getItem('supplytypes_' + me.game.id);
        me.supplytypes = supplytypes ? JSON.parse(supplytypes) : [];
        if (me.supplytypes.length == 0) {
            me.gamesService.loadSupplyCardSets(me.game.id).subscribe((result: any) => {
                if (result.supplytypes) {
                    me.supplytypes = result.supplytypes;
                } else {
                    me.supplytypes = result;
                }
                localStorage.setItem('supplytypes_' + me.game.id, JSON.stringify(me.supplytypes));
            });
        }
    }

    setSearchParams() {
        let me = this;

        if (me.cardsets) {
            me.cardsetTags = me.cardsets.filter(cardset => {
                if (me.selectedCardsets.indexOf(cardset.id) > -1) {
                    cardset.key = 'cardsets';
                    return cardset;
                }
            });
        } else if (me.supplytypes) {
            me.cardsetTags = me.supplytypes.filter(cardset => {
                if (me.criteria.series == cardset.id) {
                    cardset.key = 'cardsets';
                    return cardset;
                }
            });
        }

        if (me.criteria.searchword) {
            me.cardsetTags.push({
                key: 'searchword',
                name: me.criteria.searchword
            })
        }

        if (me.cardsetTags) {
            me.filterTags = me.cardsetTags.concat(me.filterTags);
        }

        if (me.criteria.is_box && me.criteria.is_box == 1) {
            me.filterTags.push({
                key: 'is_box',
                name: '箱・パック'
            });
        }

        /**
         * @todo 下記の項目については、TCG,サプライ,キャラクターサプライ別に変更
         * selectedSort, isBargain, groupType
         */
        me.loadSelectedSort();
        me.loadGroupType();
        me.loadIsBargain();

        if (me.filterTags.length > 0) {
            me.title = me.filterTags[0].name + '|' + me.game.title + '【BIGWEB】';

            let description = '';
            if(me.game.meta_description != null) {
                description = me.game.meta_description;
            } else {
                description = me.game.title
                + "の通販なら、日本最大級の品揃えのカードショップ【BIGWEB】におまかせください。"
                + "数量限定の最新弾シングルや、海外版シングル、海外版パックなど、あなたの欲しいカードが見つかります。";
            }
            me.settingsService.setTitle(me.title, description);
        }
    }

    setSearchFilterOptions(config) {
        let me = this;

        if (Object.keys(me.criteria).indexOf(config.name) > -1) {
            let val = me.criteria[config.name];
            if (Number.isInteger(val)) {
                val = [val];
            }
            // Array or Scalar
            if (val.length > 1) {
                for (let i = 0; i < config.search_filter_options.length; i++) {
                    for (let y = 0; y < val.length; y++) {
                        if (val[y] == config.search_filter_options[i].value) {
                            let temp_data = config.search_filter_options[i];
                            temp_data.key = config.name;
                            me.filterTags.push(temp_data);
                        }
                    }
                }
            } else {
                let data = config.search_filter_options.find(config_option => {
                    if (val.indexOf(config_option.value) > -1) {
                        return config_option.name;
                    }
                })
                if (data) {
                    data.key = config.name;
                    me.filterTags.push(data);
                }
            }
        }
    }

    setFilterTags() {
        let me = this;

        me.filterTags = [];
        me.filterConfigs.filter(config => me.setSearchFilterOptions(config)).filter(Boolean);
    }

    /**
     * セッションストレージ格納先(prefix)を取得
     * @returns string
     */
    getStoragePrefix() {
        let me = this;
        let type_genre_id = me.game.type_genre_id;
        let prefix = TypeConstant.STR_BUYING + '.';

        switch (type_genre_id) {
            case TypeGenreConstant.SUPPLY:
                prefix = TypeConstant.STR_SUPPLY;
                break;
            case TypeGenreConstant.CHARACTER_SUPPLY:
                prefix = TypeConstant.STR_CHARACTER_SUPPLY;
                break;
            case TypeGenreConstant.TCG:
                break;
            default:
                break;
        }
        return prefix;
    }

    loadIsBargain() {
        let me = this;

        let prefix = me.getStoragePrefix();
        let key = 'isBargain';
        if (prefix.length > 0) {
            key = prefix + '.' + 'isBargain';
        }

        me.isBargain = sessionStorage.getItem(key) ? true : false;
        me.criteria.isBargain = me.isBargain ? 1 : 0;
    }

    loadGroupType() {
        let me = this;

        let prefix = me.getStoragePrefix();
        let key = 'groupType';
        if (prefix.length > 0) {
            key = prefix + '.' + 'groupType';
        }

        let groupType = sessionStorage.getItem(key);
        if (groupType) me.criteria.groupType = me.groupType = groupType;
    }

    loadSelectedSort() {
        let me = this;

        let prefix = me.getStoragePrefix();
        let key = 'selectedSort';
        if (prefix.length > 0) {
            key = prefix + '.' + 'selectedSort';
        }

        let sortBy = sessionStorage.getItem(key);
        if (sortBy == "undefined") {
            sortBy = me.sortBy;
        } else if (sortBy) {
            me.criteria.sortBy = me.sortBy = sortBy;
        }
    }

    onRemoveTags() {
        let me = this;
        me.criteria.searchword = "";
        me.searchword = "";
        me.items = []
        me.isLoading = true;

        me.storesService.onClearkeyword();
    }

    onScrollList() {
        let me = this;

        if (me.limitPage >= me.pagenate.page) {
            if (me.pagenate.nextPage && !me.isLoading) {
                me.criteria.page = me.pagenate.requestedPage + 1;
                me.isLoading = true;
                me.itemsService.load(me.game.id, me.criteria).subscribe((result: any) => {
                    if (result) {
                        me.tempItems = me.tempItems.concat(result.items);
                        me.items = me.tempItems;
                        me.switchShowSoldout(me.inStock);
                        me.pagenate = result.pagenate;
                        me.headerArrayList = me.createHeaderArrayList();
                    }
                    setTimeout(function () {
                        me.isLoading = false;
                    }, 100);
                });
            }
        } else {
            me.isLoading = false;
        }

    }

    loadItemsWithQueryParams() {
        let me = this;
        me.isLoading = true;
        //me.loadingService.loading();
        me.activeRouter.queryParams.subscribe(
            params => {
                // ストレージ情報を引き継いだ上で再セット
                me.setFilterParams(params);

                if (me.game) {
                    me.criteria.is_purchase = 1;
                    me.itemsService.load(me.game.id, me.criteria).subscribe((result: any) => {

                        if (result) {
                            me.items = result.items;
                            me.tempItems = me.items;
                            me.pagenate = result.pagenate;

                            // pickups
                            if (result.sub_title) {
                                me.sub_title = result.sub_title
                                // page title
                                me.title = me.sub_title + '|' + me.game.title + '【BIGWEB】';
                                me.settingsService.setTitle(me.title);
                            }
                            // recommends
                            if ('recommend_id' in me.criteria) {
                                // ダミーのフィルタータグを生成
                                me.filterTags.push({
                                    key: 'recommend_id',
                                    name: me.sub_title,
                                    value: me.criteria.recommend_id[0]
                                });
                            }
                        }

                        me.storesService.refreshPurchaseItems();
                        me.scrollTop();

                        me.switchShowSoldout(me.inStock);
                        me.headerArrayList = me.createHeaderArrayList();

                        setTimeout(function () {
                            me.isLoading = false;
                            //me.loadingService.hideLoading();
                        }, 500);
                    });
                }
            }
        );
    }

    selectionSort($event = null) {
        let me = this;

        if ($event) {
            me.criteria.sortBy = $event.value;
        }

        let prefix = me.getStoragePrefix();
        let key = 'selectedSort';
        if (prefix.length > 0) {
            key = prefix + '.' + 'selectedSort';
        }

        sessionStorage.setItem(key, me.criteria.sortBy);
        me.criteria.page = 1;
        setTimeout(function () {
            Object.keys(me.criteria).map(function (key) {
                if (key === 'cardsets') {
                    me.criteria[key] = JSON.stringify(me.criteria[key]);
                } else
                    if (me.criteria[key] == null) {
                        delete me.criteria[key];
                    }
            });
            let params = new HttpParams().appendAll(me.criteria);
            me.router.navigateByUrl('/purchase' + '/' + me.game.code + '/list?' + params.toString());
        }, 10);
    }

    setSearchword(keyword) {
        let me = this;
        if (keyword) {
            me.keyword = keyword;
            me.criteria.page = 1;
            me.criteria.searchword = me.keyword;
        }
    }

    setSelectedCardsets(selectedCardsets) {
        let me = this;
        if (Array.isArray(selectedCardsets)) {
            me.selectedCardsets = selectedCardsets.map(Number);
        } else if (typeof selectedCardsets === 'string') {
            me.selectedCardsets = [Number(selectedCardsets)];
        } else if (typeof selectedCardsets === 'number') {
            me.selectedCardsets = [selectedCardsets];
        } else {
            me.selectedCardsets = [];
        }
    }

    setSelectedRarities(selectedRarities) {
        let me = this;
        if (Array.isArray(selectedRarities)) {
            me.selectedRarity = selectedRarities.map(Number);
        } else if (typeof selectedRarities === 'string') {
            me.selectedRarity = [Number(selectedRarities)];
        } else {
            me.selectedRarity = [];
        }
    }

    getCategory(params) {
        let me = this;
        if (params.is_box && params.is_box == 1) {
            me.inStock = true;
            return 'boxes';
        } else if (params.is_supply && params.is_supply == 1) {
            me.inStock = true;
            return 'supply';
        } else {
            me.inStock = false;
            return 'list';
        }
    }

    setFilterParams(params) {
        let me = this;

        me.category = me.getCategory(params);
        me.criteria = JSON.parse(JSON.stringify(params), (key, value) => {
            if (key === 'cardsets') {
                return JSON.parse(value);
            } else
                if (Array.isArray(value)) {
                    return value.map(Number);
                } else if (!isNaN(value)) {
                    return [Number(value)];
                } else if (value) {
                    return value;
                }
            return false;
        });

        if(me.criteria.is_box && me.criteria.is_box[0] < 1) {
            delete me.criteria.is_box;
        }

        if (me.criteria.supplytype) {
            me.supplytype_id = me.criteria.supplytype[0];
        }
        if (me.criteria.subtype) {
            me.subtype_id = me.criteria.subtype[0];
        } else {
            me.subtype_id = null;
        }
        if (me.criteria.sortBy && me.criteria.sortBy != 'undefined') {
            me.sortBy = me.criteria.sortBy;
        }

        if (params.name) {
            me.setSearchword(params.name);
        } else {
            me.setSearchword(params.q);
        }
        me.setSelectedCardsets(me.criteria.cardsets);
        me.setSelectedRarities(params.rarity);

    }

    onChangeFavorites(favorites) {

        let me = this;

        //  追加されたitem.idを取得
        let itemsId = favorites.map(item => { if (item) return item.id });
        //  該当のitemを一旦除外
        me.items = me.items.map(item => {
            item.relatedItems.map(relatedItem => {
                if (itemsId.indexOf(relatedItem.id) > -1) {
                    let tage = favorites.filter(tItem => { if (tItem && tItem.id == relatedItem.id) return tItem });
                    if (tage.length > 0) {
                        item.is_favorite = true;
                        relatedItem.is_favorite = true;
                    }
                }
                return relatedItem;
            })
            return item;
        });
    }

    onChangeTempItem(targetItem: any) {
        let me = this;
        for (let y = 0; y < me.items.length; y++) {
            if (me.items[y].id == targetItem.id) {
                me.items[y] = targetItem;
            }
        }
    }

    onChangeItem(targetItems) {

        let me = this;
        //  追加されたitem.idを取得
        let itemsId = targetItems.map(item => { if (item) return item.id });

        //  該当のitemを一旦除外
        me.items = me.items.map(item => {
            item.relatedItems.map(relatedItem => {
                if (itemsId.indexOf(relatedItem.id) > -1) {
                    let tage = targetItems.filter(tItem => { if (tItem && tItem.id == relatedItem.id) return tItem });
                    if (tage.length > 0) {
                        relatedItem.quantity = tage[0].quantity;
                        relatedItem.temp_quantity = relatedItem.quantity;
                    }
                }
                return relatedItem;
            })
            return item;
        });
    }

    removeCartItems() {
        let me = this;
        me.items = me.items.map(item => {
            item.relatedItems.map(relatedItem => {
                relatedItem.quantity = 0;
                relatedItem.temp_quantity = 0;
                return relatedItem;
            })
            return item;
        });
    }

    runAddToCartItems(items: any) {
        //this.addToCartItems.emit(items);
        this.storesService.addPurchaseCartItems(items);

    }

    onSelectViewType(val) {
        let me = this;
        me.selectedViewtype = val;
        sessionStorage.setItem('viewType', val);
    }

    scrollTop() {
        window.scroll(0, 0);

    }

    removeTag(tag) {
        let me = this;
        let criteria = me.criteria;

        if (tag.key == 'searchword') {
            delete criteria['q'];
            delete criteria['name'];
            delete criteria['searchword'];
        } else if (tag.key == 'is_box') {
            delete criteria['is_box'];
        } else if (tag.key == 'cardsets') {
            let selectedCardsets = me.selectedCardsets.concat();
            let index = selectedCardsets.indexOf(tag.id);
            selectedCardsets.splice(index, 1);
            me.filterTags.splice(index, 1);
            if (selectedCardsets.length > 0) {
                criteria = Object.assign(criteria, { cardsets: selectedCardsets });
            } else {
                delete criteria.cardsets;
            }
        } else {
            if (criteria[tag.key].length > 1) {
                criteria[tag.key] = criteria[tag.key].filter(n => n !== tag.value);
            } else {
                delete criteria[tag.key];
            }
        }

        // delete all filter
        if (Object.keys(criteria).length < 1) {
            me.router.navigateByUrl('/purchase' + '/' + me.game.code);
            return;
        } else if(Object.keys(criteria).length === 1) {
            if('is_purchase' in criteria && criteria.is_purchase === 1) {
                me.router.navigateByUrl('/purchase' + '/' + me.game.code);
                return;
            }
        }

        criteria.page = 1;

        setTimeout(function () {
            Object.keys(criteria).map(function (key) {
                if (key === 'cardsets') {
                    me.criteria[key] = JSON.stringify(me.criteria[key]);
                } else
                    if (criteria[key] == null) {
                        delete me.criteria[key];
                    }
            });
            let params = new HttpParams().appendAll(criteria);
            me.router.navigateByUrl('/purchase' + '/' + me.game.code + '/list?' + params.toString());
            if (me.swiper) {
                me.swiper.update();
            }
        }, 100);
    }

    createSwiper() {
        let me = this;
        if (me.swiper) {
            me.swiper.update();
        } else {

            me.swiper = new Swiper('.filter-criteria', {
                spaceBetween: 5,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                slideActiveClass: 'swiper-slide-active',
                breakpoints: {
                    480: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    },
                    640: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                    },
                    800: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                    },
                    960: {
                        slidesPerView: 6,
                        slidesPerGroup: 6,
                    },
                    1080: {
                        slidesPerView: 7,
                        slidesPerGroup: 7,
                    },
                    1600: {
                        slidesPerView: 8,
                        slidesPerGroup: 8,
                    }
                }
            });
        }
    }

    hideSoldout() {
        let me = this;
        me.items = me.items.filter(item => {
            return !item.relatedItems[0].is_sold_out;
        });
    }

    switchShowSoldout(inStock) {
        let me = this;
        me.inStock = inStock;
        if (inStock) {
            me.hideSoldout();
        } else {
            me.items = me.tempItems;
        }
    }

    toggleBargain(isBargain) {
        let me = this;

        let prefix = me.getStoragePrefix();
        let key = 'isBargain';
        if (prefix.length > 0) {
            key = prefix + '.' + 'isBargain';
        }

        me.isBargain = isBargain;
        if (isBargain) {
            me.criteria.isBargain = 1;
            sessionStorage.setItem(key, '1');
        } else {
            delete me.criteria.isBargain;
            sessionStorage.removeItem(key);
        }
        me.criteria.page = 1;
        setTimeout(function () {
            Object.keys(me.criteria).map(function (key) {
                if (key === 'cardsets') {
                    me.criteria[key] = JSON.stringify(me.criteria[key]);
                } else
                    if (me.criteria[key] == null) {
                        delete me.criteria[key];
                    }
            });
            let params = new HttpParams().appendAll(me.criteria);
            me.router.navigateByUrl('/purchase' + '/' + me.game.code + '/list?' + params.toString());
        }, 10);
    }

    createHeaderArrayList() {
        let me = this;
        var headerArrayList = [];
        var headerId = null;
        for (var item of me.items) {
            if (me.groupType == 'cardset') {
                if (headerId !== item.cardset.id) {
                    headerArrayList.push(item.id);
                    headerId = item.cardset.id;
                }
            } else if (me.groupType == 'rarity') {
                if (item.rarity && headerId !== item.rarity.id) {
                    headerArrayList.push(item.id);
                    headerId = item.rarity.id;
                }
            }
        }
        return headerArrayList;
    }

    toggleShowSeries(groupType) {
        let me = this;
        me.groupType = groupType;
        me.criteria.groupType = groupType;

        let prefix = me.getStoragePrefix();
        let key = 'groupType';
        if (prefix.length > 0) {
            key = prefix + '.' + 'groupType';
        }

        sessionStorage.setItem(key, groupType);
        me.selectionSort();
    }

    onSelectCardset(param) {
        this.items = [];
        this.pagenate = {};
        this.isLoading = true;
        //this.loadingService.loading();
    }

}

