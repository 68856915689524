<form class="cardset-picker" [formGroup]="selectCartsetForm">
  <div fxLayout="column">
    <div fxLayout="row" fxFill>
      <div fxFlex="95" class="item-title-head">
        <div class="item-title">
          <mat-icon>style</mat-icon>
          <ng-container
            *ngIf="game.id === TypeConstant.SUPPLY || game.id === TypeConstant.CHARACTER_SUPPLY; else selectCardsets">
            <span i18n>カテゴリを選ぶ</span>
          </ng-container>
          <ng-template #selectCardsets>
            <span i18n>セットを選ぶ</span>
          </ng-template>
        </div>
        <mat-slide-toggle formControlName="isMultipleSelections" (change)="onToggle($event)" color="primary"
          class="select-set-toggle">
          <ng-container
            *ngIf="game.id === TypeConstant.SUPPLY || game.id === TypeConstant.CHARACTER_SUPPLY; else selectmultiCardsets">
            <span i18n>複数のカテゴリを選ぶ</span>
          </ng-container>
          <ng-template #selectmultiCardsets>
            <span i18n>複数のセットを選ぶ</span>
          </ng-template>
        </mat-slide-toggle>
      </div>

      <div class="close-btn-container" fxFlex="5" fxLayuotAlign="end">
        <button mat-mini-fab color="warn" class="modal-close-button" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="row" fxFill>
      <mat-form-field fxFlex appearance="outline">
        <mat-label><span i18n>検索キーワード</span></mat-label>
        <button mat-icon-button matPrefix>
          <mat-icon>search</mat-icon>
        </button>
        <input type="text" placeholder="セット名" matInput [value]="searchword"
          (keyup)="setCardsetKeyword($event.target.value)">
      </mat-form-field>

    </div>

    <div fxLayout="row" fxFill *ngIf="!selectCartsetForm.value.isMultipleSelections">
      <mat-list role="list" fxFill #cardset fxFlex fxLayout="column">
        <mat-list-item class="setItem single-list" *ngFor="let cardset of cardsetOptions">
          <div *ngIf="cardset.name.trim().substring(0,2)=='--'" mat-subheader class="single-list-item-sub">
            {{cardset.name}}
          </div>
          <ng-container *ngIf="cardset.name.trim().substring(0,2)!='--'">
            <span *ngIf="selectCartsetForm.value.cardset.indexOf(cardset.id)<0" class="cardset-name single-list-item"
              (click)="filterCardset(cardset)">
              {{cardset.name}}
            </span>
            <span *ngIf="selectCartsetForm.value.cardset.indexOf(cardset.id)>-1" class="cardset-name-active">
              {{cardset.name}}
            </span>
          </ng-container>
        </mat-list-item>
      </mat-list>
    </div>
    <div fxLayout="column" *ngIf="selectCartsetForm.value.isMultipleSelections">
      <div fxLayout="row" fxFill fxFlex>
        <mat-selection-list role="list" fxFill #cardset fxFlex fxLayout="column" formControlName="cardset">
          <ng-container *ngFor="let cardset of cardsetOptions">
            <div class="setItem" *ngIf="cardset.name.trim().substring(0,2)=='--'" mat-subheader>
              {{cardset.name}}
            </div>
            <mat-list-option class="setItem" role="listitem" [value]="cardset.id"
              *ngIf="cardset.name.trim().substring(0,2)!='--'">
              {{cardset.name}}
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </div>
      <div class="modal-footer search-form-bottom" fxLayout="row" fxFill fxFlex fxLayoutGap="10px">
        <button mat-stroked-button class="reset-filter-button" (click)="resetFilter()" fxFlex="35">
          <i class="material-icons">delete_outline</i> <span i18n>リセット</span>
        </button>
        <button mat-flat-button class="search-button" (click)="submitFilter()" color="primary" fxFlex="65">
          <i class="material-icons">search</i><span i18n>検索</span>
        </button>
      </div>
    </div>
  </div>
</form>